import { Stack, Typography } from "@mui/material";
import {
  LargeContentBox,
  PageTitle,
  PrimaryButton,
  SecondaryButton,
} from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";
import { APPC_POLICY } from "../../services/appc";
import { useSessionContext } from "../../contexts/SessionContext";
import { usePrivacyContext } from "../../contexts/PrivacyContext";
import { useState } from "react";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { ConsentManagementMobile } from "../privacymenumobile/components/ConsentManagementMobile";

export function Privacy() {
  const { user } = useSessionContext();
  const { isSharing, privacyLoading, setRefetch } = usePrivacyContext();

  const [loading, setLoading] = useState<boolean>(false);

  async function handleChangePolicy(share: boolean) {
    setLoading(true);
    try {
      await APPC_POLICY({
        option: share ? "Opt-in" : "Opt-out",
        patient_id: user.username,
      });
      toast.success("Política de privacidade alterada com sucesso");
    } catch (err) {
      toast.error("Não foi possível alterar a política de privacidade");
      console.log("PrivacyPage", err);
    } finally {
      setRefetch((prev: boolean) => !prev);
      setLoading(false);
    }
  }
  return (
    <>
      {isMobile ? (
        <ConsentManagementMobile />
      ) : (
        <PrivateMainLayout noSidebar={isMobile} mobileView={isMobile}>
          <LargeContentBox>
            <PageTitle> Privacidade </PageTitle>
            <Stack spacing={1}>
              <Typography fontSize={18} textAlign="justify">
                Ao aceitar os termos de uso, e caso você já tenha sido atendido
                em alguma clínica participante da Plataforma Fluxmed, por
                padrão, seu Registro Eletrônico de Saúde é compartilhado entre
                os profissionais e médicos da clínica em que foi atendido.
              </Typography>

              <Typography fontSize={18} textAlign="justify">
                Você pode gerenciar quais as clínicas ou as pessoas próximas a
                você, que podem ter acesso às suas informações de saúde,
                utilizando a opção “Compartilhamentos” no painel de controle.
                Poderá ainda, ver quem já visualizou seus registros eletrônicos
                de saúde no menu “Histórico de acessos” clicando nas iniciais do
                seu nome no canto superior direito.
              </Typography>

              <Typography fontSize={18} textAlign="justify">
                Aviso! Ao selecionar “Não compartilhar meu registro eletrônico
                de saúde” nenhum médico de clínica participante da plataforma
                Fluxmed poderá registrar ou recuperar suas informações de saúde.
                Se informe com seu médico outra forma de realizar seu
                atendimento.
              </Typography>
            </Stack>
            <Stack
              mt={4}
              width="100%"
              direction={{ xs: "column", md: "row-reverse" }}
              spacing={{ xs: 2, lg: 4 }}
              justifyContent="space-between"
            >
              <PrimaryButton
                width="100%"
                height="50px"
                disabled={isSharing && !privacyLoading}
                loading={loading && !isSharing}
                onClick={async () => handleChangePolicy(true)}
              >
                Compartilhar meu Registro Eletrônico de Saúde
              </PrimaryButton>

              <SecondaryButton
                width="100%"
                height="50px"
                disabled={!isSharing && !privacyLoading}
                onClick={async () => handleChangePolicy(false)}
              >
                Não compartilhar meu Registro Eletrônico de Saúde"
              </SecondaryButton>
            </Stack>
          </LargeContentBox>
        </PrivateMainLayout>
      )}
    </>
  );
}
