import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

export async function PutLocation(dataLocation: any, idLocation: string) {
  const url = `${endpoints.WORKFLOWFHIR}/Location/${idLocation}`;
  const data = dataLocation;
  const headers = {
    Accept: "*/*",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
  };

  const response = await useAxios
    .put(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status === HTTPResponseStatus.Conflict) {
        return true;
      } else if (
        response.status !== HTTPResponseStatus.Success &&
        response.status !== HTTPResponseStatus.Created
      ) {
        throw new Error(`PutLocation: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("PutLocation ", error);
      toast.error(`PutLocation Error: ${error?.response?.status}`);
      throw new Error();
    });

  if (response) return response;
}
