import { useState } from "react";
import { PrivateMainLayout } from "../../components/layout";
import { FormProvider, useForm } from "react-hook-form";
import { IFormInput } from "./types";
import { LargeContentBox } from "../../components/common";
import { FormSubscription, TermsOfUse } from "./components";
import { SubscriptionPlan } from "./components/SubscriptionPlan";
import { Step, StepLabel, Stepper } from "@mui/material";
import { PaymentStep } from "./components/PaymentStep";

const defaultValues = {
  cnpjValue: "",
  cpfValue: "",
  personName: "",
  personSurname: "",
  corporateName: "",
  cnesValue: "",
  organization: undefined,
};

export function RegisterOrganization() {
  const methods = useForm<IFormInput>({ defaultValues });
  const { handleSubmit, control, setValue, getValues } = methods;
  const type = localStorage.getItem("operation");

  const labels = [
    "Registrar organização",
    "Termos de uso",
    "Planos de assinatura",
    "Realizar pagamento",
  ];
  const [currentStep, setCurrentStep] = useState(type === "renewPlan" ? 2 : 0);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  return (
    <PrivateMainLayout noSidebar>
      <FormProvider {...methods}>
        <>
          <LargeContentBox>
            <Stepper
              activeStep={currentStep}
              sx={{ width: "90%", marginBottom: "32px" }}
            >
              {labels.map((key, value) => (
                <Step key={value}>
                  <StepLabel>{key}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {currentStep === 0 && (
              <FormSubscription
                getValue={getValues}
                control={control}
                handleSubmit={handleSubmit}
                navigateToNextPage={handleNext}
                setValue={setValue}
              />
            )}
            {currentStep === 1 && (
              <TermsOfUse
                handleSubmit={handleSubmit}
                onBackButtonClick={handleBack}
                onNextButtonClick={handleNext}
              />
            )}
            {currentStep === 2 && (
              <SubscriptionPlan
                setSelectedPlan={setSelectedPlan}
                handleNext={handleNext}
              />
            )}
            {currentStep === 3 && selectedPlan && (
              <PaymentStep selectedPlan={selectedPlan} />
            )}
          </LargeContentBox>
        </>
      </FormProvider>
    </PrivateMainLayout>
  );
}