import React from 'react';
import { Box, Typography, Grid, Paper, List, ListItem, ListItemText, styled, Divider } from "@mui/material";
import CheckoutForm from './CheckoutForm';
import { SelectedPlan } from '../types';

// Estilos personalizados
const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
  },
  '& .MuiListItemText-secondary': {
    fontSize: '1.2rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  borderRadius: theme.spacing(2),
}));

const PlanTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 700,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(3),
}));

export function PaymentStep({ selectedPlan }: SelectedPlan) {
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
  };

  const freeTrial = selectedPlan.auto_recurring.free_trial;

  const getPluralizedDays = (frequency: number) => {
    return frequency === 1 ? "dia" : "dias";
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "1200px", margin: "0 auto", padding: "32px", overflow: "auto" }}>
      <Grid container spacing={4}>
        {/* Coluna da esquerda - Informações do plano */}
        <Grid item xs={12} md={4}>
          <StyledPaper elevation={3}>
            <PlanTitle>Resumo do Plano</PlanTitle>
            <List>
              <ListItem>
                <StyledListItemText 
                  primary="Nome do Plano"
                  secondary={selectedPlan.reason}
                />
              </ListItem>
              <Divider sx={{ my: 2 }} />
              <ListItem>
                <StyledListItemText 
                  primary="Valor Mensal"
                  secondary={formatCurrency(selectedPlan.auto_recurring.transaction_amount)}
                />
              </ListItem>
              {selectedPlan.auto_recurring.billing_day && (
                <>
                  <Divider sx={{ my: 2 }} />
                  <ListItem>
                    <StyledListItemText 
                      primary="Data de Cobrança"
                      secondary={`Todo dia ${selectedPlan.auto_recurring.billing_day}`}
                    />
                  </ListItem>
                </>
              )}
              {freeTrial && (
                <>
                  <Divider sx={{ my: 2 }} />
                  <ListItem>
                    <StyledListItemText 
                      primary="Período de Avaliação Gratuita"
                      secondary={`${freeTrial.frequency} ${getPluralizedDays(freeTrial.frequency)} grátis`}
                    />
                  </ListItem>
                </>
              )}
            </List>            
          </StyledPaper>
        </Grid>
        
        {/* Coluna da direita - Formulário de checkout */}
        <Grid item xs={12} md={8}>
          <StyledPaper elevation={3}>
            <CheckoutForm selectedPlan={selectedPlan} />
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
}