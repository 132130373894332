/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AlertInviteDialog } from "../components/modals/AlertInviteDialog";
import { oids } from "../configs/Settings";
import { GetPatient, GetPractitionerRole } from "../services/fhir";
import { ProfileSearch } from "../services/scim";
import { useFhirData } from "../utils/useFhirData";
import { useScimData } from "../utils/useScimData";
import { OrganizationContextProvider } from "./OrganizationContext";
import { ViewContextProvider } from "./ViewContext";
import { ConsentContextProvider } from "./ConsentContext";
import { LoggedUserContextProvider } from "./LoggedUserContext";
import { SideBarContextProvider } from "./SideBarContext";
import { PrivacyContextProvider } from "./PrivacyContext";
import { ClinicalRegisterContextProvider } from "./ClinicalRegisterContext";
import { PatientHealthCareInformationContextProvider } from "./PatientHealthCareInformationContext";
import { useFirebaseService } from "../utils/useFirebase";
import { isMobile } from "react-device-detect";
import { DeviceIDPatch } from "../services/scim/profile/DeviceIDPatch";
import { ContractContextProvider } from "./ContractContext";
import { PrescriptionAttendenceContextProvider } from "./PrescriptionAttendenceContext";
import { QuestionnaireContextProvider } from "./QuestionnaireContext";
import { MedicalCareContextProvider } from "./MedicalCareContext";
import { InterventionsContextProvider } from "./InterventionsContext";
import { GetRelatedPerson } from "../services/fhir/relatedperson/GetRelatedPerson";
import { SignatureContextProvider } from "./SignatureContext";
import { SignatureControlContextProvider } from "./SignatureControlContext";
import { GetPerson } from "../services/fhir/person/GetPerson";
import { TermOfUseDialog } from "../pages/TermsOfUse/TermOfUseDialog";
import { Person } from "fhir/r5";
import { CustomizationContextProvider } from "./CustomizationContext";
import {
  WarningCentralContext,
  WarningCentralContextProvider,
} from "./WarningCentralContext";

type AccessRole = "manager" | "practitioner" | "owner";

interface IAccess {
  type?: "patient" | "professional" | null;
  organizationId?: string;
  roles?: AccessRole[];
}

interface IRole {
  isManager: boolean;
  isOwner: boolean;
  isPractitioner: boolean;
  organizationId: string;
  organization: any;
}

interface SessionContextValue {
  getRolesArray: Function;
  fetchUser: any;
  fetchUserRoles: any;
  setRefetch: Function;
  setAccess: Function;
  setIsOpenTermDialog: Function;
  setInviteDialog: any;
  // manoleToken: any;
  isOpenTermDialog: boolean;
  user: any;
  roles: IRole[] | undefined;
  access: IAccess;
  loading: boolean;
  userPatient: any;
  inviteDialog: any;
  relatedPerson: any;
  patientRelated: any;
  guardianRelated: any;
  person: Person;
  firstAccessDialog: any;
  setFirstAccessDialog: any;
  termsDialog: any;
  setTerminateAccount: Function;
  terminateAccount: any;
  termRefused: boolean;
  userNavigable: boolean | null;
  userMaintainer: boolean | null;
}

export const SessionContext = createContext<SessionContextValue>(null!);

interface SessionContextProviderProps {
  children: React.ReactNode;
}

export function SessionContextProvider({
  children,
}: SessionContextProviderProps) {
  const navigate = useNavigate();

  const [user, setUser] = useState({});

  const [relatedPerson, setRelatedPerson] = useState<Array<any>>();
  const [patientRelated, setPatientRelated] = useState<Array<any>>();
  const [guardianRelated, setGuardianRelated] = useState<Array<any>>();
  const [inviteDialog, setInviteDialog] = useState(false);
  const [termsDialog, setTermsDialog] = useState(false);
  const [firstAccessDialog, setFirstAccessDialog] = useState(true);
  const [userPatient, setUserPatient] = useState<any>({});
  const [roles, setRoles] = useState<IRole[] | undefined>();
  const [refetch, setRefetch] = useState<boolean>(false);
  const [access, setAccess] = useState<IAccess>(
    JSON.parse(localStorage.getItem("access") || "{}") as IAccess
  );
  const [loadingAppointments, setLoadingAppointments] = useState(false);
  const [person, setPerson] = useState<any>();
  const [isOpenTermDialog, setIsOpenTermDialog] = useState(false);
  const [terminateAccount, setTerminateAccount] = useState<boolean>();
  const termRefused = person?.extension?.find((item: any) =>
    item.url.includes("termRefused")
  )?.valueBoolean;

  const userNavigable: boolean | null =
    roles?.some(
      (role) => role.isOwner || role.isManager || role.isPractitioner
    ) ?? null;
  const userMaintainer: boolean | null =
    roles?.some((role) => role.isOwner || role.isManager) ?? null;

  async function fetchUser() {
    const Scim2UserResponse = await ProfileSearch().then(
      (response) => response?.Resources
    );

    if (Scim2UserResponse) {
      const extractScim2Data = useScimData.getUser(Scim2UserResponse);
      setUser(extractScim2Data);
      const patient = await GetPatient({
        identifier: extractScim2Data.username,
      });
      setUserPatient(patient?.data);

      // Related Person

      const idsRelated = patient?.data?.link
        ?.map((el: any) => el?.other?.reference.split("/")[1])
        .join(",");

      const auxLinkGuardian = await GetRelatedPerson({
        patient: `${patient?.data?.id}`,
      }).then((response) => response?.data?.entry);

      const auxGuardianRelated = auxLinkGuardian?.find((el: any) => {
        return el.resource?.resourceType?.includes("RelatedPerson");
      })
        ? await GetRelatedPerson({
            patient: `${patient?.data?.id}`,
          }).then((response) => response?.data?.entry)
        : undefined;

      setGuardianRelated(auxGuardianRelated);
      const responseRelated = idsRelated
        ? await GetRelatedPerson({
            id: idsRelated,
            // includePatient: true,
          }).then((response) => response?.data?.entry)
        : undefined;

      if (responseRelated) {
        const auxRelatedPerson = responseRelated?.filter(
          (e: any) => e?.resource.resourceType === "RelatedPerson"
        );

        setRelatedPerson(auxRelatedPerson);
        const patientPromises = responseRelated?.map(async (el: any) => {
          const response = await GetPatient({
            identifier: el?.resource?.patient?.reference.split("-")[1],
          });
          const auxRelated = auxRelatedPerson?.find((el: any) => {
            return el.resource?.patient?.reference?.includes(
              response?.data?.id
            );
          });

          return {
            ...response?.data,
            isVerify: auxRelated?.resource?.active,
            period: auxRelated?.resource?.period,
            relatedId: auxRelated?.resource?.id,
          };
        });

        const patientsRelated = await Promise.all(patientPromises);

        setPatientRelated(patientsRelated);
      }

      updateDeviceIdWeb(
        extractScim2Data?.tokenFirebaseWeb,
        extractScim2Data?.id
      );
      return extractScim2Data;
    } else throw new Error();
  }

  async function fetchUserRoles() {
    const username = localStorage.getItem("username");
    const practitionerRolesReponse = await GetPractitionerRole({
      practitioner: `${oids.cpf}-${username}`,
      includeOrganization: true,
    }).then((response) => {
      return response?.entry;
    });

    const practitionerRoles = practitionerRolesReponse?.filter(
      (e: any) => e.resource.resourceType === "PractitionerRole"
    );
    const organizations = practitionerRolesReponse?.filter(
      (e: any) => e.resource.resourceType === "Organization"
    );

    const verifyRoles = practitionerRoles
      ?.filter((el: any) => {
        const isValidOrganization = organizations
          ?.find(
            (e: any) =>
              e.resource.id ===
              el?.resource?.organization?.reference?.split("/")[1]
          )
          ?.resource?.extension?.find((ext: any) =>
            ext?.url.includes("OrganizationManager")
          );

        return (
          (el?.resource.active === undefined ||
            el?.resource.active !== false) &&
          isValidOrganization
        );
      })
      ?.map((element: any) => useFhirData.extractPracitionerRole(element));

    if (!verifyRoles?.length) {
      setAccess({ type: "patient" });
      setRoles([]);
      return;
    }

    if (verifyRoles?.find((el: any) => el.isInvited)) setInviteDialog(true);

    if (access.type === undefined) {
      setAccess({ type: null });
    } else if (access.type === "professional") {
      setAccess((prev) => ({
        ...prev,
        roles: getRolesArray(
          verifyRoles.find((el: IRole) =>
            el.organization?.reference.includes(access.organizationId)
          )
        ),
      }));
    }
    setRoles(verifyRoles);

    return;
  }

  function getRolesArray(data: IRole): AccessRole[] {
    const roles: AccessRole[] = [];
    if (data?.isManager) roles.push("manager");
    if (data?.isPractitioner) roles.push("practitioner");
    if (data?.isOwner) roles.push("owner");
    return roles;
  }

  const updateDeviceIdWeb = async (webId: any, userId: any) => {
    const device = await useFirebaseService.requestNotificationPermission();
    if (!webId && device)
      await DeviceIDPatch({
        idUser: userId,
        operation: "add",
        pathName:
          "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User:deviceIDWeb",
        valueField: device,
      });
    if (webId !== undefined && webId !== device && !isMobile) {
      await DeviceIDPatch({
        idUser: userId,
        operation: "replace",
        pathName:
          "urn:ietf:params:scim:schemas:extension:enterprise:2.0:User:deviceIDWeb",
        valueField: device,
      });
    }
  };

  useEffect(() => {
    localStorage.setItem("access", JSON.stringify(access));
    navigate("/");
  }, [access]);

  useEffect(() => {
    setLoadingAppointments(true);
    async function asyncCall() {
      try {
        await fetchUser();
        await fetchUserRoles();
      } catch (err) {
        localStorage.clear();
        navigate("/signin");
        toast.warn(
          "Suas credenciais expiraram. Por favor faça o login novamente!"
        );
      } finally {
        setLoadingAppointments(false);
      }
    }

    if (localStorage.getItem("logged") === "true") asyncCall();
  }, [refetch, access?.type]);

  useEffect(() => {
    async function fetchPerson() {
      try {
        const username = localStorage.getItem("username");
        const response = await GetPerson({
          identifier: username,
        }).then((response) => response);
        setTermsDialog(!response?.active);

        setPerson(response);
      } catch (err) {
        console.log("err", err);
      }
    }

    fetchPerson();
  }, []);
  const loading =
    access.type === undefined ||
    Object.entries(user).length === 0 ||
    roles === undefined ||
    loadingAppointments;

  const contextValue: SessionContextValue = {
    getRolesArray,
    fetchUser,
    fetchUserRoles,
    setRefetch,
    setAccess,
    setInviteDialog,
    setFirstAccessDialog,
    setIsOpenTermDialog,
    setTerminateAccount,
    firstAccessDialog,
    isOpenTermDialog,
    user,
    roles,
    access,
    loading,
    inviteDialog,
    userPatient,
    relatedPerson,
    patientRelated,
    guardianRelated,
    person,
    termsDialog,
    terminateAccount,
    termRefused,
    userNavigable,
    userMaintainer,
  };

  const dialogIsOpen = !person?.active && !isOpenTermDialog;
  return (
    <SessionContext.Provider value={contextValue}>
      {termsDialog &&
        !loading &&
        (userNavigable ? (
          <TermOfUseDialog isOpen={dialogIsOpen && !termRefused} update />
        ) : (
          <TermOfUseDialog isOpen={dialogIsOpen && !userNavigable} update />
        ))}
      <OrganizationContextProvider>
        <ViewContextProvider>
          <LoggedUserContextProvider>
            <ClinicalRegisterContextProvider>
              <PrivacyContextProvider>
                <ConsentContextProvider>
                  <PatientHealthCareInformationContextProvider>
                    <ContractContextProvider>
                      <SideBarContextProvider>
                        <AlertInviteDialog />
                        <SignatureControlContextProvider>
                          <SignatureContextProvider>
                            <PrescriptionAttendenceContextProvider>
                              <QuestionnaireContextProvider>
                                <MedicalCareContextProvider>
                                  <InterventionsContextProvider>
                                    <CustomizationContextProvider>
                                      <WarningCentralContextProvider>
                                        {children}
                                      </WarningCentralContextProvider>
                                    </CustomizationContextProvider>
                                  </InterventionsContextProvider>
                                </MedicalCareContextProvider>
                              </QuestionnaireContextProvider>
                            </PrescriptionAttendenceContextProvider>
                          </SignatureContextProvider>
                        </SignatureControlContextProvider>
                      </SideBarContextProvider>
                    </ContractContextProvider>
                  </PatientHealthCareInformationContextProvider>
                </ConsentContextProvider>
              </PrivacyContextProvider>
            </ClinicalRegisterContextProvider>
          </LoggedUserContextProvider>
        </ViewContextProvider>
      </OrganizationContextProvider>
    </SessionContext.Provider>
  );
}

export function useSessionContext() {
  return useContext(SessionContext);
}
