import { Divider, Stack, Typography } from "@mui/material";
import { EditableCardRow } from "../../../components/common";

import React, { Dispatch, SetStateAction, useState } from "react";
import { UploadLogoDialog } from "./LogoDialog";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { useFhirData } from "../../../utils/useFhirData";

interface IProps {
  setIsEditCnesOpen: Dispatch<SetStateAction<boolean>>;
  cnesValue: string;
  inscricaoEstadual: string;
  displayNameValue: string;
  phoneNumber: any;
  setIsEditAliasOpen: Dispatch<SetStateAction<boolean>>;
  setIsEditAddressOpen: Dispatch<SetStateAction<boolean>>;
  setIsEditEnrollmentOpen: Dispatch<SetStateAction<boolean>>;
}

export function CardProfileClinic({
  setIsEditCnesOpen,
  cnesValue,
  inscricaoEstadual,
  displayNameValue,
  phoneNumber,
  setIsEditAliasOpen,
  setIsEditAddressOpen,
  setIsEditEnrollmentOpen,
}: IProps) {
  const [openUploadLogo, setOpenUploadLogo] = useState(false);
  const { organization, refetchOrganization } = useOrganizationContext();

  const brandLogoOrganization = useFhirData?.brandLogoOrganization(
    organization?.extension
  );

  return (
    <Stack
      id="profileClinic"
      sx={{
        border: "1px solid",
        borderColor: "neutral700.main",
        borderRadius: "15px",
      }}
      width="100%"
      paddingTop={2}
    >
      <Typography
        variant="h5"
        fontWeight="500"
        paddingBottom={3}
        paddingLeft={5}
      >
        Perfil da Clínica
      </Typography>

      <EditableCardRow
        title="Logo da clínica"
        type={brandLogoOrganization ? "edit" : "add"}
        titleContext={
          brandLogoOrganization ? (
            <img
              alt="Brand Logo"
              width={"16%"}
              src={brandLogoOrganization || ""}
            />
          ) : (
            " Acrescente a logomarca da sua clínica."
          )
        }
        onClick={() => {
          setOpenUploadLogo(true);
        }}
      />

      <EditableCardRow
        title="Nome de apresentação"
        titleContext={displayNameValue || "Nome de apresentação da clínica"}
        onClick={() => {
          setIsEditAliasOpen(true);
        }}
      />

      <EditableCardRow
        title="Número de inscrição estadual"
        titleContext={
          inscricaoEstadual || "Insira o número de inscrição estadual"
        }
        onClick={() => {
          setIsEditEnrollmentOpen(true);
        }}
      />

      <EditableCardRow
        title="Número CNES"
        titleContext={
          cnesValue || "Você pode adicionar o CNES da sua clínica aqui."
        }
        onClick={() => {
          setIsEditCnesOpen(true);
        }}
      />

      <EditableCardRow
        title="Telefone da clínica"
        titleContext={phoneNumber || "Adicione o telefone da sua clínica aqui."}
        onClick={() => {
          setIsEditAddressOpen(true);
        }}
      />

      <Divider sx={{ marginLeft: 5 }} />
      {openUploadLogo && (
        <UploadLogoDialog
          open={openUploadLogo}
          handleClose={() => {
            refetchOrganization();
            setOpenUploadLogo(false);
          }}
        />
      )}
    </Stack>
  );
}
