import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { useFormatter } from "../../../../utils/useFormatter";
import { useDatetime } from "../../../../utils/useDatetime";
import { History, WhatsApp } from "@mui/icons-material";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";
import { useInternationalVerify } from "../../../../utils/useInternationalVerify";

export function Header() {
  const { formatNameDisplay, formatGender, formatAge } = useFormatter;
  const { formatDate } = useDatetime;

  const { setOpenSummary, patient } = useMedicalCareContext();
  const handleClickSummary = () => {
    setOpenSummary(true);
  };

  const auxAddress = patient?.address?.[0];

  const city = auxAddress?.city || "";
  const addressState = auxAddress?.state || "";
  const postalCode = auxAddress?.postalCode || "";
  const line = auxAddress?.line?.join(", ") || "";

  const address =
    [line, city, addressState, postalCode]
      ?.filter((el: any) => el)
      ?.join(" , ") || "-";

  const phone = patient?.telecom?.find((e: any) => e.system === "phone")?.value;

  const cpf = patient?.id?.split("-")?.[1];

  const data = [
    {
      label: "Nome",
      description: formatNameDisplay(patient?.name?.[0]?.text || "-"),
    },
    {
      label: "CPF",
      description: cpf,
    },
    {
      label: "Telefone",
      description: (
        <>
          <Stack
            sx={{ height: "20px" }}
            direction="row"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Typography>
              {useFormatter.formatPhoneNumber(phone || "")}
            </Typography>
            <Tooltip title={useFormatter.formatPhoneNumber(phone || "")}>
              <IconButton
                color="primary"
                onClick={() =>
                  window.open(
                    `https://web.whatsapp.com/send?phone=+${useInternationalVerify.verifyPhoneDDI(
                      phone
                    )}`,
                    "_blank"
                  )
                }
              >
                <WhatsApp />
              </IconButton>
            </Tooltip>
          </Stack>
        </>
      ),
    },

    {
      label: "Sexo de nascimento",
      description: formatGender(patient?.gender) || "-",
    },
    {
      label: "Idade",
      description: patient?.birthDate
        ? `${formatAge(`${patient?.birthDate}`)} anos`
        : "-",
    },
    {
      label: "Data de nascimento",
      description: patient?.birthDate
        ? formatDate(`${patient?.birthDate}`)
        : "-",
    },
    {
      label: "Endereço",
      description: address || "-",
    },
    {
      label: "Raça/Etnia",
      description:
        patient?.extension?.find((ext: any) =>
          ext.url.includes("BRRacaCorEtnia")
        )?.valueCodeableConcept?.coding?.[0]?.display || "-",
    },
  ];

  return (
    <>
      <Box pl={6} sx={{ direction: "row" }}>
        <Card
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 1,
            width: "100%",
            maxWidth: "none",
            border: "none",
            boxShadow: "none",
          }}
        >
          {/* Conteúdo (80%) */}
          <Grid container sx={{ width: "100%" }} columnGap={4}>
            {data?.map((el) => (
              <>
                <Grid item key={`patient-information-${el.label}`}>
                  <Stack direction="column">
                    <Stack direction="row">
                      <Typography
                        variant="body1"
                        color="neutral1000.main"
                        fontWeight={500}
                      >
                        {el.label}
                      </Typography>
                    </Stack>
                    <Typography variant="body1" color="neutral700.main">
                      {el.description}
                    </Typography>
                  </Stack>
                </Grid>
              </>
            ))}
          </Grid>

          <Button
            sx={{
              width: "200px",
              textTransform: "none",
            }}
            startIcon={<History />}
            onClick={handleClickSummary}
          >
            Sumário de saúde
          </Button>
        </Card>
      </Box>
    </>
  );
}
