import { TableBody, TableCell, TableRow, Tooltip } from "@mui/material";
import { StatusRow } from "./StatusRow";
import { isMobile } from "react-device-detect";

export function GenericTableBody({
  rows,
  startIndex,
  endIndex,
  rowPath,
  actions,
  isTooltip,
}: any) {
  return (
    <TableBody>
      {rows?.slice(startIndex, endIndex).map((row: any, index: number) => {
        return (
          <TableRow
            key={index}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              backgroundColor: row.ends === "Inativo" ? "grey.100" : "inherit", // Estiliza como desabilitado se inativo
            }}
          >
            {isMobile ? (
              <>
                {actions && actions(row)}
                {rowPath?.map((el: any, index: number) => (
                  <TableCell
                    sx={{
                      textDecoration:
                        row.ends === "Inativo" ? "line-through" : "none",
                    }}
                    align="center"
                    key={`tableBodyCell-${index}`}
                  >
                    {row?.[el]
                      ? row?.[el]?.[0]?.props?.children.length > 30
                        ? `${row?.[el]?.[0]?.props?.children.substring(
                            0,
                            30
                          )}...`
                        : row[el]
                      : "-"}
                  </TableCell>
                ))}
              </>
            ) : (
              <>
                {rowPath?.map((el: any, index: number) => {
                  return (
                    <>
                      {isTooltip ? (
                        <>
                          {el === "description" || el === "category" ? (
                            <Tooltip
                              placement="right"
                              title={row?.[el] || ""}
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, -90],
                                      },
                                    },
                                  ],
                                },
                              }}
                            >
                              <TableCell
                                sx={{
                                  textDecoration:
                                    row.ends === "Inativo"
                                      ? "line-through"
                                      : "none",
                                }}
                                align="center"
                                key={`tableBodyCell-${index}`}
                              >
                                <>
                                  {row?.[el]?.length
                                    ? row?.[el]?.[0]?.props?.children.length >
                                        30 || row?.[el]?.length > 30
                                      ? `${
                                          row?.[
                                            el
                                          ]?.[0]?.props?.children.substring(
                                            0,
                                            30
                                          ) || row?.[el]?.substring(0, 30)
                                        }...`
                                      : row[el]
                                    : "-"}
                                </>
                              </TableCell>
                            </Tooltip>
                          ) : (
                            <TableCell
                              sx={{
                                textDecoration:
                                  row.ends === "Inativo"
                                    ? "line-through"
                                    : "none",
                              }}
                              align="center"
                              key={`tableBodyCell-${index}`}
                            >
                              {el === "status" ? (
                                <StatusRow status={row?.[el]} />
                              ) : (
                                row?.[el] || "-"
                              )}
                            </TableCell>
                          )}
                        </>
                      ) : (
                        <TableCell
                          sx={{
                            textDecoration:
                              row.ends === "Inativo" ? "line-through" : "none",
                          }}
                          align="center"
                          key={`tableBodyCell-${index}`}
                        >
                          {el === "status" ? (
                            <StatusRow status={row?.[el]} />
                          ) : (
                            row?.[el] || "-"
                          )}
                        </TableCell>
                      )}
                    </>
                  );
                })}
                {actions && actions(row)}
              </>
            )}
          </TableRow>
        );
      })}
    </TableBody>
  );
}
