import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Stack, Tab, Typography } from "@mui/material";
import { CheckCircle, HighlightOffRounded } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { PrivateMainLayout } from "../../components/layout";
import {
  LargeContentBox,
  PageTitle,
  PrimaryButton,
  SecondaryButton,
} from "../../components/common";
import { GenericTable } from "../../components/GenericTable/GenericTable";
import { useLocationContext } from "../../contexts/LocationContext";
import { ManageLocationActions } from "./components/ManageLocationActions";
import { CreateLocationDialog } from "./components/CreateLocationDialog";

export function ManageLocation() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const { location } = useLocationContext();
  const [editData, setEditData] = useState<any>();

  const formatAddress = (addressData?: any) => {
    const auxAddress = addressData;
    const city = auxAddress?.city;
    const state = auxAddress?.state;
    const postalCode = auxAddress?.postalCode;

    return auxAddress.line
      ? auxAddress?.line?.join(", ")
      : Object.keys(auxAddress)?.length === 1
      ? "-"
      : `${city && city}, ${state && state}, ${postalCode && postalCode}`;
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const columns = [
    {
      column: "Nome",
      rowName: "name",
    },
    { column: "Endereço", rowName: "addressFormated" },
    { column: "Telefone", rowName: "phoneNumber" },
    { column: "Tipo", rowName: "type" },
  ];

  const rows = location?.map(
    (elLocation: fhir5.BundleEntry<fhir5.Location>) => {
      const data = {
        fullResource: elLocation?.resource,
        id: elLocation?.resource?.id,
        name: elLocation?.resource?.name,
        status: elLocation?.resource?.status,
        address: elLocation?.resource?.address,
        addressFormated: formatAddress(elLocation?.resource?.address),
        phoneNumber:
          elLocation?.resource?.contact?.[0]?.telecom?.[0]?.value || "-",
        type:
          formatAddress(elLocation?.resource?.address) !== "-"
            ? "Presencial"
            : "Remoto",
      };
      return data;
    }
  );

  return (
    <PrivateMainLayout>
      <LargeContentBox>
        <Stack width="100%">
          <Stack spacing={3} width="100%" alignItems="center">
            <PageTitle>Localizações</PageTitle>
            <Typography
              variant="body1"
              color="neutral700.main"
              textAlign="center"
            >
              Gerencie os locais de atendimento da sua clínica.
            </Typography>
          </Stack>
          <TabContext value={selectedTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TabList onChange={handleChangeTab}>
                <Tab
                  style={{ display: "flex", alignItems: "center" }}
                  label={
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <CheckCircle sx={{ marginRight: 1 }} />
                      Ativas
                    </Box>
                  }
                  value="1"
                />

                <Tab
                  style={{ display: "flex", alignItems: "center" }}
                  label={
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <HighlightOffRounded sx={{ marginRight: 1 }} />
                      Inativas
                    </Box>
                  }
                  value="2"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <GenericTable
                tableColumns={columns}
                tableRows={rows?.filter((e: any) => e.status === "active")}
                actions={(rowData: any) => (
                  <ManageLocationActions
                    row={rowData}
                    setOpenModalEdit={setIsOpen}
                    setEditData={setEditData}
                  />
                )}
              />
            </TabPanel>
            <TabPanel value="2">
              <GenericTable
                tableColumns={columns}
                tableRows={rows?.filter((e: any) => e.status !== "active")}
                actions={(rowData: any) => (
                  <ManageLocationActions
                    row={rowData}
                    setOpenModalEdit={setIsOpen}
                    setEditData={setEditData}
                  />
                )}
              />
            </TabPanel>
          </TabContext>
        </Stack>

        <Stack width="100%" direction="row" justifyContent="space-between">
          <SecondaryButton
            onClick={() => navigate(-1)}
            width="180px"
            height="50px"
          >
            Voltar
          </SecondaryButton>

          <PrimaryButton
            width="fit-content"
            height="50px"
            onClick={() => setIsOpen(true)}
          >
            Nova localização
          </PrimaryButton>
        </Stack>

        {isOpen && (
          <CreateLocationDialog
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            editData={editData}
            setEditData={setEditData}
          />
        )}
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
