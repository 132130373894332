import axios, { AxiosError } from "axios";
import { SubscriptionData, PreapprovalResponse, ApiResponse } from "../../pages/registerorganization/types";



export class SubscriptionService {
  private readonly apiUrl = `${process.env.REACT_APP_ENDPOINT_BILLING}/subscribe`;

  async createSubscription(
    subscriptionData: SubscriptionData
  ): Promise<PreapprovalResponse> {
    try {
      const response = await axios.post<PreapprovalResponse>(
        this.apiUrl,
        subscriptionData
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(
          "Erro ao enviar a solicitação de assinatura:",
          error.message
        );
        if (error.response) {
          console.error("Detalhes do erro:", error.response.data);
        }
        throw new Error(`Erro na solicitação: ${error.message}`);
      } else {
        console.error("Ocorreu um erro desconhecido:", error);
        throw new Error('Ocorreu um erro desconhecido ao criar a assinatura');
      }
    }
  }

  async getSubscriptionPlans(): Promise<
    {
      plan: PreapprovalResponse
    }[]
  > {
    try {
      const response = await axios.get<ApiResponse>(
        `${process.env.REACT_APP_ENDPOINT_BILLING}/subscriptionPlans`
      );
      const subscriptionPlans = response.data.results;

      const formattedPlans = subscriptionPlans.map((selectedPlan) => ({
        plan: selectedPlan
    }));

      return formattedPlans;
    } catch (error) {
      console.error("Erro ao obter os planos de assinatura:", error);
      throw error;
    }
  }
}
