import { Link, Stack, Typography } from "@mui/material";
import {
  PrimaryButton,
  PrimaryLink,
  SecondaryButton,
} from "../../../components/common";

export default function NoticeOfTerms({
  supportLink,
  setTerminateAccount,
  isManagerOrOwner,
  setIsOpenTermDialog,
  isOpenTermDialog,
}: {
  supportLink: string;
  setTerminateAccount: Function;
  isManagerOrOwner?: boolean;
  setIsOpenTermDialog?: Function;
  isOpenTermDialog?: boolean;
}) {
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyItems={"flex-start"}
        width={"100%"}
      >
        <PrimaryLink text="Feedback & Suporte" link={supportLink} />
      </Stack>
      <Stack spacing={4} width={"100%"} alignItems={"center"}>
        <Typography
          variant="h5"
          fontWeight={500}
          textAlign="center"
          marginBottom={2}
        >
          Você não aceitou o Termo de Uso!
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ fontSize: "16px" }}
          fontStyle={"italic"}
          fontWeight={500}
        >
          Para continuar utilizando o Fluxmed, é necessário aceitar os Termos de
          Uso!
        </Typography>

        <Stack
          direction={"column"}
          justifyItems={"center"}
          alignItems={"flex-start"}
          width={"100%"}
          spacing={1}
        >
          <Typography
            variant="body1"
            textAlign="justify"
            color={"neutral700.main"}
            fontWeight={500}
          >
            Não concordando com os termos de uso, você pode:
          </Typography>

          {isManagerOrOwner ? (
            <Link
              component="button"
              variant="body1"
              onClick={() => setTerminateAccount(true)}
              sx={{
                color: "primary",
                wordBreak: "break-word",
                textAlign: "justify",
                ml: 2,
              }}
              fontWeight={500}
            >
              Encerrar a conta.
            </Link>
          ) : (
            <Link
              component="button"
              variant="body1"
              onClick={() => setTerminateAccount(true)}
              sx={{
                color: "primary",
                wordBreak: "break-word",
                textAlign: "justify",
                ml: 2,
              }}
              fontWeight={500}
            >
              Encerrar a conta.
            </Link>
          )}

          <Typography
            variant="body1"
            textAlign="justify"
            sx={{
              color: "gray",
              display: "flex",
              wordBreak: "break-word",
              fontSize: "14px",
              ml: 2,
            }}
            fontWeight={500}
          >
            Revisar Termos de Uso e aceitar para continuar utilizando a
            plataforma.
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        width={"100%"}
        justifyItems={"center"}
        alignItems={"center"}
      >
        <SecondaryButton
          onClick={() => setTerminateAccount(true)}
          width="fit-content"
          height="36"
        >
          Encerrar conta
        </SecondaryButton>

        <PrimaryButton
          width="100%"
          height="50px"
          onClick={() => setIsOpenTermDialog?.(!isOpenTermDialog)}
        >
          Revisar Termo de Uso
        </PrimaryButton>
      </Stack>
    </>
  );
}
