import { FormControl, MenuItem, Select, Stack } from "@mui/material";
import { useSessionContext } from "../../contexts/SessionContext";

import { useOrganizationContext } from "../../contexts/OrganizationContext";
import { useContractContext } from "../../contexts/ContractContext";

export function OrganizationSelect() {
  const { organization, organizationList } = useOrganizationContext();
  const { setAccess, roles, access } = useSessionContext();
  const { currentContract } = useContractContext();

  function getRoles(data: any) {
    return [
      ...(data?.isManager ? ["manager"] : []),
      ...(data?.isPractitioner ? ["practitioner"] : []),
      ...(data?.isOwner ? ["owner"] : []),
      ...(data?.isInvited ? ["invited"] : []),
    ];
  }

  const handleChange = (data?: any) => {
    const auxOrganizationId = data.organization.reference
      ?.split("/")[1]
      ?.split("-")[1];

    setAccess({
      type: "professional",
      organizationId: auxOrganizationId,
      roles: getRoles(data),
    });

    localStorage.setItem("organizationId", auxOrganizationId);

    //verificar contratos da clinica selecionada
    //verificar se é profissional alem de owner e manager
    // redirecionar para tela de perfil com cancelamento aberto se tiver recusado o termo
  };

  return (
    <>
      {roles?.find(
        (el) => el.isPractitioner && access.type === "professional"
      ) && (
        <Stack sx={{ backgroundColor: "primary100.main", borderRadius: "5px" }}>
          <FormControl
            variant="filled"
            hiddenLabel
            fullWidth
            size="small"
            sx={{
              "& .MuiFilledInput-root": {
                backgroundColor: "primary100.main",
                width: "300px",
                ":hover": { backgroundColor: "primary100.main" },
                ":&focus": { backgroundColor: "primary100.main" },
              },
            }}
          >
            <Select
              sx={{ borderRadius: "20px" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={organization?.id?.split("-")[1]}
            >
              {roles.map((el) => {
                const auxOrganization = organizationList?.find(
                  (e: fhir5.BundleEntry<fhir5.Organization>) =>
                    e?.resource?.id ===
                    el?.organization?.reference?.split("/")[1]
                );

                return (
                  <MenuItem
                    value={
                      el.organization?.reference?.split("/")[1]?.split("-")[1]
                    }
                    onClick={() => handleChange(el)}
                    key={`org-select-${el.organization?.display}`}
                  >
                    {auxOrganization?.resource?.alias?.[0] !== "null"
                      ? auxOrganization?.resource?.alias?.[0]
                      : auxOrganization?.resource?.name ||
                        el?.organization?.display ||
                        "-"}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>
      )}
    </>
  );
}
