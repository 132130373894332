import { Box, DialogTitle, Stack } from "@mui/material";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { useState } from "react";

import { CloudUpload } from "@mui/icons-material";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { PrimaryButton, SecondaryButton } from "../../../components/common";
import { useFhirData } from "../../../utils/useFhirData";
import { PutOrganization } from "../../../services/fhir";
import { PreviewLogo } from "./brandLogo/PreviewLogo";
import { ReferenceDetails } from "./brandLogo/ReferenceDetails";
import { toast } from "react-toastify";

interface IProps {
  open: boolean;
  handleClose: Function;
}

export function UploadLogoDialog({ open, handleClose }: IProps) {
  const { organization, refetchOrganization } = useOrganizationContext();
  const [preview, setPreview] = useState<string | null>(null);
  const [file, setFile] = useState<Object | null>(null);
  const [removed, setRemoved] = useState(false);
  const [fileInfo, setFileInfo] = useState<string | null>(null);
  const [errorFile, setErrorFile] = useState<boolean>(false);

  const brandLogoOrganization = useFhirData?.brandLogoOrganization(
    organization?.extension
  );

  const RemoveOrganizationLogoExtension = async (organization: any) => {
    if (organization?.extension) {
      organization.extension = organization.extension.filter(
        (extension: any) => !extension.url.includes("OrganizationLogo")
      );
    }

    try {
      await PutOrganization(organization);
    } catch (error) {
      console.error(error);
    } finally {
      toast.success("A logo foi removida com sucesso!");
    }
  };

  const AddOrganizationLogoExtension = async (organization: any, file: any) => {
    if (!organization?.extension) {
      organization.extension = [];
    }

    const data = {
      url: "https://fluxmed.cloud/StructureDefinition/OrganizationLogo",
      valueAttachment: {
        contentType: file?.contentType,
        data: file?.data,
      },
    };

    organization.extension.push(data);

    try {
      await PutOrganization(organization);
    } catch (error) {
      console.error(error);
    } finally {
      refetchOrganization();
      toast.success("A logo adicionada com sucesso!");
    }
  };

  const clearFile = () => {
    setFile(null);
    setFileInfo(null);
    setPreview(null);
  };

  return (
    <CommonDialog isOpen={open} handleClose={handleClose}>
      <DialogTitle align="center" sx={{ padding: 0, marginBottom: 2 }}>
        Logo perfil da clínica
      </DialogTitle>
      <Stack sx={{ p: 2 }} spacing={4}>
        {preview && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <PreviewLogo
              preview={preview}
              clearFile={clearFile}
              fileInfo={fileInfo}
            />
          </Stack>
        )}
        {((!preview && !brandLogoOrganization) || removed) && (
          <Stack direction="row" spacing={2}>
            <ReferenceDetails errorFile={errorFile} />
          </Stack>
        )}
        {brandLogoOrganization && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              sx={{
                display: "flex",
                border: "1px solid",
                borderColor: "neutral200.main",
                borderRadius: "8px",
                padding: "16px",
                width: "144px",
                height: "144px",
              }}
            >
              <img
                src={brandLogoOrganization || ""}
                alt="Logo da clínica"
                width="100%"
                style={{
                  objectFit: "contain",
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            </Box>
          </Stack>
        )}

        {file ? (
          <PrimaryButton
            height="50px"
            width="100%"
            onClick={async () => {
              setPreview(null);
              setFile(null);
              handleClose();

              await AddOrganizationLogoExtension(organization, file);
            }}
          >
            Salvar
          </PrimaryButton>
        ) : brandLogoOrganization ? (
          <SecondaryButton
            height="50px"
            width="100%"
            onClick={async () => {
              setPreview(null);
              setFile(null);
              setRemoved(true);
              await RemoveOrganizationLogoExtension(organization);
            }}
          >
            Remover Logo
          </SecondaryButton>
        ) : (
          <PrimaryButton
            height="50px"
            width="100%"
            onClick={() => {
              const input = document.createElement("input");
              input.type = "file";
              input.accept = "image/png, image/jpeg";
              input.click();

              input.onchange = async () => {
                const file = input.files?.[0];

                if (!file) return;

                const allowedTypes = ["image/png", "image/jpeg"];

                if (!allowedTypes.includes(file.type)) {
                  setErrorFile(true);
                  return;
                }

                setPreview(URL.createObjectURL(file));

                const base64 = await new Promise<string>((resolve, reject) => {
                  const reader = new FileReader();
                  reader.onload = () => resolve(reader.result as string);
                  reader.onerror = (error) => reject(error);
                  reader.readAsDataURL(file);
                });

                setFileInfo(
                  `${file?.name} (${(file?.size / 1024 / 1024).toFixed(2)} MB)`
                );

                setErrorFile(false);
                setRemoved(false);
                setFile({
                  data: base64?.split(",")[1],
                  contentType: base64?.match(/^data:(.*?);base64,/)?.[1],
                });
              };
            }}
          >
            <CloudUpload sx={{ mr: 1 }} /> Adicionar logo
          </PrimaryButton>
        )}
      </Stack>
    </CommonDialog>
  );
}
