import { Stack } from "@mui/material";
import {
  PageTitle,
  PrimaryButton,
  SecondaryButton,
} from "../../../components/common";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { ManageProtocolActions } from "./ManageProtocolActions";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useDict } from "../../../utils/useDict";
import { useFormatter } from "../../../utils/useFormatter";
import { useContractContext } from "../../../contexts/ContractContext";

export function ManageProtocol({ data, setRefetch }: any) {
  const { practitioner } = usePractitionerRoleContext();
  const { currentContract } = useContractContext();
  const navigate = useNavigate();
  const columns = [
    {
      column: "Nome",
      rowName: "name",
    },
    // { column: "Organização", rowName: "organization" },
    { column: "Autor", rowName: "practioner" },
    { column: "Status", rowName: "statusValue" },
    { column: "Ultima modificação", rowName: "lastUpdated" },
    { column: "Ultimo editor", rowName: "subject" },
    { column: "Versões", rowName: "version" },
  ];

  const rows = data?.map((el: any) => {
    // const auxOrganization = organizationList?.find(
    //   (organization: any) =>
    //     organization?.resource?.id?.split("/")?.pop() ===
    //     el?.resource?.author?.reference?.split("/")?.pop()
    // )?.resource?.alias?.[0];
    const auxPracitioner = useFormatter.formatNameDisplay(
      practitioner?.find(
        (practitioners: any) =>
          practitioners?.resource?.id ===
          el?.resource?.source?.reference?.split("/")?.pop()
      )?.resource?.name?.[0]?.text || "-"
    );

    const auxLastEditor = useFormatter.formatNameDisplay(
      practitioner?.find(
        (practitioners: any) =>
          practitioners?.resource?.id ===
          el?.resource?.subject?.reference?.split("/")?.pop()
      )?.resource?.name?.[0]?.text || "-"
    );

    const auxName = el?.resource?.item?.find(
      (e: any) => e.linkId === "438586562091"
    )?.answer?.[0]?.valueString;

    return {
      id: el?.resource?.id,
      statusValue: el?.resource?.status
        ? useDict.protocolStatus[el?.resource?.status]
        : "-",
      name: auxName || "",
      // organization: auxOrganization,
      practioner: auxPracitioner,
      subject: auxLastEditor || auxPracitioner,
      itens: el,
      version: el?.resource?.meta?.versionId,
      lastUpdated: dayjs(el?.resource?.meta?.lastUpdated)?.format("DD/MM/YYYY"),
    };
  });

  return (
    <>
      <PageTitle>Gerenciar protocolos</PageTitle>

      <Stack width="100%" sx={{ height: "100%" }}>
        <GenericTable
          tableColumns={columns}
          tableRows={rows}
          actions={(row: any) => (
            <ManageProtocolActions
              row={row}
              data={data}
              setRefetch={setRefetch}
            />
          )}
        />
      </Stack>

      <Stack width="100%" direction="row" justifyContent="space-between">
        <SecondaryButton
          onClick={() => navigate(-1)}
          width="180px"
          height="50px"
        >
          Voltar
        </SecondaryButton>

        <PrimaryButton
          width="fit-content"
          height="50px"
          onClick={() => navigate("/createprotocol")}
          disabled={!currentContract}
        >
          Novo protocolo
        </PrimaryButton>
      </Stack>
    </>
  );
}
