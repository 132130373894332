import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Description, PersonAdd } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { useFormatter } from "../../../utils/useFormatter";
import { useState } from "react";
import { CreateAppointmentDialog } from "../../schedulemanager/components/creatappointmentdialog";
import { useViewContext } from "../../../contexts/ViewContext";
import { useContractContext } from "../../../contexts/ContractContext";

export function PatientSearchActions({ row }: any) {
  const { currentContract } = useContractContext();
  const { availableRoles } = useViewContext();
  const navigate = useNavigate();
  const [createAppointmentDialogOpen, setCreateAppointmentDialogOpen] =
    useState<boolean>(false);
  return (
    <TableCell align="center">
      <Tooltip title="Realizar encaixe">
        <IconButton
          onClick={() => setCreateAppointmentDialogOpen(true)}
          disabled={!currentContract}
        >
          <PersonAdd />
        </IconButton>
      </Tooltip>

      {createAppointmentDialogOpen && (
        <CreateAppointmentDialog
          isOpen={createAppointmentDialogOpen}
          setModalOpen={setCreateAppointmentDialogOpen}
          cpfT={useFormatter.formatCPF(row?.identifier)}
          walkin
        />
      )}

      {availableRoles?.includes("practitioner") && (
        <Tooltip title="Acessar Registros ">
          <IconButton
            onClick={() => {
              if (row.identifier)
                navigate("/summary", {
                  state: {
                    patientId: row?.identifier?.replace(/[^a-zA-Z0-9]/g, ""),
                  },
                });
            }}
          >
            <Description />
          </IconButton>
        </Tooltip>
      )}
    </TableCell>
  );
}
