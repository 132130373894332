/* eslint-disable no-useless-escape */
function isBrazilianPhoneNumber(phone: string): boolean {
  // Remover espaços, traços, parênteses e "+" do número
  const cleanedPhone = phone?.replace(/[\s\-\(\)\+]/g, "");

  // Verificar se começa com DDI 55 e remover
  const phoneWithoutDDI = cleanedPhone.startsWith("55")
    ? cleanedPhone.slice(2)
    : cleanedPhone;

  // Verificar se tem 11 dígitos (2 dígitos de DDD + 9 dígitos do número)
  if (phoneWithoutDDI.length !== 11) {
    return false;
  }

  // Verificar se o número começa com "9" após o DDD (números móveis no Brasil começam com 9)
  const startsWithNine = phoneWithoutDDI[2] === "9";

  return startsWithNine;
}

function verifyPhoneDDI(phone: string): string {
  // Remover espaços, traços, parênteses e "+" do número
  const cleanedPhone = phone.replace(/[\s\-\(\)\+]/g, "");

  // Verificar se é um número brasileiro
  if (!isBrazilianPhoneNumber(cleanedPhone)) {
    return phone; // Retorna o número original se não for brasileiro
  }

  // Adicionar DDI 55 se não estiver presente
  return cleanedPhone.startsWith("55") ? cleanedPhone : `55${cleanedPhone}`;
}

export const useInternationalVerify = {
  isBrazilianPhoneNumber,
  verifyPhoneDDI,
};
