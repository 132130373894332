import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";

import { useViewContext } from "../../../contexts/ViewContext";
import { useFhirData } from "../../../utils/useFhirData";
import { useFormatter } from "../../../utils/useFormatter";
import { DataSkeleton } from "../../../components/common";
import { GenericTable } from "../../../components/GenericTable/GenericTable";
import { NextAppointmentsActions } from "./NextAppoinmentsActions";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { WhatsApp } from "@mui/icons-material";
import { useInternationalVerify } from "../../../utils/useInternationalVerify";

interface IProps {
  data: fhir5.Bundle<fhir5.Appointment> | undefined;
  loading: boolean;
  setTabRefetch: Dispatch<SetStateAction<boolean>>;
  patients: fhir5.BundleEntry<fhir5.Patient>[] | undefined;
}

export function NextAppointments({
  data,
  loading,
  setTabRefetch,
  patients,
}: IProps) {
  const { availableRoles } = useViewContext();
  const { formatDateTimeToHour } = useFormatter;

  if (loading) {
    return <DataSkeleton />;
  }

  const columns = [
    { column: "Data", rowName: "date", width: "100px" },
    { column: "Hora", rowName: "hour", width: "75px" },
    ...((availableRoles.includes("manager") && [
      {
        column: "Profissional",
        rowName: "practitioner",
      },
    ]) ||
      []),
    { column: "Paciente", rowName: "patient" },
    { column: "Telefone", rowName: "contact" },
    { column: "Status", rowName: "status" },
  ];

  const rows = data?.entry?.map((el) => {
    const practitioner = useFhirData.findParticipant(
      el?.resource?.participant,
      "Practitioner"
    );
    const patient = patients?.find((patient: any) => {
      const auxId = patient?.resource?.id;
      const auxReference = useFhirData.findParticipant(
        el?.resource?.participant,
        "Patient"
      )?.reference;

      return auxReference.includes(auxId);
    });

    const contact = patient?.resource?.telecom;

    const phone = contact?.find((e: any) => e.system === "phone")?.value;

    const hour =
      el?.resource?.start && formatDateTimeToHour(el?.resource?.start);
    const date = dayjs(el?.resource?.start)?.format("DD/MM/YYYY ") || "-";

    const status = el?.resource?.status || "-";
    const { formatNameDisplay } = useFormatter;

    return {
      date,
      phone,
      hour,
      ...(availableRoles.includes("manager") && {
        practitioner: practitioner?.display,
      }),
      practitionerReference: practitioner?.reference,
      appointment: el.resource,
      patient: formatNameDisplay(patient?.resource?.name?.[0]?.text || ""),
      patientId: patient?.resource?.id,
      status,
      ...(phone && {
        contact: (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            alignContent="center"
          >
            <Typography mt={0.5}>
              {useFormatter.formatPhoneNumber(phone || "")}
            </Typography>
            <Tooltip title={useFormatter.formatPhoneNumber(phone || "")}>
              <IconButton
                color="primary"
                onClick={() =>
                  window.open(
                    `https://web.whatsapp.com/send?phone=+${useInternationalVerify.verifyPhoneDDI(
                      phone
                    )}`,
                    "_blank"
                  )
                }
              >
                <WhatsApp />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      }),
      reference: {
        participant: patient?.resource?.id,
        appointmentId: el?.resource?.id,
      },
      auxCancelAppointment: el?.resource,
      setTabRefetch,
    };
  });

  return (
    <GenericTable
      tableColumns={columns}
      tableRows={rows}
      actions={(rowData: any) => <NextAppointmentsActions row={rowData} />}
    />
  );
}
