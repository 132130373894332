import { DocumentReference } from "fhir/r5";
import { useFhirData } from "../utils/useFhirData";
import { BundlePatientSummary } from "./fhir";
import { GetBinary } from "./fhir/patientsummary/Binary/GetBinary";
import JSZip from "jszip";

export default async function backupDataUser({
  userIdentifier,
  setLoading,
  setProgress,
  setStartedProgress,
  setFinishedProgress,
  setZipData,
}: {
  userIdentifier: any;
  setLoading?: Function;
  setProgress?: Function;
  setStartedProgress?: Function;
  setFinishedProgress?: Function;
  setZipData?: Function;
}) {
  try {
    const bundleSummaryResponse = await BundlePatientSummary({
      identifier: userIdentifier,
    });

    const medicalNoteData = useFhirData.extractPatientSummaryResource(
      bundleSummaryResponse,
      "DocumentReference",
      "prescricao"
    )?.[0]?.entry;

    const solicitationData = useFhirData.extractPatientSummaryResource(
      bundleSummaryResponse,
      "DocumentReference",
      "service-request"
    )?.[0]?.entry;

    const solicitationResultData = useFhirData.extractPatientSummaryResource(
      bundleSummaryResponse,
      "DocumentReference",
      "resultado"
    )?.[0]?.entry;

    const noteReportData = useFhirData.extractPatientSummaryResource(
      bundleSummaryResponse,
      "DocumentReference",
      "atestado"
    )?.[0]?.entry;

    const proceduresData = useFhirData.extractPatientSummaryResource(
      bundleSummaryResponse,
      "DocumentReference",
      "service-request"
    );

    const verifyLength =
      medicalNoteData?.length +
      solicitationData?.length +
      solicitationResultData?.length +
      noteReportData?.length +
      proceduresData?.[0]?.entry?.length;

    if (!verifyLength) {
      setProgress?.(100);
      setZipData?.(undefined);
      setStartedProgress?.(true);
      setLoading?.(false);
      return;
    }

    const documentReferenceResults = solicitationResultData?.filter(
      (results: any) => {
        return (
          results?.resource?.resourceType === "DocumentReference" &&
          results?.resource?.category?.[0].coding?.[0].display ===
            "Resultado de exame"
        );
      }
    );

    const documentReferenceProcedures = proceduresData?.[0]?.entry?.filter(
      (el: any) => {
        return (
          el?.resource?.resourceType === "DocumentReference" &&
          el?.resource?.category?.[0].coding?.[0].display === "Procedimento"
        );
      }
    );

    const documentReferenceMedicalNote = medicalNoteData
      ?.filter((el: any) => el?.resource?.resourceType === "DocumentReference")
      ?.filter((e: any) => {
        return (
          e?.resource?.securityLabel?.[0]?.coding?.[0]?.code === "ProofOfOrigin"
        );
      });

    const documentReferenceSolicitation = solicitationData
      ?.filter((el: any) => el?.resource?.resourceType === "DocumentReference")
      ?.filter((e: any) => {
        return (
          e?.resource?.securityLabel?.[0]?.coding?.[0]?.code === "ProofOfOrigin"
        );
      });

    const documentReferenceNoteReport =
      noteReportData
        ?.filter((e: any) => {
          return (
            e?.resource?.securityLabel?.[0]?.coding?.[0]?.code ===
            "ProofOfOrigin"
          );
        })
        ?.map(
          (el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
            return {
              binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
            };
          }
        ) || [];

    const documentReferenceResultsData =
      documentReferenceResults
        ?.filter(
          (filterEl: any) =>
            filterEl?.resource?.category?.[0]?.coding?.[0]?.display ===
            "Resultado de exame"
        )
        ?.map(
          (el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
            return {
              binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
            };
          }
        ) || [];

    const documentReferenceProceduresData =
      proceduresData?.[0]?.entry
        ?.filter(
          (valueFilter: any) =>
            valueFilter?.resource?.category?.[0]?.text === "procedure"
        )
        ?.map((el: any) => {
          const docReference = documentReferenceProcedures?.find(
            (doc: fhir5.BundleEntry<DocumentReference | any>) => {
              const findRef = doc?.resource?.basedOn?.[0]?.reference?.includes(
                el?.resource?.id
              );

              return findRef;
            }
          );

          const binaryUrl =
            docReference?.resource?.content?.[0]?.attachment?.url || "";

          return {
            binaryUrl,
          };
        }) || [];

    const documentReferenceSolicitationData =
      documentReferenceSolicitation
        ?.filter(
          (filterEl: any) =>
            filterEl?.resource?.category?.[0]?.coding?.[0]?.display ===
            "Solicitação"
        )
        ?.map(
          (el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
            return {
              binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
            };
          }
        ) || [];

    const documentReferenceMedicalNoteData =
      documentReferenceMedicalNote?.map(
        (el: fhir5.BundleEntry<DocumentReference | any>, index: number) => {
          return {
            binaryUrl: el?.resource?.content?.[0]?.attachment?.url || "",
          };
        }
      ) || [];

    const base64ToBlob = (base64: string, contentType = ""): Blob => {
      const byteCharacters = atob(base64);
      const byteArrays: Uint8Array[] = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = Array.from(slice, (char) => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    };

    const processDocuments = async (
      documents: any,
      folder: any,
      prefix: string
    ) => {
      for (let index = 0; index < documents.length; index++) {
        const documentReference = documents[index];
        try {
          const response = await GetBinary({
            urlBinary: documentReference?.binaryUrl,
          });

          if (response?.data && response?.contentType) {
            const blob = base64ToBlob(response.data, response.contentType);
            const fileName = `${prefix}${index + 1}.pdf`;
            folder?.file(fileName, blob);
          } else {
            console.log("Dados inválidos para o documento:", documentReference);
          }
        } catch (err) {
          console.log("Erro ao processar documento:", err);
        }
      }
    };

    (async () => {
      setStartedProgress?.(true);

      const zip = new JSZip();
      const medicalNoteFolder = zip.folder("receitas");
      const solicitationFolder = zip.folder("pedidos de exames");
      const resultsFolder = zip.folder("resultados de exames");
      const noteReportFolder = zip.folder("relatórios");
      const proceduresFolder = zip.folder("procedimentos");

      const updateProgress = (percent: number) => {
        setProgress?.(Math.round(percent * (1 - 0.75)) + 75);
      };

      if (!documentReferenceMedicalNoteData?.length) {
        console.log("Nenhuma nota médica para processar.");
      } else {
        await processDocuments(
          documentReferenceMedicalNoteData,
          medicalNoteFolder,
          "Receita"
        );

        setProgress?.(20);
      }

      if (!documentReferenceSolicitationData?.length) {
        console.log("Nenhuma solicitação para processar.");
      } else {
        await processDocuments(
          documentReferenceSolicitationData,
          solicitationFolder,
          "Exame"
        );
        setProgress?.(30);
      }

      if (!documentReferenceNoteReport?.length) {
        console.log("Nenhuma nota de relatório para processar.");
      } else {
        await processDocuments(
          documentReferenceNoteReport,
          noteReportFolder,
          "Relatório"
        );
        setProgress?.(45);
      }

      if (!documentReferenceProceduresData?.length) {
        console.log("Nenhuma procedimento para processar.");
      } else {
        await processDocuments(
          documentReferenceProceduresData,
          proceduresFolder,
          "Procedimento"
        );
        setProgress?.(65);
      }

      if (!documentReferenceResultsData?.length) {
        console.log("Nenhum resultado de exame para processar.");
      } else {
        await processDocuments(
          documentReferenceResultsData,
          resultsFolder,
          "Resultado de Exame"
        );

        setProgress?.(75);
      }

      const zipBlob = await zip.generateAsync({ type: "blob" }, (metadata) => {
        updateProgress(metadata.percent);
      });

      setZipData?.(zipBlob);
    })();
  } catch (err) {
    console.log("PatientSummary error:", err);
  } finally {
    setFinishedProgress?.(true);
    setLoading?.(false);
  }
}
