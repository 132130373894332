import React, { useState } from "react";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { useForm } from "react-hook-form";
import { FormButtonGroup } from "../../../components/form";
import { InternationalPhone } from "../../../components/common/InternationalPhone";
import { DialogTitle, Stack } from "@mui/material";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { PutOrganization } from "../../../services/fhir";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface IformInput {
  phoneNumber: string;
}

interface IProps {
  isOpen: boolean;
  handleClose: Function;
  phoneNumber: any;
}

export function EditPhoneNumberDialog({
  isOpen,
  handleClose,
  phoneNumber,
}: IProps) {
  const navigate = useNavigate();
  const { organization, refetchOrganization } = useOrganizationContext();
  const [loading, setLoading] = useState<boolean>(false);
  const defaultValues: IformInput = {
    phoneNumber: phoneNumber,
  };
  const methods = useForm<IformInput>({ defaultValues });
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = methods;

  const onSubmit = async (data: any) => {
    const auxPutOrg = {
      ...organization,
      contact: [
        {
          ...organization?.contact?.[0],
          telecom: [
            {
              system: "phone",
              value: data?.phoneNumber,
            },
          ],
        },
      ],
    };

    try {
      setLoading(true);
      await PutOrganization(auxPutOrg);
    } catch (err) {
      console.log("err", err);
    }
    toast.success("Telefone adicionado com sucesso!");
    refetchOrganization();
    handleClose();
    navigate("/clinic");
    setLoading(false);
  };
  return (
    <CommonDialog
      handleClose={() => {
        handleClose();
        reset();
      }}
      isOpen={isOpen}
    >
      <DialogTitle textAlign="center">Telefone da clínica</DialogTitle>
      <Stack spacing={2}>
        <InternationalPhone
          control={control}
          label="Telefone da clínica"
          name="phoneNumber"
          required={true}
          defaultValue={phoneNumber}
        />
        <FormButtonGroup
          disabled={!isDirty}
          loading={loading}
          onGoBackButtonClick={() => {
            handleClose();
            reset();
          }}
          onNextButtonClick={handleSubmit(onSubmit)}
          goBackButtonText="Cancelar"
          nextButtonText="Salvar"
        />
      </Stack>
    </CommonDialog>
  );
}
