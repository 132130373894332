import { useEffect, useState } from "react";
import { Box, Stack, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import dayjs from "dayjs";

import {
  CircularLoading,
  LargeContentBox,
  PageTitle,
} from "../../components/common";
import { PrivateMainLayout } from "../../components/layout";
import { GenericTable } from "../../components/GenericTable/GenericTable";
import { GetAccessHistory } from "../../services/consent";
import { useSessionContext } from "../../contexts/SessionContext";
import { isMobile } from "react-device-detect";
import { BundlePatientSummary } from "../../services/fhir";
import { useDict } from "../../utils/useDict";
import { AccessHistoryActions } from "./AccessHistoryActions";

export function AccessHistory() {
  const { user } = useSessionContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const [data, setData] = useState([]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const generalColumns = [
    {
      column: "CPF",
      rowName: "identifier",
    },
    { column: "Nome", rowName: "name" },
    { column: "Organização", rowName: "organization" },
    { column: "Motivo", rowName: "purposeOfUse" },
    { column: "Data", rowName: "date" },
    { column: "Hora", rowName: "hour" },
  ];

  const myAccessColumns = [
    {
      column: "CPF",
      rowName: "identifier",
    },
    { column: "Nome", rowName: "name" },
    // { column: "Organização", rowName: "organization" },
    // { column: "Motivo", rowName: "purposeOfUse" },
    { column: "Data", rowName: "date" },
    { column: "Hora", rowName: "hour" },
  ];

  const docsColumns = [
    {
      column: "Documento clínico",
      rowName: "docType",
    },

    { column: "Organização", rowName: "organization" },

    { column: "Data", rowName: "date" },
    { column: "Hora", rowName: "hour" },
  ];

  useEffect(() => {
    function displayPurposeOfUse(
      purpose: string | undefined,
      instituicao: boolean | undefined,
      identifier: string | undefined
    ): string {
      switch (purpose) {
        case "PATRQT":
          return "Consentimento individual";
        case "PWATRNY":
          return "Responsável legal";
        case "Emergencia":
          return "Emergência";
        case "Atendimento":
          return instituicao
            ? "Atendimento"
            : identifier === user.username
            ? "Acesso pessoal"
            : "Consentimento individual";
        case "Federacao":
          return "Atendimento na rede de saúde";
        default:
          return purpose || "-";
      }
    }

    async function fecthData() {
      setLoading(true);

      const documentReference = await BundlePatientSummary({
        identifier: user.username,
        entries: ["DocumentReference"],
      });

      const documentResource = documentReference.entry
        ?.flatMap((el: any) => el.resource.entry)
        ?.filter(
          (item: any) =>
            item !== undefined &&
            item.resource.resourceType.includes("DocumentReference")
        );

      const response = await GetAccessHistory(user.username);
      if (response?.data) {
        const rows = response?.data?.map((item: any) => {
          const auxDoc = documentResource?.find(
            (doc: any) => doc?.resource?.id === item?.sujeito?.id
          );
          return {
            identifier: item.sujeito.id || "-",
            name: item.sujeito.nome || "-",
            purposeOfUse: displayPurposeOfUse(
              item.contexto.motivoAcesso,
              item.contexto.instituicao,
              item.sujeito.id
            ),
            docType:
              useDict.signedDocsType[
                auxDoc?.resource?.category?.[0]?.coding?.[0]?.display
              ] || "",
            binaryUrl: auxDoc?.resource?.content?.[0]?.attachment?.url || "",

            date: item.hora && dayjs(item.hora)?.format("DD/MM/YYYY"),
            hour: item.hora && dayjs(item.hora)?.format("HH:mm"),
            organization: item.contexto.instituicao || "-",
          };
        });
        setData(rows);
      }

      setLoading(false);
    }
    fecthData();
  }, [user.username]);

  return (
    <PrivateMainLayout
      noSidebar={isMobile}
      mobileView={isMobile}
      noPadding={isMobile}
    >
      <LargeContentBox>
        <Stack width="100%">
          <PageTitle>Quem acessou meus registros de saúde?</PageTitle>

          {loading ? (
            <CircularLoading />
          ) : (
            <TabContext value={selectedTab}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TabList onChange={handleChangeTab}>
                  <Tab
                    style={{ display: "flex", alignItems: "center" }}
                    label=" Histórico de acessos"
                    value="1"
                  />

                  <Tab
                    style={{ display: "flex", alignItems: "center" }}
                    label="Meus acessos"
                    value="2"
                  />
                  <Tab
                    style={{ display: "flex", alignItems: "center" }}
                    label="Documentos visualizados"
                    value="3"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <GenericTable
                  tableColumns={generalColumns}
                  tableRows={data?.filter(
                    (el: any) =>
                      el.identifier !== user?.username &&
                      !el?.identifier.includes("-")
                  )}
                />
              </TabPanel>
              <TabPanel value="2">
                <GenericTable
                  tableColumns={myAccessColumns}
                  tableRows={data?.filter(
                    (el: any) => el.identifier === user?.username
                  )}
                />
              </TabPanel>
              <TabPanel value="3">
                <GenericTable
                  tableColumns={docsColumns}
                  tableRows={data?.filter(
                    (el: any) =>
                      el.identifier !== user?.username &&
                      el?.identifier.includes("-")
                  )}
                  actions={(rowData: any) => (
                    <AccessHistoryActions row={rowData} />
                  )}
                />
              </TabPanel>
            </TabContext>
          )}
        </Stack>
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
