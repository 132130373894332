import { Stack, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  FormTextInput,
  FormStack,
  FormButtonGroup,
} from "../../../components/form";
import { ContentBoxTitle } from "../../../components/common";
import { IFormInput } from "../types";
import { ProfileSearch, SearchCPF } from "../../../services/scim";
import { GetPatient, GetPractitioner } from "../../../services/fhir";
import dayjs from "dayjs";
import { HTTPResponseStatus } from "../../../utils/enum";
import { toast } from "react-toastify";
import { useState } from "react";
import { useRegex } from "../../../utils/useRegex";
import { useFormatter } from "../../../utils/useFormatter";
import { HelpOutline } from "@mui/icons-material";
import { InternationalPhone } from "../../../components/common/InternationalPhone";

interface IProps {
  control: any;
  reset: any;
  handleSubmit: Function;
  onNextButtonClick: Function;
  setValue: any;
}

export default function PersonalData({
  control,
  reset,
  handleSubmit,
  onNextButtonClick,
  setValue,
}: IProps) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");

  async function ValidateUser(data: any) {
    try {
      const searchPractitioner =
        (await GetPractitioner(
          `${data?.identifier.replace(/[.-]/g, "")}`,
          true
        )) ||
        (
          await GetPatient({
            authType: "application",
            identifier: data?.identifier.replace(/[.-]/g, ""),
          })
        )?.data;

      toast.success("Informações validadas com sucesso!");
      return {
        name: searchPractitioner.name?.[0]?.text,
        birthDate: searchPractitioner?.birthDate,
      };
    } catch (err) {
      const validationSerpro = await SearchCPF(
        data?.identifier.replace(/[.-]/g, ""),
        "application"
      );
      if (validationSerpro?.status === HTTPResponseStatus.UnprocessableEntity) {
        toast.warn("Usuário menor de idade!");
        return false;
      }
      if (validationSerpro?.status === HTTPResponseStatus.Conflict) {
        toast.warn(validationSerpro?.data);
        navigate("/signin");
        return false;
      }
      if (validationSerpro?.status !== HTTPResponseStatus.Success) {
        toast.warn(
          "Não foi possível validar as informaçẽos, por favor verifique os dados e tente novamente!"
        );
        return false;
      }
      toast.success("Informações validadas com sucesso!");
      return {
        name: validationSerpro?.data?.nome,
        birthDate: dayjs(validationSerpro?.data.nascimento, "DDMMYYYY"),
      };
    }
  }

  const onSubmit = async (data: IFormInput) => {
    console.log("data: ", data);
    console.log("phone: ", phone);
    setLoading(true);
    try {
      const Scim2UserResponse = await ProfileSearch(
        "application",
        data.identifier?.replace(/[.-]/g, "")
      ).then((response) => response?.Resources);

      if (Scim2UserResponse) {
        toast.warn("Usuário já cadastrado na plataforma");
        navigate("/");
        return;
      }

      const userData = await ValidateUser(data);

      if (userData) {
        setValue("userData", userData);
        setValue("surname", userData?.name?.split(" ")?.pop());
        setValue(
          "firstName",
          userData?.name.split(" ")?.slice(0, -1)?.join(" ")
        );
        setValue("birthdate", new Date(userData?.birthDate));
        onNextButtonClick();
      } else {
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      // onKeyDown={(event) => handleKeyPress(event, false, onNextButtonClick)}
      style={{ width: "100%" }}
    >
      <FormStack>
        <ContentBoxTitle>Criar uma conta</ContentBoxTitle>
        <Typography
          variant="body1"
          sx={{ color: "neutral600.main", mt: "-10px" }}
        >
          Para começar a sua jornada na plataforma, por favor, preencha os dados
          abaixo.
        </Typography>
        <Stack width="100%" direction="column" spacing={2}>
          <Stack
            sx={{
              display: "grid",
              gridTemplateColumns: "100% 20px",
            }}
            width="100%"
            gap={2}
          >
            <FormTextInput
              label="CPF"
              name="identifier"
              control={control}
              required={true}
              pattern={useRegex.cpf}
              mask={useFormatter.formatCPF}
              maxLength={14}
              autoFocus
            />
            <Tooltip
              title="CPF será validado na base da Receita Federal"
              placement="right"
            >
              <HelpOutline
                fontSize="small"
                id="cpfSupport"
                sx={{ marginTop: 2, color: "neutral600.main" }}
              />
            </Tooltip>
          </Stack>

          <InternationalPhone
            name="phoneNumber"
            control={control}
            label="Número de celular"
            required={true}
          />

          <FormTextInput
            type="email"
            name="email"
            control={control}
            label="E-mail"
            mask={useFormatter.removeSpaces}
            required={true}
            pattern={useRegex.email}
          />
        </Stack>

        <FormButtonGroup
          loading={loading}
          onNextButtonClick={handleSubmit(onSubmit)}
          onGoBackButtonClick={() => navigate("/signin")}
        />
      </FormStack>
    </form>
  );
}
