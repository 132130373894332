import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Add, Close, Edit } from "@mui/icons-material";

import { useState } from "react";

import { useForm, useWatch } from "react-hook-form";

import { InativateConfirmDialog } from "./InativateConfirmDialog";
import { PatchLocation } from "../../../services/fhir/location/PatchLocation";
import { useLocationContext } from "../../../contexts/LocationContext";
import { toast } from "react-toastify";

interface IProps {
  row: any;
  setEditData?: any;
  setOpenModalEdit?: any;
}

export function ManageLocationActions({
  row,
  setEditData,
  setOpenModalEdit,
}: IProps) {
  const auxData = row?.address?.line;

  const { refetchLocation } = useLocationContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <TableCell align="center">
        {row?.fullResource?.status !== "inactive" && (
          <Tooltip title="Editar localização">
            <IconButton
              onClick={() => {
                setEditData(row);
                setOpenModalEdit(true);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title={row?.status === "active" ? "Desativar" : "Ativar"}>
          <IconButton
            onClick={async () => {
              if (row?.status === "active") {
                setIsOpen(true);
              } else {
                try {
                  await PatchLocation({
                    id: row?.id,
                    operation: "replace",
                    path: "/status",
                    content: "active",
                  });
                  refetchLocation();
                  toast.success("Localização ativada com sucesso!");
                } catch (err) {
                  toast.warn(
                    "Não foi possível realizar a operação, por favor tente novamente!"
                  );
                  console.log("err", err);
                }
              }
            }}
          >
            {row?.status === "active" ? (
              <Close sx={{ color: "red" }} />
            ) : (
              <Add />
            )}
          </IconButton>
        </Tooltip>
      </TableCell>

      {isOpen && (
        <InativateConfirmDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          locationId={row?.id}
        />
      )}
    </>
  );
}
