import {
  CircularProgress,
  IconButton,
  Link,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { PrimaryButton } from "../../components/common";
import { useAuthContext } from "../../contexts/AuthContext";
import { useSessionContext } from "../../contexts/SessionContext";
import { useRef, useState } from "react";
import { FormButtonGroup } from "../../components/form";
import { ArrowBack } from "@mui/icons-material";

import { CancelPlanRef } from "./components/CancelPlan";
import BackupNotice, { InfoMessage } from "./components/BackupNotice";
import RevokeConsents from "../consents/components/RevokeConsentIndividual/RevokeConsents";
import { useNavigate } from "react-router-dom";
import { SendEmailFinishAccount } from "../../services/brevo/SendEmailFinishAccount";
import {
  ChangePractitionerRoleActive,
  GetAppointment,
  GetSchedule,
  PatchAppointmentStatus,
  PatchSchedule,
} from "../../services/fhir";
import backupDataUser from "../../services/backupDataUser";
import NoticeOfTerms from "./components/NoticeOfTerms";
import DialogAccountFinish from "./components/DialogAccountFinish";
import CircularProgressWithLabel from "./components/CircularProgressWithLabel";
import { saveAs } from "file-saver";
import { usePractitionerRoleContext } from "../../contexts/PractitionerRoleContext";
import { DeleteUser } from "../../services/scim/profile/DeleteUser";
import { PractitionerCancel } from "./components/PractitionerCancel";
import { OwnerCancel } from "./components/OwnerCancel";
import { toast } from "react-toastify";
import { oids } from "../../configs/Settings";
import dayjs from "dayjs";
import { GetContract } from "../../services/fhir/contract/GetContract";
import { PutContract } from "../../services/fhir/contract/PutContract";

export default function Term({ termOf }: { termOf?: string }) {
  const { logoutUser } = useAuthContext();
  const {
    isOpenTermDialog,
    setIsOpenTermDialog,
    roles,
    user,
    setTerminateAccount,
    terminateAccount,
    person,
  } = useSessionContext();
  const { rolesOrganization, practitionerRole } = usePractitionerRoleContext();

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [startedProgressBackup, setStartedProgressBackup] =
    useState<boolean>(false);
  const cancelPlanRef = useRef<CancelPlanRef | null>(null);

  const isManagerOrOwner =
    roles?.some((role: any) => role.isOwner || role.isManager) || false;

  const responsavelLegal = rolesOrganization
    ?.map((e: any) => e?.resource)
    ?.filter((el: any) => {
      const auxExt =
        el?.extension
          ?.find((ext: any) =>
            ext?.url?.includes("StructureDefinition/OrganizationManager")
          )
          ?.extension?.find((i: any) => i?.url === "id")?.valueIdentifier
          ?.value === user?.username;

      return auxExt;
    });

  const notResponsavelLegal = rolesOrganization
    ?.map((e: any) => e?.resource)
    ?.filter((el: any) => {
      const auxExt =
        el?.extension
          ?.find((ext: any) =>
            ext?.url?.includes("StructureDefinition/OrganizationManager")
          )
          ?.extension?.find((i: any) => i?.url === "id")?.valueIdentifier
          ?.value !== user?.username;

      return auxExt;
    });

  const [zipBlobData, setZipBlobData] = useState<Blob | any>();
  const navigate = useNavigate();

  const steps = (() => {
    if (!responsavelLegal?.length) {
      return ["Revogar consentimentos", "Confirmação", "Concluir"];
    } else {
      return [
        "Revogar consentimentos",
        "Confirmação",
        "Cancelar assinatura",
        "Concluir",
      ];
    }
  })();

  const supportLink = isManagerOrOwner
    ? "https://projectcore.atlassian.net/servicedesk/customer/portal/11"
    : "https://projectcore.atlassian.net/servicedesk/customer/portal/12";

  const firstStep = 0;

  const handleDeletePractitionerRole = async (organization?: any) => {
    const idSchedules = await GetSchedule({
      practitioner: `Practitioner/${oids.cpf}-${user.username}`,
      active: true,
    });

    const idAppointment = await GetAppointment({
      professionalActor: `Practitioner/${oids.cpf}-${user.username}`,
      status: ["booked"],
      ...(organization && { organization: organization }),
      startDate: dayjs()?.format("YYYY-MM-DD"),
    });

    const patchOperation = [
      {
        op: "replace",
        path: "/active",
        value: "false",
      },
    ];

    if (idSchedules?.entry)
      await Promise.all(
        idSchedules?.entry?.map((e: any) =>
          PatchSchedule(e.resource?.id, patchOperation)
        )
      );

    if (idAppointment?.entry)
      await Promise.all(
        idAppointment?.entry?.map((e: any) =>
          PatchAppointmentStatus({
            appointmentId: e?.resource.id,
            status: "cancelled",
          })
        )
      );

    practitionerRole
      ?.filter((el: fhir5.BundleEntry<fhir5.PractitionerRole>) => {
        const verifyUser = el?.resource?.id?.includes(user.username);
        const verifyOrganization = organization
          ? el?.resource?.organization?.reference?.includes(organization)
          : true;
        return verifyUser && verifyOrganization;
      })
      ?.map(
        async (e: any) => await ChangePractitionerRoleActive(e?.resource, false)
      );
  };

  const handleCancelPlan = async ({ organizationId }: any) => {
    try {
      const idSchedules = await GetSchedule({
        organization: organizationId,
        active: true,
      });

      const patchOperation = [
        {
          op: "replace",
          path: "/active",
          value: "false",
        },
      ];

      if (idSchedules?.entry) {
        await Promise.all(
          idSchedules?.entry?.map((e: any) =>
            PatchSchedule(e.resource?.id, patchOperation)
          )
        );
      }

      const contractOrganization = await GetContract({
        organization: organizationId,
        count: 1,
        sort: true,
        status: "executed",
      })?.then((response: any) => response?.entry?.[0]?.resource);

      const replaceStatusResource: any = {
        ...contractOrganization,
        status: "cancelled",
        term: contractOrganization?.term?.map((t: any) => ({
          ...t,
          applies: {
            ...t.applies,
            end: `${dayjs().toISOString()}`,
          },
        })),
      };

      if (contractOrganization)
        await PutContract({
          contractId: `${contractOrganization?.id}`,
          resource: replaceStatusResource,
        });
    } catch (err) {
      console.log("err", err);
    }
    // setRefetchContract((prev: boolean) => !prev);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const shouldShowBackButton = (
    activeStep: number,
    startedProgressBackup: boolean,
    loading: boolean
  ) => activeStep !== firstStep && !startedProgressBackup && !loading;

  const shouldShowFormButtons = (
    startedProgressBackup: boolean,
    loading: boolean
  ) => !startedProgressBackup && !loading;

  const StepContent = ({
    activeStep,
    progress,
    loading,
    startedProgressBackup,
  }: {
    activeStep: number;
    isManagerOrOwner?: boolean;
    cancelPlanRef: any;
    progress?: number;
    loading?: boolean;
    startedProgressBackup?: boolean;
  }) => {
    switch (activeStep) {
      case 0:
        return <RevokeConsents />;
      case 1:
        return !roles?.length ? (
          <BackupNotice
            progress={progress}
            loading={loading}
            startedProgress={startedProgressBackup}
          />
        ) : (
          <PractitionerCancel isFiscalReponsive={responsavelLegal?.length} />
        );
      case 2:
        return <OwnerCancel />;
      case 3:
        return (
          <>
            {responsavelLegal?.length ? (
              <>
                <InfoMessage
                  message={
                    "Em até 30 dias você receberá as intruções para realizar a extração dos dados da plataforma"
                  }
                />

                <FormButtonGroup
                  onGoBackButtonClick={() => {
                    !responsavelLegal?.length ? setActiveStep(1) : handleBack();
                  }}
                  onNextButtonClick={async () => {
                    notResponsavelLegal?.map(async (el: any) => {
                      await handleDeletePractitionerRole({
                        organizationId: el?.id?.split("-")?.[1],
                      });
                    });

                    responsavelLegal?.map(async (el: any) => {
                      await handleCancelPlan({ organizationId: el?.id });
                    });

                    await SendEmailFinishAccount(
                      { email: user?.email?.[0], name: user?.name },
                      "Solicitação de extração de dados para encerramento realizada",
                      user.username
                    );
                    navigate("/");
                  }}
                  goBackButtonText="Voltar"
                  nextButtonText="Solicitar extração de dados e concluir!"
                  loading={loading}
                />
              </>
            ) : (
              <>
                <Stack spacing={2} alignItems="center">
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    sx={{ color: "neutral700.main" }}
                  >
                    Buscando seus registros de paciente da plataforma.
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    Não feche o navegador até concluir o processo completo para
                    desativar a conta.
                  </Typography>
                </Stack>
                {loading && !startedProgressBackup && (
                  <CircularProgress size="132px" />
                )}
                {startedProgressBackup && (
                  <CircularProgressWithLabel
                    size="132px"
                    value={progress || 0}
                  />
                )}

                {!loading && zipBlobData === undefined && progress === 100 && (
                  <Stack>
                    <InfoMessage message="Você não possui registros para extração." />
                  </Stack>
                )}

                {progress === 100 && (
                  <PrimaryButton
                    width="100%"
                    height="42px"
                    onClick={async () => {
                      try {
                        if (zipBlobData !== undefined)
                          saveAs(zipBlobData, `${user?.name}.zip`);

                        if (!responsavelLegal?.length) {
                          if (roles?.length)
                            await handleDeletePractitionerRole();
                          await DeleteUser(user?.id);
                          toast.success(
                            "O processo de encerramento de conta foi finalizado com sucesso!"
                          );
                          await SendEmailFinishAccount(
                            { email: user?.email?.[0], name: user?.name },
                            "Usuário finalizando conta fluxmed",
                            user.username
                          );
                          logoutUser();
                        }
                      } catch (err) {
                        console.log("Delete err", err);
                      }
                    }}
                  >
                    {zipBlobData
                      ? "Baixar dados e encerrar conta"
                      : "Encerrar conta"}
                  </PrimaryButton>
                )}
              </>
            )}
          </>
        );
      default:
        return null;
    }
  };

  const BackButton = ({
    onClick,
    disabled,
  }: {
    onClick: () => void;
    disabled: boolean;
  }) => (
    <IconButton
      onClick={onClick}
      sx={{
        position: "relative",
        left: { xs: 0, lg: "-50%" },
        top: { xs: 2, lg: "12.6%" },
      }}
      size="large"
      disabled={disabled}
      title="Voltar"
    >
      <ArrowBack />
    </IconButton>
  );

  const StepHeader = ({
    activeStep,
    steps,
  }: {
    activeStep: number;
    steps: string[];
  }) => (
    <Stepper activeStep={activeStep} sx={{ width: { xs: "100%", lg: "90%" } }}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
  const StepControlButton = () => {
    if (activeStep === 0)
      return (
        <FormButtonGroup
          onGoBackButtonClick={() => {
            setActiveStep(0);
            person?.active ? navigate("/profile") : setIsOpenTermDialog(false);
          }}
          onNextButtonClick={() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }}
          goBackButtonText="Voltar"
          nextButtonText="Avançar"
          loading={loading}
        />
      );
    if (activeStep === 1)
      return (
        <FormButtonGroup
          onGoBackButtonClick={handleBack}
          onNextButtonClick={async () => {
            if (!responsavelLegal?.length) {
              await backupDataUser({
                userIdentifier: user?.username,
                setProgress,
                setLoading,
                setStartedProgress: setStartedProgressBackup,
                setZipData: setZipBlobData,
              });

              setActiveStep(3);
            } else {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          }}
          goBackButtonText="Voltar"
          nextButtonText="Confirmar"
          loading={loading}
        />
      );

    if (activeStep === 2)
      return (
        <FormButtonGroup
          onGoBackButtonClick={handleBack}
          onNextButtonClick={() =>
            setActiveStep((prevActiveStep) => prevActiveStep + 1)
          }
          goBackButtonText="Voltar"
          nextButtonText="Confirmar"
          loading={loading}
        />
      );
    else return <></>;
  };

  return (
    <Stack
      height={"100%"}
      width={{ xs: "100%", lg: "50%" }}
      alignItems={"center"}
      justifyContent={"center"}
      gap={{ xs: 2, lg: 8 }}
    >
      {terminateAccount ? (
        <>
          {shouldShowBackButton(activeStep, startedProgressBackup, loading) && (
            <BackButton onClick={handleBack} disabled={activeStep === 0} />
          )}
          <StepHeader activeStep={activeStep} steps={steps} />

          <StepContent
            activeStep={activeStep}
            isManagerOrOwner={isManagerOrOwner}
            cancelPlanRef={cancelPlanRef}
            progress={progress}
            loading={loading}
            startedProgressBackup={startedProgressBackup}
          />

          {shouldShowFormButtons(startedProgressBackup, loading) && (
            <StepControlButton />
          )}
        </>
      ) : (
        <NoticeOfTerms
          supportLink={supportLink}
          setTerminateAccount={setTerminateAccount}
          isManagerOrOwner={isManagerOrOwner}
          setIsOpenTermDialog={setIsOpenTermDialog}
          isOpenTermDialog={isOpenTermDialog}
        />
      )}
      <DialogAccountFinish open={false} />
    </Stack>
  );
}
