import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { FormButtonGroup, FormTextInput } from "../../../components/form";
import { useForm, useWatch } from "react-hook-form";
import { CommonDialog } from "../../../components/common/CommonDialog";
import { useRegex } from "../../../utils/useRegex";
import { HTTPResponseStatus } from "../../../utils/enum";
import { toast } from "react-toastify";
import { EditOrganization } from "../../../services/fhir";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";

interface IformInput {
  cnes: string;
}

interface IProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  cnesValue: string;
  organization: any;
}

export function EditCnesDialog({
  isOpen,
  setIsOpen,
  cnesValue,
  organization,
}: IProps) {
  const defaultValues: IformInput = {
    cnes: cnesValue,
  };
  const { refetchOrganization } = useOrganizationContext();
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<IformInput>({ defaultValues });
  const {
    handleSubmit,
    control,
    formState: { isDirty, isValid },
    reset,
  } = methods;

  const fieldCnes = useWatch({ control, name: "cnes" });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await EditOrganization(organization, "cnes", data.cnes);
      if (response?.status === HTTPResponseStatus.Success) {
        refetchOrganization();
        toast.success("Alteração realizada com sucesso");
        setIsOpen(false);
        return;
      }
      toast.error(
        "Houve um erro na atualização do cnes, tente novamente mais tarde"
      );
    } catch (error) {
      toast.error(
        "Houve um erro na atualização do cnes, tente novamente mais tarde"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen]);

  return (
    <CommonDialog handleClose={handleClose} isOpen={isOpen}>
      <DialogTitle textAlign="center">
        Editar número CNES
        <Typography variant="body1" color="neutral700.main">
          Você pode adicionar o CNES da sua clínica aqui.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack pt={1}>
          <FormTextInput
            name="cnes"
            label="CNES"
            pattern={useRegex.number}
            maxLength={7}
            control={control}
            autoFocus={true}
          />
          <Stack
            width="100%"
            mt={3}
            direction="row"
            justifyContent="space-between"
          >
            <FormButtonGroup
              onGoBackButtonClick={handleClose}
              onNextButtonClick={handleSubmit(onSubmit)}
              goBackButtonText="Cancelar"
              nextButtonText="Salvar"
              loading={loading}
              disabled={!isDirty || !isValid || !fieldCnes?.length}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
