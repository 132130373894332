import { DialogTitle, Stack, Typography } from "@mui/material";
import { CommonDialog } from "../../../../../components/common/CommonDialog";
import Warning from "@mui/icons-material/Warning";
import { FormButtonGroup } from "../../../../../components/form";
import { useMedicalCareContext } from "../../../../../contexts/MedicalCareContext";

interface IProps {
  isOpen: boolean;
  onClose: any;
  submit: any;
  loading: boolean;
}

export function WarningDocumentsDialog({
  isOpen,
  onClose,
  submit,
  loading,
}: IProps) {
  const { setActiveStep } = useMedicalCareContext();
  return (
    <CommonDialog isOpen={isOpen} handleClose={onClose}>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Warning color="warning" />
        <DialogTitle textAlign="center">Assinatura digital</DialogTitle>
      </Stack>

      <Typography textAlign="center" variant="body1" mb={3}>
        Existem documentos que não foram assinados digitalmente. Deseja
        prosseguir sem realizar a assinatura?
      </Typography>

      <FormButtonGroup
        onGoBackButtonClick={() => {
          setActiveStep(2);
          onClose();
        }}
        onNextButtonClick={submit}
        goBackButtonText="Assinar documentos"
        nextButtonText="Confirmar"
        disabled={loading}
      />
    </CommonDialog>
  );
}
