import { IconButton, Stack, Typography } from "@mui/material";
import { useInterventionsContext } from "../../../contexts/InterventionsContext";
import { InterventionsTags } from "./InterventionsTags";
import { Delete, Edit } from "@mui/icons-material";

interface IProps {
  el: any;
  index: number;
}

export function AuxInterventionsListItem({ el, index }: IProps) {
  const { selectedInterventions, functions } = useInterventionsContext();

  const auxIndex = selectedInterventions?.all.indexOf(el);
  return (
    <Stack direction="row" key={`${el?.name}-${index}`}>
      <Stack
        p={1}
        gap={1}
        sx={{
          background: "#F1F3F9",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <Stack direction="row" gap={1}>
          <Typography variant="body1" color="neutral1000.main" fontWeight={500}>
            {index + 1}. {el?.name}
          </Typography>
          {el?.tag && <InterventionsTags type={el.tag} />}

          <Typography variant="body1" color="neutral700.main">
            Código TUSS: {el?.inputValue?.code}
          </Typography>
        </Stack>

        <Stack direction="row" gap={1}>
          <Typography variant="body1" color="neutral700.main">
            {el?.condition?.label}{" "}
            {!el?.condition?.name &&
              el.condition?.value &&
              `- CID10 ${el.condition?.value}`}
          </Typography>
        </Stack>

        <Typography variant="body1" color="neutral700.main">
          {el?.category?.label}
        </Typography>
        {el?.note && (
          <Stack direction="row" gap={1}>
            <Typography
              color="neutral1000.main"
              fontWeight={400}
              sx={{
                maxWidth: "100%",
                whiteSpace: "normal",
                wordWrap: "break-word",
                overflowWrap: "break-word",
                wordBreak: "break-word",
              }}
            >
              Observação: {el?.note}
            </Typography>
          </Stack>
        )}
      </Stack>
      {!el?.sign && (
        <Stack direction="row" p={1} sx={{ alignItems: "center" }}>
          <IconButton sx={{ color: "black" }}>
            <Edit
              fontSize="small"
              onClick={() => functions.handleSelect(el, auxIndex)}
            />
          </IconButton>
          <IconButton
            sx={{ color: "black" }}
            onClick={() => functions.handleRemove(auxIndex)}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
}
