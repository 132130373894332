import {
  Alert,
  Badge,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { HeaderLogo, HeaderStack } from "../../../layoutHeader";
import { OptionsMenu } from "./options";
import { OrganizationSelect } from "../../../layoutHeader/OrganizationSelect";
import { isMobile } from "react-device-detect";
import { OptionsMenuMobile } from "./options/OptionsMenuMobile";
import { Notifications } from "../../../notification";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { SignatureControls } from "./SignatureControls";
import { useContractContext } from "../../../../contexts/ContractContext";
import dayjs from "dayjs";
import { Campaign, Handshake } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useWarningCentralContext } from "../../../../contexts/WarningCentralContext";

export function Header() {
  const { access, person, termRefused } = useSessionContext();
  const { currentContract } = useContractContext();
  const { handleOpen, warnings } = useWarningCentralContext();

  const dayContract = dayjs(currentContract?.[0]?.resource?.term?.[0]?.issued);

  const nextPayment = dayjs(
    currentContract?.[0]?.resource?.extension?.find((el: any) =>
      el?.url.includes("nextPaymentDueDate")
    )?.valueDate
  );

  return (
    <HeaderStack>
      <Stack direction="row" alignItems="center" spacing={10}>
        <HeaderLogo />
        {(person?.active || termRefused) && (
          <Stack direction="row" alignItems="center" spacing={4}>
            {access.type === "professional" && !currentContract && (
              <Link to="/clinic#signature" style={{ textDecoration: "none" }}>
                <Alert severity="info">
                  Para continuar usando todos os recursos, renove seu plano.
                </Alert>
              </Link>
            )}
            {access?.type === "professional" && <SignatureControls />}
            {access?.type === "professional" &&
              currentContract &&
              nextPayment.diff(dayContract, "day") <= 15 &&
              nextPayment.diff(dayjs(), "day") > 0 && (
                <Link to="/clinic#signature" style={{ textDecoration: "none" }}>
                  <Tooltip
                    title={`Após esse período será debitado em sua conta o valor do plano solicitado.`}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      border="1px solid"
                      borderColor="neutral0.main"
                      padding={1}
                      borderRadius={2}
                      spacing={1}
                      sx={{
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}
                    >
                      <Handshake sx={{ color: "neutral0.main" }} />
                      <Typography
                        variant="body1"
                        fontWeight={600}
                        textTransform="uppercase"
                        sx={{
                          color: "neutral0.main",
                          textShadow: "1px 1px 1px black",
                        }}
                      >
                        Teste grátis: {nextPayment.diff(dayjs(), "day")}{" "}
                        {nextPayment.diff(dayjs(), "day") === 1
                          ? "dia restante"
                          : "dias restantes"}
                      </Typography>
                    </Stack>
                  </Tooltip>
                </Link>
              )}
          </Stack>
        )}
      </Stack>

      {(person?.active || termRefused) && (
        <Stack
          key="private-user-layout-header-right"
          direction="row"
          // spacing={4}
          alignItems="center"
        >
          {/* <SupportFeedback /> */}
          <Stack
            paddingRight={4}
            paddingLeft={4}
            display={{ xs: "none", lg: "initial" }}
          >
            <OrganizationSelect />
          </Stack>
          <Notifications />
          {!isMobile && (
            <IconButton onClick={handleOpen}>
              <Badge badgeContent={warnings?.length} color="error">
                <Campaign sx={{ color: "white", fontSize: "28px" }} />
              </Badge>
            </IconButton>
          )}

          {!isMobile ? <OptionsMenu /> : <OptionsMenuMobile />}
        </Stack>
      )}
    </HeaderStack>
  );
}
