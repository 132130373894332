import dayjs from "dayjs";
import { useInternationalVerify } from "./useInternationalVerify";

function formatCEP(value: string) {
  if (!value) return "";

  let newCEP = value;
  newCEP = newCEP.replace(/\D/g, "");
  newCEP = newCEP.replace(/^(\d{2})(\d)/, "$1.$2");
  newCEP = newCEP.replace(/\.(\d{3})(\d)/, ".$1-$2");

  return newCEP;
}

function fixName(nome: string) {
  return nome.trim().split(/\s+/).join(" ");
}

function formatConselho(data: string) {
  // Primeiro, separar a parte antes e depois do "/"
  if (!data || typeof data !== "string") return "";

  // Primeiro, separar a parte antes e depois do "/"
  const [conselho, numeroCompleto] = data.split("/");

  if (conselho && numeroCompleto) {
    // A UF são os primeiros dois caracteres do númeroCompleto
    const uf = numeroCompleto.slice(0, 2);
    // O restante após a UF é o número
    const numero = numeroCompleto.slice(2);

    return `${conselho}-${uf} ${numero.trim()}`;
  }

  return "";
}

function formatCPF(value: string) {
  if (value === undefined) return "";

  let newCPF = value;
  newCPF = newCPF.replace(/\D/g, "");
  newCPF = newCPF.replace(/(\d{3})(\d)/, "$1.$2");
  newCPF = newCPF.replace(/(\d{3})(\d)/, "$1.$2");
  newCPF = newCPF.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

  return newCPF;
}

function formatPhoneNumber(value: string): string {
  if (value === undefined) return "";

  // Verificar se o número é brasileiro
  const cleanedValue = value.replace(/[\D]/g, "");
  if (!useInternationalVerify.isBrazilianPhoneNumber(cleanedValue)) {
    return value; // Retorna o valor original sem formatação
  }

  // Formatar número brasileiro
  let newTel = cleanedValue.startsWith("55")
    ? cleanedValue.slice(2)
    : cleanedValue;
  newTel = newTel.replace(/(\d{2})(\d)/, "($1) $2");
  newTel = newTel.replace(/(\d{5})(\d)/, "$1-$2");
  newTel = newTel.replace(/(-\d{4})(\d+?)/, "$1");

  return newTel;
}

function formatCNPJ(value: string) {
  if (value === undefined) return "";

  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})(\d)/, "$1.$2");
  value = value.replace(/(\d{3})(\d)/, "$1.$2");
  value = value.replace(/(\d{3})(\d)/, "$1/$2");
  value = value.replace(/(\d{4})(\d)/, "$1-$2");

  return value;
}

function formatDate(value: string) {
  if (value === undefined) return "";

  const formatDate = value?.split("-");
  const day = formatDate?.[2];
  const month = formatDate?.[1];
  const year = formatDate?.[0];

  return `${day}/${month}/${year}`;
}

function formatTime(time: any) {
  if (time === undefined) return "";

  const hours =
    time?.$H.toString().length === 1
      ? time?.$H.toString().padStart(2, "0")
      : time.$H.toString();
  const minutes =
    time?.$m.toString().length === 1
      ? time?.$m.toString().padStart(2, "0")
      : time.$m.toString();
  return `${hours}:${minutes}`;
}

function formatNameDisplay(value: string) {
  if (value === undefined) return "";

  return value
    ?.toLowerCase()
    ?.replace(/(^|\s)\S/g, (match) => match.toUpperCase());
}

function formatNameToInitials(value: string) {
  return value?.split(" ")?.map((el) => el?.[0].toUpperCase());
}

function formatNameFirstInitials(value: string, num: number) {
  const name = value?.split(" ");
  if (num === 1) {
    return name?.[0]?.[0].toUpperCase();
  } else if (num === 2) {
    const firstInitial = name[0]?.[0]?.toUpperCase() || "";
    const secondInitial = name[1]?.[0]?.toUpperCase() || "";
    return firstInitial + secondInitial;
  }
  return;
}

function translateWeekDays(ptDays: string): string {
  const englishDays: Record<string, string> = {
    Dom: "sun",
    Seg: "mon",
    Ter: "tue",
    Qua: "wed",
    Qui: "thu",
    Sex: "fri",
    Sab: "sat",
  };

  return englishDays[ptDays];
}

function translateRelationship(relationship: string): string {
  const portugueseRelationship: Record<string, string> = {
    father: "Pai",
    mother: "Mãe",
    legalDependent: "Responsável",
  };
  return portugueseRelationship[relationship];
}

type gender = "male" | "female" | "other" | "unknown" | undefined;

function formatGender(gender: gender) {
  const gendersList = {
    male: "Masculino",
    female: "Feminino",
    other: "Outro",
    unknown: "Desconhecido",
  };

  if (!gender) return "Não definido";
  return gendersList[gender];
}

function formatDateTimeToWeekdayDate(value: string) {
  if (!dayjs(value).isValid()) return;
  return dayjs(value).format("ddd, DD/MM");
}

function formatDateTimeToHour(value: string) {
  if (!dayjs(value).isValid()) return;
  return dayjs(value).format("HH:mm");
}

function formatAge(value: string) {
  const AuxDate = value?.split("-");
  const year = parseInt(AuxDate?.[0]);
  const month = parseInt(AuxDate?.[1]);
  const day = parseInt(AuxDate?.[2]);

  const CurrentYear = dayjs().year();
  const CurrentMonth = dayjs().month() + 1;
  const CurrentDay = dayjs().date();

  let age = CurrentYear - year;

  if (CurrentMonth < month || (CurrentMonth === month && CurrentDay < day)) {
    age--;
  }
  return age;
}

function removeSpaces(value: string): string {
  if (!value) return ""; // Retorna uma string vazia se o valor for indefinido ou vazio
  return value?.replace(/\s+/g, ""); // Remove todos os espaços em branco
}

function removeSpecialChars(value: string): string {
  if (value === undefined) return "";

  return value.replace(/\D/g, ""); // Remove todos os caracteres que não são dígitos
}

export const useFormatter = {
  formatCEP,
  fixName,
  formatCPF,
  formatPhoneNumber,
  formatCNPJ,
  formatDate,
  formatNameDisplay,
  formatTime,
  translateWeekDays,
  translateRelationship,
  formatGender,
  formatDateTimeToWeekdayDate,
  formatDateTimeToHour,
  formatNameToInitials,
  formatNameFirstInitials,
  formatAge,
  formatConselho,
  removeSpaces,
  removeSpecialChars,
};
