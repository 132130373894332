import { toast } from "react-toastify";
import { endpoints } from "../../configs/Settings";
import { HTTPResponseStatus } from "../../utils/enum";
import { useAxios } from "../../utils/useAxios";

export async function SendEmailFinishAccount(
  sender: any,
  message: any,
  identifier?: any
) {
  const url = `${endpoints.Brevo}`;

  const data = {
    sender: {
      name: sender?.name,
      email: sender?.email,
    },
    to: [
      {
        email: "contato@fluxmed.com.br", //" samuel.jesus@coreconsulting.com.br"
        name: "Fluxmed",
      },
    ],
    subject: "Solicitação de Backup e Encerramento de Conta Fluxmed",
    htmlContent: `<html>
    <head> Identificador: ${identifier} </head>
    
    <body>
    
    </strong> O usuário ${
      sender?.name
    } solicitou o backup e encerramento da conta! </strong>

    <p> ${message || ""} <p>

    <a href=${endpoints.XDSB_domain}/portal> Acessar Fluxmed  </a>
    
    </body>
    
    </html>`,
  };

  const headers = {
    Accept: "*/*",
    "api-key": `${endpoints.BrevoApiKey}`,
    "Content-Type": "application/json",
  };

  const response = await useAxios
    .post(url, data, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Created) {
        throw new Error(`SendEmail : ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("SendEmail ", error);
      toast.error(`SendEmail Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
