import React, { useState } from "react";
import {
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";

export default function SelectWithInput() {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");

  // Utilizando o tipo correto SelectChangeEvent<string>
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedOption(event.target.value);
    setInputValue(""); // Limpa o valor do input ao mudar a seleção
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <div>
      <FormControl fullWidth margin="normal">
        <InputLabel>Motivo de encerramento</InputLabel>
        <Select
          labelId="select-label"
          value={selectedOption}
          onChange={handleSelectChange}
          label="Motivo de encerramento"
          fullWidth
        >
          <MenuItem value="">
            <em>Escolha uma opção</em>
          </MenuItem>
          <MenuItem value="opcao1">Motivo 1</MenuItem>
          <MenuItem value="opcao2">Motivo 2</MenuItem>
          <MenuItem value="opcaoEspecial">Outro</MenuItem>
        </Select>
        <FormHelperText>Selecione uma opção para continuar</FormHelperText>
      </FormControl>

      {selectedOption === "opcaoEspecial" && (
        <TextField
          label="Descreva o motivo"
          value={inputValue}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
      )}
    </div>
  );
}
