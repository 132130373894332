import { Info } from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";

import { Controller } from "react-hook-form";
import Term from "./TermText";

export default function TermOfPatient({
  control,
  isAssigned,
}: {
  control: any;
  isAssigned: boolean;
}) {
  return (
    <>
      <FormGroup
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Controller
          name="termOfPatient"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox {...field} checked={field.value} color="primary" />
              }
              label={
                <Typography variant="body1">
                  Eu li e estou de acordo com os Termos de Uso e Política de
                  Privacidade .
                </Typography>
              }
            />
          )}
        />
      </FormGroup>
      {/* {isAssigned && ( */}
      <Stack
        direction="row"
        justifyContent="left"
        alignItems="center"
        spacing={2}
        width="100%"
        p={2}
      >
        <Info sx={{ color: "warning300.main" }} />
        <Typography variant="body2" fontWeight={400} color="neutral600.main">
          Aceite os Termos de Uso e Política de Privacidade para continuar o
          acesso a plataforma e seus serviços, ao recusar os Termos de Uso e
          Política de Privacidade, sua conta será encerrada.
        </Typography>
      </Stack>
      {/* )} */}
    </>
  );
}
