import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { WarningCentralDialog } from "../components/layout/private/main/WarningCentralDialog";
import { Campaign, ChatBubble, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "./SessionContext";
import { useOrganizationContext } from "./OrganizationContext";
import { useFhirData } from "../utils/useFhirData";

interface WarningCentralContextValue {
  isOpen: boolean;
  handleClose: any;
  handleOpen: any;
  warnings: any;
}

export const WarningCentralContext = createContext<WarningCentralContextValue>(
  null!
);

export function WarningCentralContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { access, loading } = useSessionContext();
  const { organization, loading: loadingOrganization } =
    useOrganizationContext();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsOpen(false);
    localStorage?.setItem("warningsClosedOnce", "true");
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const hasPhone = organization?.contact?.[0]?.telecom?.find(
    (e: any) => e?.system === "phone"
  )?.value;
  const hasRegionalId = useFhirData.findIdentifier(
    organization?.identifier,
    "inscricaoEstadual"
  );
  //  realiza validaçoes

  const warnings = [
    {
      text: "Chat",
      description: "Conheça o nosso chat",
      displayIcon: <Campaign />,
      actionIcon: <ChatBubble />,
      tooltipText: "Acessar",
      rule: false,
      type: "feature",
      onClick: () => {
        handleClose();
      },
    },
    {
      text: "Número de telefone",
      description: "Informe o telefone da clínica para contato!",
      displayIcon: <Campaign />,
      actionIcon: <Settings />,
      tooltipText: "Acessar",
      rule:
        access?.type === "professional" &&
        (access?.roles?.includes("manager") ||
          access?.roles?.includes("owner")) &&
        !hasPhone,
      type: "settings",
      onClick: () => {
        navigate("/clinic#profileClinic", {
          state: {
            open: "editPhone",
          },
        });
        handleClose();
      },
    },
    {
      text: "Inscrição estadual",
      description: "Informe o número de inscrição estadual.",
      displayIcon: <Campaign />,
      actionIcon: <Settings />,
      tooltipText: "Acessar",
      rule:
        access?.type === "professional" &&
        (access?.roles?.includes("manager") ||
          access?.roles?.includes("owner")) &&
        !hasRegionalId,
      type: "settings",

      onClick: () => {
        navigate("/clinic#profileClinic", {
          state: {
            open: "editEnrollment",
          },
        });
        handleClose();
      },
    },
  ]?.filter((e: any) => e?.rule === true);

  useEffect(() => {
    const wasClosedOnce = localStorage.getItem("warningsClosedOnce") === "true";

    if (
      !loading &&
      !loadingOrganization &&
      access &&
      organization &&
      warnings.length > 0 &&
      !wasClosedOnce
    ) {
      handleOpen();
    }
  }, [loading, access, organization, warnings, loadingOrganization]);

  const contextValue = {
    isOpen,
    handleClose,
    handleOpen,
    warnings,
  };

  return (
    <WarningCentralContext.Provider value={contextValue}>
      {isOpen && access?.type && !loading && (
        <WarningCentralDialog isOpen={isOpen} handleClose={handleClose} />
      )}
      {children}
    </WarningCentralContext.Provider>
  );
}

export function useWarningCentralContext() {
  return useContext(WarningCentralContext);
}
