import { Stack, Typography } from "@mui/material";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { useFormatter } from "../../../utils/useFormatter";
import { useFhirData } from "../../../utils/useFhirData";

interface IProps {
  location: fhir5.Location;
  organization?: any;
}

function isExtensionVirtualService(extension: fhir5.Extension) {
  return (
    extension.url ===
      "https://fluxmed.com.br/fhir/StructureDefinition/virtual-service" &&
    extension.valueBoolean === true
  );
}

function isVirtualService(location: fhir5.Location) {
  return !!location?.extension?.find(isExtensionVirtualService);
}

export function LocationInfo({ location, organization }: IProps) {
  const brandLogoOrganization = useFhirData?.brandLogoOrganization(
    organization?.resource?.extension
  );

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        gap: "20px",
      }}
      direction="row"
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        width="120px"
        height="120px"
        borderRadius={2}
        sx={{ borderColor: "primary400.main" }}
      >
        {brandLogoOrganization ? (
          <img alt="Brand Logo" width={"100%"} src={brandLogoOrganization} />
        ) : (
          <Typography variant="h4" fontWeight={700} fontFamily="fantasy">
            {useFormatter.formatNameFirstInitials(
              organization?.resource?.alias?.[0],
              2
            )}
          </Typography>
        )}
      </Stack>
      <Stack spacing={2}>
        <Typography
          sx={{ color: "neutral1000.main", wordBreak: "break-word" }}
          variant="h5"
          fontWeight={600}
        >
          {organization?.resource?.alias?.[0] ||
            organization?.resource?.name ||
            "-"}
        </Typography>

        <Stack width="100%" direction="row" alignItems="start" gap="20px">
          <Typography variant="body1" color="neutral700.main" textAlign="left">
            Atendimento
          </Typography>
          <Typography
            variant="body1"
            color="neutral1000.main"
            flex={1}
            fontWeight={500}
          >
            {isVirtualService(location) ? "Remoto" : "Presencial"}
          </Typography>
        </Stack>
        {!isVirtualService(location) && (
          <Stack width="100%" direction="row" alignItems="start" gap="20px">
            <Typography
              variant="body1"
              color="neutral700.main"
              textAlign="left"
              sx={{
                wordBreak: "break-word",
                textTransform: "capitalize",
              }}
            >
              Localização
            </Typography>
            <Typography
              variant="body1"
              color="neutral1000.main"
              flex={1}
              fontWeight={500}
              sx={{
                wordBreak: "break-word",
                textTransform: "capitalize",
              }}
            >
              {/* TODO: Criar função geradora de endereço */}
              {location.address?.line?.join(", ")}
            </Typography>
          </Stack>
        )}
        <Stack width="100%" direction="row" alignItems="start" gap="20px">
          <Typography
            variant="body1"
            color="neutral700.main"
            textAlign="left"
            sx={{
              wordBreak: "break-word",
              textTransform: "capitalize",
            }}
          >
            Contato
          </Typography>
          <Typography
            variant="body1"
            color="neutral1000.main"
            flex={1}
            fontWeight={500}
            sx={{
              wordBreak: "break-word",
              textTransform: "capitalize",
            }}
          >
            {location?.contact?.[0]?.telecom?.[0]?.value ||
              organization?.resource?.contact
                ?.find((e: any) => e?.telecom)
                ?.telecom?.find((el: any) => el?.system === "phone")?.value ||
              "-"}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
