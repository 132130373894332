import { Box, Paper, Stack, Typography, styled } from "@mui/material";
import { PrimaryButton } from "../../../components/common";
import { Check } from "@mui/icons-material";

const CustomBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.neutral0.main,
  width: "100%",
  alignItems: "center",
  maxWidth: "100%",
  height: "fit-content",
  borderRadius: "30px",
  [theme.breakpoints.down("md")]: {
    padding: "0%",
  },
  [theme.breakpoints.up("xs")]: {
    height: "100%",
  },
}));

interface IProps {
  option: any;
  onSelectPlan: (plan: any) => void;
}

export function SubscriptionCard({ option, onSelectPlan }: IProps) {
  const handleChoose = () => {
    onSelectPlan(option.plan);
  };

  return (
    <CustomBox>
      <Paper
        elevation={3}
        sx={{
          padding: "24px",
          borderRadius: "10px",
          minHeight: "420px",
        }}
      >
        <Stack spacing={4}>
          <Stack alignItems="center" spacing={2}>
            <Stack>
              <Typography variant="h6" fontWeight={600}>
                {option?.plan.reason || ""}
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              color="neutral700.main"
              width="300px"
              textAlign="center"
            >
              Assine agora por apenas
            </Typography>
            <Stack direction="row">
              <Typography variant="h5" color="primary.main" fontWeight="600">
                R$
              </Typography>
              <Typography variant="h2" color="neutral700.main" fontWeight={600}>
                {option?.plan.auto_recurring?.transaction_amount
                  .toFixed(2)
                  .replace(".", ",") || ""}
              </Typography>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <PrimaryButton
              width="100%"
              height="40px"
              onClick={handleChoose}
            >
              Escolher
            </PrimaryButton>
            <Stack alignItems="flex-start">
              <Stack direction="row" spacing={1} alignItems="center">
                <Check sx={{ color: "primary.main", fontSize: 32 }} />
                <Typography>
                  Acesso completo a todas as funcionalidades.
                </Typography>
              </Stack>
              {option?.plan.auto_recurring?.free_trial?.frequency && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Check sx={{ color: "primary.main", fontSize: 32 }} />
                  <Typography>
                    {option?.plan.auto_recurring?.free_trial?.frequency === 1
                      ? `${option?.plan.auto_recurring?.free_trial?.frequency} dia grátis para experimentar`
                      : `${option?.plan.auto_recurring?.free_trial?.frequency} dias grátis para experimentar `}
                  </Typography>
                </Stack>
              )}
              <Stack direction="row" spacing={1} alignItems="center">
                <Check sx={{ color: "primary.main", fontSize: 32 }} />
                <Typography>
                  Cadastre até{" "}
                  {option?.plan.external_reference > 1
                    ? `${option?.plan.external_reference} profissionais`
                    : `${option?.plan.external_reference} profissional`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </CustomBox>
  );
}