import { IconButton, Menu, MenuItem, TableCell, Tooltip } from "@mui/material";
import {
  CalendarToday,
  CancelOutlined,
  Delete,
  MailOutline,
  Settings,
} from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { PutPractitionerRole } from "../../../services/fhir";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { oids } from "../../../configs/Settings";
import { toast } from "react-toastify";
import { InativateConfirmDialog } from "./InativateConfirmDialog";
import { useState } from "react";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import { ManagePractitionersDialog } from "./ManagePractitionersDialog";
import { useViewContext } from "../../../contexts/ViewContext";
import { useContractContext } from "../../../contexts/ContractContext";
import { ProfessionalDialogAction } from "./ProfessionalDialogAction";

export function ManagePractitionerActions({ row }: any) {
  const { access, user } = useSessionContext();
  const { organization } = useOrganizationContext();
  const { availableRoles } = useViewContext();
  const { practitionerRole, refetchPractitionerRole } =
    usePractitionerRoleContext();
  const { currentContract } = useContractContext();
  const [inativateDialogOpen, setInativateDialogOpen] =
    useState<boolean>(false);
  const [modalRolePractitioner, setModalRolePractitioner] =
    useState<boolean>(false);

  const [modalCancelAndRemove, setModalCancelAndRemove] = useState<{
    status: boolean;
    type?: "cancelInvite" | "deletePractitioner";
  }>({ status: false });

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const limitPractitioners =
    (practitionerRole &&
      practitionerRole?.filter((el: any) => el?.resource?.active !== false)
        .length >=
        parseInt(
          currentContract?.[0]?.resource?.term?.[0]?.identifier?.value || ""
        )) ||
    false;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableCell align="center">
        {row?.situation === "Ativo" && (
          <>
            <Tooltip title="Conferir agenda">
              <IconButton
                onClick={() => {
                  navigate("/schedule", {
                    state: {
                      filter: `Practitioner/${row?.practitionerId}`,
                    },
                  });
                }}
              >
                <CalendarToday />
              </IconButton>
            </Tooltip>

            <Tooltip title="Configurar">
              <IconButton
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                disabled={
                  row.practitionerId?.includes(user.username) ||
                  (row?.roles?.includes("owner") &&
                    !availableRoles?.includes("owner"))
                }
              >
                <Settings />
              </IconButton>
            </Tooltip>
          </>
        )}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {row?.situation === "Ativo" && (
            <>
              <MenuItem
                onClick={() => {
                  setModalRolePractitioner(true);
                  setAnchorEl(null);
                }}
              >
                Editar Atividade
              </MenuItem>

              <MenuItem
                onClick={() => {
                  setInativateDialogOpen(true);
                  setAnchorEl(null);
                }}
              >
                Inativar
              </MenuItem>
            </>
          )}
        </Menu>

        {row?.situation === "Convidado" && (
          <>
            <Tooltip title="Reenviar convite">
              <IconButton
                onClick={async () => {
                  await PutPractitionerRole({
                    organizationId: `${oids.cnpj}-${access.organizationId}`,
                    organizationDisplay: `${
                      organization?.name || organization?.alias
                    }`,
                    practitionerId: row?.practitionerId,
                    practitionerDisplay: row?.practitionerName,
                    invite: true,
                  });
                  refetchPractitionerRole();
                }}
              >
                <MailOutline />
              </IconButton>
            </Tooltip>

            <Tooltip title="Cancelar convite">
              <IconButton
                onClick={() =>
                  setModalCancelAndRemove({
                    status: true,
                    type: "cancelInvite",
                  })
                }
              >
                <Delete sx={{ color: "error700.main" }} />
              </IconButton>
            </Tooltip>
          </>
        )}

        {row?.situation === "Inativo" && (
          <>
            <Tooltip title="Convidar">
              <IconButton
                disabled={!currentContract ? true : limitPractitioners}
                onClick={async () => {
                  try {
                    await PutPractitionerRole({
                      organizationId: `${oids.cnpj}-${access.organizationId}`,
                      organizationDisplay: `${
                        organization?.name || organization?.alias
                      }`,
                      practitionerId: row?.practitionerId,
                      practitionerDisplay: row?.practitionerName,
                      ...(row?.roles && { role: row?.roles }),
                      invite: true,
                    });
                    toast.success("Convite enviado");
                    refetchPractitionerRole();
                  } catch (err) {
                    toast.error("Não foi possível enviar o convite");
                  }
                }}
              >
                <MailOutline />
              </IconButton>
            </Tooltip>

            <Tooltip title="Remover profissional">
              <IconButton
                onClick={() =>
                  setModalCancelAndRemove({
                    status: true,
                    type: "deletePractitioner",
                  })
                }
              >
                <Delete sx={{ color: "error700.main" }} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </TableCell>

      {inativateDialogOpen && (
        <InativateConfirmDialog
          isOpen={inativateDialogOpen}
          setIsOpen={setInativateDialogOpen}
          practitionerId={row?.practitionerId?.split("-")?.[1]}
        />
      )}

      {modalCancelAndRemove.status && (
        <ProfessionalDialogAction
          isOpen={modalCancelAndRemove.status}
          handleClose={() =>
            setModalCancelAndRemove({
              status: false,
            })
          }
          row={row}
          type={modalCancelAndRemove.type}
        />
      )}

      {modalRolePractitioner && (
        <ManagePractitionersDialog
          isOpen={modalRolePractitioner}
          handleClose={() => setModalRolePractitioner(false)}
          row={row}
          refetchPractitionerRole={refetchPractitionerRole}
        />
      )}
    </>
  );
}
