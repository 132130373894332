import { Box, Button, Stack, Tab, Tabs } from "@mui/material";

import { SyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Add } from "@mui/icons-material";
import { ListaDeIntervenções } from "../Prototype/components/ListaDeIntervenções";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../components/common";
import { Tratamentos } from "./components/Tratamentos";

import { ProcedureAndCertificate } from "../Prototype/components/ProcedureAndCertificate";
import { Certificate } from "../Prototype/components/Certificate/Certificate";
import { SelectProtocolDialog } from "../medicaldocumentation/components/prescription/SelectProtocolDialog";
import { CreateProtocolDialog } from "../medicaldocumentation/components/prescription/CreateProtocolDialog";
import { useNavigate } from "react-router-dom";
import { ExamsFields } from "../Prototype/components/ExamsFields";
import { useContractContext } from "../../contexts/ContractContext";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  teste?: any;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export function CreateProtocolContent({
  setSelected,
  defaultItens,
  setId,
  id,
}: any) {
  const { currentContract } = useContractContext();
  const [selectProtocol, setSelectProtocol] = useState<boolean>(false);
  const [createDialog, setCreateDialog] = useState<boolean>(false);

  const [appliedProtocol, setAppliedProtocol] = useState<Array<any>>([]);

  const [expandedList, setExpandedList] = useState<boolean>(true);

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const [tabValueMedication, setTabValueMedication] = useState(0);
  const handleChangeMedication = (event: SyntheticEvent, newValue: number) => {
    setTabValueMedication(newValue);
  };

  const methods = useForm();
  const { handleSubmit, control, reset, setValue } = methods;

  const [interventionsList, setInterventionsList] = useState<Array<any>>([]);
  const [selectedItem, setSelectedItem] = useState<number>();

  const [clean, setClean] = useState<boolean>(false);

  const functions = {
    handleAdd: (item: any) => {
      setInterventionsList((prev: any) => [
        ...prev?.filter((e: any) => e?.name),
        item,
      ]);
      setClean(true);
    },
    handleEdit: (item: any) => {
      setInterventionsList((prevState: any) => {
        const updatedItems = [...prevState];
        const index = updatedItems.findIndex((el, i) => i === selectedItem);
        if (index !== -1) {
          updatedItems[index] = item;
        }

        return updatedItems;
      });
      setSelectedItem(undefined);
      setClean(true);
    },
    handleSign: (index: any, signedBase64: any) => {
      setInterventionsList((prevState: any) => {
        const updatedItems = [...prevState];

        updatedItems[index] = {
          ...updatedItems[index],
          signedBase64,
          sign: true,
        };

        return updatedItems;
      });
      setSelectedItem(undefined);
      setClean(true);
    },
    handleRemove: (index: number) => {
      setSelectedItem(undefined);

      setInterventionsList((prev: any) =>
        prev.filter((item: any, i: number) => i !== index)
      );
    },
    handleSelect: (item: any, index: any) => {
      reset();

      setSelectedItem(index);

      if (item?.type === "medication") {
        setTabValue(0);

        setValue("medication", item?.inputValue);
        setValue("quantity", item?.quantity?.value || item?.quantity);
        setValue("frequency", item.frequency);
        setValue("note", item.note);
        setValue("time", item.time);
        setValue("form", item.formValue);
      }
      if (item?.type === "supplement") {
        setTabValue(0);

        setValue("supplement", item?.inputValue);
        setValue("quantity", item?.quantity?.value || item?.quantity);
        setValue("frequency", item?.frequency);
        setValue("note", item?.note);
        setValue("time", item?.time);
        setValue("form", item?.formValue);
      }
      if (item?.type === "herbalMedicine") {
        setTabValue(0);

        setValue("herbalMedicine", item.inputValue);
        setValue("quantity", item.quantity.value || item.quantity);
        setValue("frequency", item.frequency);
        setValue("note", item.note);
        setValue("time", item.time);
        setValue("form", item.formValue);
      }
      if (item.type === "procedure") {
        setTabValue(2);

        setValue("procedure", item.inputValue);
        setValue("note", item.note);
      }
      if (item.type === "service") {
        reset({
          inputValue: {},
          condition: [],
          category: {},
          note: "",
        });
        setTabValue(1);

        setValue("service", item.inputValue);
        setValue("condition", item.condition);
        item.category && setValue("category", item.category);
        item.note && setValue("note", item.note);
      }
      if (item.type === "certificate") {
        setTabValue(3);
        setValue("certificate", item.note);
      }
    },
    handleRemoveProtocol: (index: number, value: string) => {
      setAppliedProtocol((prev: any) =>
        prev.filter((item: any, i: number) => i !== index)
      );
      setInterventionsList((prev: any) =>
        prev.filter(
          (item: any, i: number) => item?.protocol?.reference !== value
        )
      );
    },
  };

  const selectedInterventions = {
    all: interventionsList,
    medicationSpecial: interventionsList?.filter(
      (el: any) =>
        el?.tag?.toUpperCase()?.includes("VERMELHA") ||
        el?.tag?.toUpperCase()?.includes("PRETA") ||
        el?.tag?.toUpperCase()?.includes("ESPECIAL")
    ),
    medicationSimple: interventionsList?.filter(
      (el: any) =>
        (el?.type === "medication" ||
          el?.type === "supplement" ||
          el?.type === "herbalMedicine") &&
        !(
          el?.tag?.toUpperCase()?.includes("VERMELHA") ||
          el?.tag?.toUpperCase()?.includes("PRETA") ||
          el?.tag?.toUpperCase()?.includes("ESPECIAL")
        )
    ),
    service: interventionsList?.filter((el: any) => el?.type === "service"),
    procedure: interventionsList?.filter((el: any) => el?.type === "procedure"),
    certificates: interventionsList?.filter(
      (el: any) => el?.type === "certificate"
    ),
  };

  const action =
    selectedItem === undefined ? functions.handleAdd : functions.handleEdit;
  const isEdit = selectedItem !== undefined;

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        style={{ width: "100%" }}
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Stack
            sx={{ pl: 3, width: "100%" }}
            direction="row"
            justifyContent="space-between"
          >
            {" "}
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              spacing={2}
              pr={3}
            >
              {children}

              <Stack
                width="100%"
                minWidth="600px"
                overflow="auto"
                maxHeight="60vh"
                paddingRight={3}
              >
                <ListaDeIntervenções
                  selectedInterventions={selectedInterventions}
                  functions={functions}
                  setCreateDialog={setCreateDialog}
                  expandedList={expandedList}
                  setExpandedList={setExpandedList}
                  appliedProtocol={appliedProtocol}
                />
              </Stack>
            </Stack>
          </Stack>
        )}
      </div>
    );
  }

  const navigate = useNavigate();

  useEffect(() => {
    setInterventionsList([]);
    if (defaultItens?.itens?.length && !interventionsList?.length) {
      defaultItens?.itens?.map((el: any) => functions?.handleAdd(el));
    }
  }, [defaultItens]);

  useEffect(() => {
    if (clean) {
      reset();
      // Reset específico da tab de exames
      reset({
        service: {},
        condition: [],
        category: {},
        note: "",
      });
      setClean(false);
    }
  }, [clean, reset]);

  return (
    <Stack spacing={2}>
      <Stack pl={2}>
        <Button
          sx={{ width: "fit-content", textTransform: "none" }}
          startIcon={<Add />}
          onClick={() => setSelectProtocol(true)}
        >
          Selecionar protocolo
        </Button>
      </Stack>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "fit-content",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Tratamentos" {...a11yProps(0)} />
          <Tab label="Exames" {...a11yProps(1)} />
          <Tab label="Procedimentos" {...a11yProps(2)} />
          <Tab label="Relatórios" {...a11yProps(3)} />
          {/* <Tab label="Referências" {...a11yProps(4)} />
          <Tab label="Descrição" {...a11yProps(5)} /> */}
        </Tabs>

        <TabPanel value={tabValue} index={0} teste="teste">
          <Tratamentos
            tabValueMedication={tabValueMedication}
            handleChangeMedication={handleChangeMedication}
            clean={clean}
            control={control}
            setValue={setValue}
            setClean={setClean}
            handleSubmit={handleSubmit}
            action={action}
            isEdit={isEdit}
          />
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Stack width="100%" maxHeight="60vh" overflow="auto">
            <ExamsFields
              control={control}
              setValue={setValue}
              action={action}
              setClean={setClean}
              handleSubmit={handleSubmit}
              isEdit={isEdit}
            />
          </Stack>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Stack width="100%" maxHeight="60vh" overflow="auto">
            <ProcedureAndCertificate
              control={control}
              setValue={setValue}
              action={action}
              setClean={setClean}
              handleSubmit={handleSubmit}
              isEdit={isEdit}
            />
          </Stack>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <Stack width="100%" maxHeight="60vh" overflow="auto">
            <Certificate
              control={control}
              setValue={setValue}
              action={action}
              setClean={setClean}
              handleSubmit={handleSubmit}
              isEdit={isEdit}
            />
          </Stack>
        </TabPanel>
      </Box>
      {/* <Stack justifySelf="start" marginTop={3}>
        <SecondaryButton
          onClick={() => {
            setSelected("");
            setId("");
          }}
          width="180px"
          height="50px"
        >
          Voltar
        </SecondaryButton>
      </Stack> */}
      <Stack width="100%" direction="row" justifyContent="space-between">
        <SecondaryButton
          onClick={() => navigate(-1)}
          width="180px"
          height="50px"
        >
          Voltar
        </SecondaryButton>

        <PrimaryButton
          width="180px"
          height="50px"
          onClick={() => setCreateDialog(true)}
          disabled={!currentContract || !selectedInterventions?.all?.length}
        >
          Confirmar
        </PrimaryButton>
      </Stack>

      {selectProtocol && (
        <SelectProtocolDialog
          isOpen={selectProtocol}
          handleClose={() => setSelectProtocol(false)}
          addItem={functions?.handleAdd}
          setAppliedProtocol={setAppliedProtocol}
        />
      )}
      {createDialog && (
        <CreateProtocolDialog
          id={id}
          itens={selectedInterventions?.all}
          isOpen={createDialog}
          handleClose={() => setCreateDialog(false)}
        />
      )}
    </Stack>
  );
}
