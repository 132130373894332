import { PrivateMainLayout } from "../../components/layout";
import Term from "./Term";

export default function TermMain() {
  return (
    <>
      <PrivateMainLayout dashboard noSidebar={true} mobileView={false}>
        <Term />
      </PrivateMainLayout>
      {/* <Stack width="100%" sx={{ display: { xs: "block", lg: "none" } }} mt={5}>
        <MobileBarNavigation />
      </Stack> */}
    </>
  );
}
