import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { GetPractitionerRole } from "../services/fhir";
import { useSessionContext } from "./SessionContext";
import { oids } from "../configs/Settings";

interface PractitionerRoleContextValue {
  practitionerRole: fhir5.BundleEntry<fhir5.PractitionerRole>[] | undefined;
  practitioner: fhir5.BundleEntry<fhir5.Practitioner>[] | undefined;
  userPractitionerRole: fhir5.PractitionerRole | undefined;
  userPractitioner: fhir5.Practitioner | undefined;
  refetchPractitionerRole: Function;
  practitionerRoleLoading: any;
  rolesOrganization: any;
  userSpecialties: fhir5.PractitionerRole["specialty"];
}

export const PractitionerRoleContext =
  createContext<PractitionerRoleContextValue>(null!);

export function PractitionerRoleContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { user, access } = useSessionContext();

  const [practitionerRole, setPractitionerRole] =
    useState<fhir5.BundleEntry<fhir5.PractitionerRole>[]>();
  const [practitioner, setPractitioner] =
    useState<fhir5.BundleEntry<fhir5.Practitioner>[]>();

  const [rolesOrganization, setRolesOrganization] =
    useState<fhir5.BundleEntry<fhir5.Organization>[]>();

  const userSpecialties = practitionerRole?.find(
    (el: fhir5.BundleEntry<fhir5.PractitionerRole>) =>
      el?.resource?.id?.includes(user.username)
  )?.resource?.specialty;

  const userPractitionerRole = practitionerRole?.find(
    (el: fhir5.BundleEntry<fhir5.PractitionerRole>) =>
      el?.resource?.id?.includes(user.username)
  )?.resource;

  const userPractitioner = practitioner?.find(
    (el: fhir5.BundleEntry<fhir5.Practitioner>) =>
      el?.resource?.id?.includes(user.username)
  )?.resource;

  const [refetch, setRefetch] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();

  function refetchPractitionerRole() {
    setRefetch(!refetch);
  }

  useEffect(() => {
    setLoading(true);
    async function fetchPractitionerRole() {
      const accessFilter = access?.organizationId
        ? {
            organization: `${oids.cnpj}-${access?.organizationId}`,
            includeOrganization: true,
          }
        : {
            practitioner: `${oids.cpf}-${user.username}`,
            includeOrganization: true,
          };
      const practitionerRoleResponse = await GetPractitionerRole(accessFilter);

      const practitionerRole = practitionerRoleResponse?.entry?.filter(
        (
          el:
            | fhir5.BundleEntry<fhir5.PractitionerRole>
            | fhir5.BundleEntry<fhir5.Practitioner>
        ) => el.resource?.resourceType === "PractitionerRole"
      );
      const practitioner = practitionerRoleResponse?.entry?.filter(
        (
          el:
            | fhir5.BundleEntry<fhir5.PractitionerRole>
            | fhir5.BundleEntry<fhir5.Practitioner>
        ) => el.resource?.resourceType === "Practitioner"
      );

      const organization = practitionerRoleResponse?.entry?.filter(
        (
          el:
            | fhir5.BundleEntry<fhir5.PractitionerRole>
            | fhir5.BundleEntry<fhir5.Organization>
        ) => el.resource?.resourceType === "Organization"
      );

      setRolesOrganization(organization);
      setPractitioner(practitioner);

      setPractitionerRole(practitionerRole);
    }
    try {
      if (user?.username) fetchPractitionerRole();
    } catch (err) {
      console.log("PractitionerRole", err);
    } finally {
      setLoading(false);
    }
  }, [access?.organizationId, refetch, user?.username]);

  const contextValue = {
    practitionerRole,
    practitioner,
    userPractitionerRole,
    userPractitioner,
    rolesOrganization,
    refetchPractitionerRole,
    practitionerRoleLoading: loading,
    userSpecialties,
  };

  return (
    <PractitionerRoleContext.Provider value={contextValue}>
      {children}
    </PractitionerRoleContext.Provider>
  );
}

export function usePractitionerRoleContext() {
  return useContext(PractitionerRoleContext);
}
