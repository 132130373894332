import { Box, Button, Divider, IconButton, Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import {
  InputDate,
  InputText,
} from "../../../ClinicalRegister/components/QuestionnaireViewer/components/Form";
import { Delete } from "@mui/icons-material";
import { useQuestionnaireContext } from "../../../../contexts/QuestionnaireContext";
import { ResultadosHistory } from "./ResultadosHistory";
import { useMedicalCareContext } from "../../../../contexts/MedicalCareContext";

interface GruposExames {
  [key: string]: any[];
}

export function Resultado() {
  const { historyValues } = useQuestionnaireContext();
  const { control, unregister } = useMedicalCareContext();

  const { newExams, setNewExams } = useMedicalCareContext();

  function agruparPorNomeExame(exames: any) {
    const grupos: GruposExames = {};
    exames?.forEach((exame: any) => {
      const nomeExameItem = exame?.item?.find(
        (item: any) => item?.text === "Nome do exame"
      );
      if (nomeExameItem) {
        const nomeExame = nomeExameItem?.answer?.[0]?.valueString;
        if (nomeExame === undefined) return;
        if (!grupos[nomeExame]) {
          grupos[nomeExame] = [];
        }
        grupos[nomeExame].push(exame);
      }
    });

    return grupos;
  }

  const responseEntries = historyValues?.map((el: any) => el?.resource);

  const examResultResponses = responseEntries?.map((itemResponse: any) => {
    const answerDate = itemResponse?.authored;
    const filterItems = itemResponse?.item
      ?.filter((e: any) => e?.text === "Resultado de exames")?.[0]
      ?.item?.filter((subItem: any) => {
        return subItem?.item?.[0]?.answer;
      });
    const displayReferences = itemResponse?.item?.filter(
      (e: any) => e?.linkId === "displayReferences"
    )?.[0]?.item;
    const displayInfo = {
      healthcare: displayReferences?.[0]?.answer?.[0]?.valueString || "",
      practitioner: displayReferences?.[1]?.answer?.[0].valueString || "",
      identifier: itemResponse?.author?.reference?.split("-")?.[1],
    };
    return { answerDate, filterItems, displayInfo };
  });

  const dataExamResults = examResultResponses
    ?.filter((el: any) => el?.filterItems?.length)
    ?.flatMap((item: any) => {
      const auxItem = item?.filterItems?.map((response: any) => {
        return {
          ...response,
          displayInfo: item?.displayInfo,
          answerDate: item?.answerDate,
        };
      });

      return auxItem;
    });

  const groupExams = agruparPorNomeExame(dataExamResults);

  const [dateRef, setDateRef] = useState<Dayjs>();

  const handleAddInput = () => {
    const newInput = {
      nameId: `newExames.${newExams?.length}.name`,
      valueId: `newExames.${newExams?.length}.value`,
      dateId: `newExames.${newExams?.length}.date`,
    };
    setNewExams([...newExams, newInput]);
  };

  return (
    <Stack width="100%" overflow="auto" maxHeight="70vh">
      <Stack height="30vh">
        <Stack direction="row" width="100%" gap={8} sx={{ mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "12px",
              paddingRight: "64px",
              borderRight: "1px solid #ccc",
            }}
          >
            <InputDate
              key={"Results"}
              label="Data de referência"
              handleChange={(e: any) => setDateRef(e.$d)}
              name="Data de Referencia"
              defaultValue={dayjs()}
              control={control}
            />
            <Button
              sx={{ width: "250px" }}
              onClick={handleAddInput}
              variant="outlined"
            >
              Novo exame
            </Button>
          </Box>
          <Box
            sx={{
              height: "25vh",
              overflow: "auto",
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {newExams?.map((input: any, index: any) => (
              <>
                {(input?.nameId || input?.answerName) && (
                  <Stack
                    direction="row"
                    spacing={1}
                    padding={1}
                    alignItems="center"
                  >
                    <InputText
                      key={input?.nameId}
                      label="Nome do exame"
                      name={input?.nameId}
                      control={control}
                      sx={{ mb: 4, width: "300px" }}
                      autoFocus
                    />
                    <InputText
                      key={input?.valueId}
                      label="Valor do exame"
                      name={input?.valueId}
                      control={control}
                      sx={{ mb: 4, width: "300px" }}
                    />
                    <InputDate
                      key={input?.dateId}
                      label="Data de realização"
                      name={input?.dateId}
                      defaultValue={dayjs(dateRef)}
                      control={control}
                    />
                    <IconButton
                      onClick={() => {
                        setNewExams((prevItems: any) => {
                          const updatedItems = [...prevItems]; // Faz uma cópia do array
                          updatedItems[index] = ""; // Altera o item específico
                          return updatedItems; // Retorna o novo array modificado
                        });
                        unregister(`newExames.${index}`);
                      }}
                    >
                      <Delete sx={{ color: "error600.main" }} />
                    </IconButton>
                  </Stack>
                )}
              </>
            ))}
          </Box>
          {/*TODO: Fazer função para remover item */}
        </Stack>

        <Divider sx={{ width: "100%", background: "neutral400.main", mb: 4 }} />
      </Stack>

      <Stack sx={{ overflow: "auto", maxHeight: "40vh", padding: 2 }}>
        {Object.keys(groupExams)?.map((el: any) => {
          return (
            <>
              <ResultadosHistory
                name={el}
                values={groupExams?.[el]}
                dateRef={dateRef}
              />
            </>
          );
        })}
      </Stack>
    </Stack>
  );
}
