import { Stack, Typography } from "@mui/material";
import { useFormatter } from "../../../utils/useFormatter";
import Logo from "./images/Logo.png";
import { Phone, Place, VideoCameraFront } from "@mui/icons-material";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";

interface IProps {
  location: fhir5.Location;

  onClick: any;
}

export function DisponibilityCardMobile({ location, onClick }: IProps) {
  function isExtensionVirtualService(extension: fhir5.Extension) {
    return (
      extension.url ===
        "https://fluxmed.com.br/fhir/StructureDefinition/virtual-service" &&
      extension.valueBoolean === true
    );
  }

  function isVirtualService(location: fhir5.Location) {
    return !!location?.extension?.find(isExtensionVirtualService);
  }

  const { organizationList } = useOrganizationContext();
  const findOrganization = organizationList?.find(
    (org) =>
      org?.resource?.id ===
      location?.managingOrganization?.reference?.split("/")?.[1]
  );

  const locationContact = location?.contact?.[0]?.telecom?.[0]?.value;
  const organizationContact = findOrganization?.resource?.contact
    ?.find((e: any) => e?.telecom)
    ?.telecom?.find((el: any) => el?.system === "phone")?.value;

  return (
    <Stack
      width="100%"
      height="138px"
      borderRadius="5px"
      padding="8px 16px"
      boxShadow={3}
      onClick={onClick}
    >
      <Stack height="100%" gap={1}>
        <Typography
          variant="subtitle2"
          fontWeight={700}
          color="neutral700.main"
        >
          {useFormatter.formatNameDisplay(
            findOrganization?.resource?.alias?.[0] ||
              findOrganization?.resource?.name ||
              "-"
          )}
        </Typography>

        <Stack
          direction="column"
          alignContent="center"
          width="100%"
          spacing={1}
        >
          {!isVirtualService(location) ? (
            <Stack direction="row" justifyContent="center" spacing={2}>
              <Place fontSize="small" sx={{ marginLeft: "-3px" }} />
              <Typography
                display="flex"
                alignItems="center"
                variant="body1"
                color="neutral700.main"
              >
                {location?.address?.line?.join(", ")}
              </Typography>
            </Stack>
          ) : (
            <>
              <Stack direction="row">
                <VideoCameraFront sx={{ mr: 2 }} />
                <Typography variant="body1" color="neutral700.main">
                  Remoto
                </Typography>
              </Stack>
            </>
          )}
          {(locationContact || organizationContact) && (
            <Stack width="100%" direction="row" alignItems="start" gap="20px">
              <Phone />
              <Typography
                variant="body1"
                color="neutral1000.main"
                flex={1}
                fontWeight={500}
                sx={{
                  wordBreak: "break-word",
                  textTransform: "capitalize",
                }}
              >
                {locationContact || organizationContact}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
