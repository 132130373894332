import {
  Avatar,
  Box,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useFormatter } from "../../../utils/useFormatter";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useNavigate } from "react-router-dom";
import { FormButtonGroup } from "../../../components/form";
import { useContractContext } from "../../../contexts/ContractContext";
import { PrimaryButton } from "../../../components/common";
import { Close, Warning } from "@mui/icons-material";

export function ModalSchedule({ handleClose, open }: any) {
  const { user, loading, access } = useSessionContext();
  const { currentContract } = useContractContext();

  const navigate = useNavigate();

  const validateContractAndPaper =
    !currentContract && access?.roles?.includes("owner");

  const messageWarning = !currentContract && !access?.roles?.includes("owner");

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          height: "75vh",
          background: `linear-gradient(${theme.palette.primary700.main} 20%, ${theme.palette.neutral0.main} 38%)`,
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        })}
      >
        <Stack
          direction="column"
          justifyContent="space-around"
          alignItems="center"
          height="90%"
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", right: 5, top: 5 }}
          >
            <Close />
          </IconButton>
          <Stack alignItems="center" spacing={3}>
            <Avatar
              sx={{
                width: 90,
                height: 90,
                backgroundColor: "primary100.main",
                color: "primary900.main",
              }}
            >
              <Typography variant="h3" fontWeight={600}>
                {user?.name?.slice(0, 1)}
              </Typography>
            </Avatar>

            {validateContractAndPaper ? (
              <Typography variant="h5" fontWeight={600}>
                Você não possui um contrato ativo
              </Typography>
            ) : (
              <Typography variant="h5" fontWeight={600}>
                Bem vindo ao Fluxmed,{" "}
                {useFormatter.formatNameDisplay(user?.givenName)}!
              </Typography>
            )}
          </Stack>
          <Stack alignItems="center" spacing={3} width="80%">
            {validateContractAndPaper ? (
              <Typography variant="h6" fontWeight={600} textAlign="center">
                Acesse as configurações da clínica e renove a assinatura do seu
                contrato.
              </Typography>
            ) : (
              <Typography variant="h5" fontWeight={600}>
                Crie sua primeira agenda e inicie sua jornada.
              </Typography>
            )}

            {messageWarning && (
              <Stack
                direction="row"
                alignItems="center"
                border="1px solid"
                borderColor="neutral700.main"
                borderRadius={2}
                spacing={2}
                p={2}
              >
                <Warning sx={{ color: "warning500.main" }} />
                <Typography
                  variant="body1"
                  color="neutral700.main"
                  fontWeight={600}
                >
                  Nenhuma assinatura encontrada, solicite ao responsável da clínica e
                  renove o plano para criar novas agendas!
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack
            width="80%"
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            {validateContractAndPaper ? (
              <PrimaryButton
                width="40%"
                height="42px"
                onClick={() => navigate("/clinic")}
              >
                Renovar contrato
              </PrimaryButton>
            ) : (
              <FormButtonGroup
                onGoBackButtonClick={() => {
                  localStorage.setItem("scheduleCreated", "true");
                  handleClose();
                }}
                onNextButtonClick={() => navigate("/newschedule")}
                goBackButtonText="Fechar"
                nextButtonText="Criar Agenda"
                loading={loading}
                disabled={!currentContract}
              />
            )}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}
