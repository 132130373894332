import React, { useState } from "react";
import { CommonDialog } from "../../../../components/common/CommonDialog";
import { DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import {
  FormButtonGroup,
  FormRadioButton,
  FormTextInput,
} from "../../../../components/form";
import { useForm } from "react-hook-form";
import { useFormatter } from "../../../../utils/useFormatter";
import { useRegex } from "../../../../utils/useRegex";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { endpoints } from "../../../../configs/Settings";
import { useConsentContext } from "../../../../contexts/ConsentContext";
import { Warning } from "@mui/icons-material";
import { useInternationalVerify } from "../../../../utils/useInternationalVerify";
import { isMobile } from "react-device-detect";
import { InternationalPhone } from "../../../../components/common/InternationalPhone";

interface IProps {
  handleClose: any;
  isOpen: any;
}
export function CreatePersonDialog({ handleClose, isOpen }: IProps) {
  const { setModalCreatePerson } = useConsentContext();
  const { user } = useSessionContext();
  const [loading, setLoading] = useState<boolean>(false);
  const { formatPhoneNumber, formatCPF } = useFormatter;
  const { phoneNumber, cpf } = useRegex;

  const optionsDurationDefault = [
    { display: "Masculino", value: "male" },
    { display: "Feminino", value: "female" },
  ];

  const methods = useForm();
  const { handleSubmit, control } = methods;

  const sendMessage = (data: any) => {
    const lembreteMessage = `Esta é uma mensagem gerada no Fluxmed! ${user.name} quer compartilhar informações de saude com você. Acesse ${endpoints?.XDSB_domain}, faça seu cadastro de primeiro acesso, e avise ao ${user.name} que você realizou seu cadastro, e informe seu CPF.`;

    const url = isMobile
      ? `whatsapp://send?phone=/${useInternationalVerify.verifyPhoneDDI(
          data?.phone
        )}&text=${lembreteMessage}`
      : `https://web.whatsapp.com/send?phone=+${useInternationalVerify.verifyPhoneDDI(
          data?.phone
        )}&text=${lembreteMessage}`;

    return window.open(url, "_blank");
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      console.log("data:", data);
      sendMessage(data);
      setModalCreatePerson(false);
    } catch (err) {
      console.log("err: ", err);
    }
    setLoading(false);
  };

  return (
    <CommonDialog handleClose={handleClose} isOpen={isOpen} mobileView={isMobile}>
      <DialogTitle textAlign="center">
        Enviar aviso de consentimento
      </DialogTitle>
      <DialogContent>
        <Stack
          width="100%"
          direction="column"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography variant="body1" fontWeight={400}>
              Informe o telefone da pessoa que você quer realizar o
              compartilhamento.
            </Typography>
            <InternationalPhone
              name="phone"
              control={control}
              label="Telefone"
              required={true}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <Warning sx={{ color: "warning500.main" }} />
            <Typography variant="body1" fontWeight={400}>
              Envio de aviso de consentimento em ações de saúde. Você será
              redirecionado para o whatsapp web para envio da mensagem.
            </Typography>
          </Stack>

          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleSubmit(onSubmit)}
            goBackButtonText="Cancelar"
            nextButtonText="Confirmar"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
