import { useState } from "react";
import { PrivateMainLayout } from "../../components/layout";
import { useSessionContext } from "../../contexts/SessionContext";
import { LoadingContent } from "../home/components";
import { EditableCardRow, LargeContentBox } from "../../components/common";
import EditEmailDialog from "./components/EditEmailDialog";
import { EditPhoneDialog } from "./components/EditPhoneDialog";
import { EditPasswordDialog } from "./components/EditPasswordDialog";
import EditPractitionerRoleDialog from "./components/EditPractitionerRoleDialog";
import { Stack, Typography } from "@mui/material";
import { CardInfo } from "./components/CardInfo";
import { CardContact } from "./components/CardContact";
import { CardProfessional } from "./components/CardProfessional";
import { CardPassword } from "./components/CardPassword";
import { isMobile } from "react-device-detect";
import { CardAddress } from "./components/CardAddress";
import { TermOfUseDialog } from "../TermsOfUse/TermOfUseDialog";
import { useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { InactivateAccount } from "./components/InactivateAccount/InactivateAccount";

export function Profile() {
  const {
    roles,
    access,
    setIsOpenTermDialog,
    isOpenTermDialog,
    setTerminateAccount,
    inviteDialog,
    loading,
  } = useSessionContext();

  const [isEditEmailOpen, setIsEditEmailOpen] = useState<boolean>(false);
  const [isEditPhoneOpen, setIsEditPhoneOpen] = useState<boolean>(false);
  const [isEditPasswordOpen, setIsEditPasswordOpen] = useState<boolean>(false);
  const [isEditPracititionerRolesOpen, setIsEditPracititionerRolesOpen] =
    useState<boolean>(false);
  const [selectedPractitionerRole, setSelectPractitionerRole] = useState<any>();
  const [inactivateAccount, setInactivateAccount] = useState<boolean>(false);

  const navigate = useNavigate();

  if (loading || inviteDialog) {
    return <LoadingContent />;
  }
  return (
    <PrivateMainLayout noSidebar={isMobile} mobileView={isMobile}>
      <LargeContentBox>
        <EditEmailDialog
          isOpen={isEditEmailOpen}
          setIsOpen={setIsEditEmailOpen}
        />
        <EditPhoneDialog
          isOpen={isEditPhoneOpen}
          setIsOpen={setIsEditPhoneOpen}
        />
        <EditPasswordDialog
          isOpen={isEditPasswordOpen}
          setIsOpen={setIsEditPasswordOpen}
        />

        {selectedPractitionerRole?.roles !== undefined && (
          <EditPractitionerRoleDialog
            isOpen={isEditPracititionerRolesOpen}
            setSelectedRole={setSelectPractitionerRole}
            seletedRole={selectedPractitionerRole}
            setIsOpen={setIsEditPracititionerRolesOpen}
          />
        )}

        <Stack
          spacing={3}
          width="100%"
          alignItems={{ xs: "flex-start", sm: "center" }}
        >
          <Typography variant="h5" fontWeight="500">
            Informações pessoais
          </Typography>
          <Typography variant="body1" color="neutral700.main">
            Informações pessoais e opções de gerenciamento do seu perfil nos
            serviços Fluxmed
          </Typography>

          <CardInfo />

          <CardAddress />

          <CardContact
            setIsEditEmailOpen={setIsEditEmailOpen}
            setIsEditPhoneOpen={setIsEditPhoneOpen}
          />
          {roles?.find(
            (el: any) =>
              el.isPractitioner &&
              el?.organization?.reference?.split("-")?.[1] ===
                access?.organizationId
          ) && (
            <CardProfessional
              setSelectPractitionerRole={setSelectPractitionerRole}
              setIsEditPracititionerRolesOpen={setIsEditPracititionerRolesOpen}
            />
          )}

          <CardPassword onClick={() => setIsEditPasswordOpen(true)} />
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          >
            <Stack
              sx={{
                border: "1px solid",
                borderColor: "neutral700.main",
                borderRadius: "15px",
              }}
              width="100%"
              paddingTop={2}
            >
              <Typography
                variant="h5"
                fontWeight="500"
                paddingBottom={3}
                paddingLeft={5}
              >
                Informações adicionais
              </Typography>
              <EditableCardRow
                title="Cancelamento de conta"
                titleContext={""}
                auxText="Cancelar minha conta"
                auxIcon={<Logout />}
                colorButton="error700.main"
                onClick={() => setInactivateAccount(true)}
              />
              <EditableCardRow
                title="Termos de uso"
                titleContext={""}
                auxText="Visualizar termos de uso"
                onClick={() => setIsOpenTermDialog(true)}
              />
            </Stack>
          </Stack>
        </Stack>
        {isOpenTermDialog && <TermOfUseDialog isOpen={isOpenTermDialog} />}
        {inactivateAccount && (
          <InactivateAccount
            isOpen={inactivateAccount}
            handleClose={() => setInactivateAccount(false)}
            onClick={() => {
              setTerminateAccount(true);
              navigate("/terms");
            }}
          />
        )}
      </LargeContentBox>
    </PrivateMainLayout>
  );
}
