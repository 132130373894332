import { toast } from "react-toastify";
import { endpoints, oids } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

type AppointmentStatusType =
  | "proposed"
  | "pending"
  | "booked"
  | "arrived"
  | "fulfilled"
  | "cancelled"
  | "noshow"
  | "entered-in-error"
  | "checked-in"
  | "waitlist";

interface IProps {
  id?: number;
  _id?: number;
  count?: number;
  actor?: string;
  professionalActor?: string;
  includePatient?: boolean;
  includeLocation?: boolean;
  includeSlot?: boolean;
  includeSchedule?: boolean;
  schedule?: string;
  organization?: string;
  startDate?: string;
  endDate?: string;
  purposeofuse?: string;
  sort?: string;
  status?: AppointmentStatusType[];
  scheduleStatus?: "true" | "false";
  scheduling?: boolean;
  ehrrunner?: string;
}

/**
 * @param id number
 * @param _id number
 * @param count number
 * @param actor string
 * @param professionalActor string
 * @param includePatient boolean
 * @param includeLocation boolean
 * @param includeSlot boolean
 * @param includeSchedule boolean
 * @param schedule number
 * @param organization string, cnpj
 * @param startDate YYYY-MM-DD, inclusive
 * @param endDate YYYY-MM-DD, inclusive
 * @param sort slot field, to sort in reverse use the character -, e.g. -start
 * @param status busy | free | busy-unavailable | busy-tentative | entered-in-error
 * @param scheduleStatus true | false
 * @returns Bundle
 */
export async function GetAppointment({
  _id,
  id,
  count,
  actor,
  purposeofuse,
  professionalActor,
  includeLocation,
  includePatient,
  includeSlot,
  includeSchedule,
  schedule,
  organization,
  startDate,
  endDate,
  sort,
  status,
  scheduleStatus,
  scheduling,
  ehrrunner,
}: IProps) {
  let url = `${endpoints.SCHEDULING}/Appointment`;
  if (ehrrunner)
    url = `${endpoints.MHD}/Appointment?patient.identifier=urn:oid:${oids.cpf}%7C${ehrrunner}`;
  if (id) url = `${url}/${id}`;
  if (_id) url = `${url}${ehrrunner ? "&" : "?"}_id=${_id}`;
  else if (count) url = `${url}?_count=${count}`;
  else url = `${url}${ehrrunner ? `&_count=250` : "?_count=250"}`;
  if (actor) url = `${url}&actor=${actor}`;
  if (professionalActor) url = `${url}&actor=${professionalActor}`;
  if (includeLocation) url = `${url}&_include=Appointment:location`;
  if (includePatient) url = `${url}&_include=Appointment:patient`;
  if (includeSlot) url = `${url}&_include=Appointment:slot`;
  if (includeSchedule) url = `${url}&_include:iterate=Slot:schedule`;
  if (schedule) url = `${url}&slot.schedule=${schedule}`;
  if (organization)
    url = `${url}&actor.organization=${oids.cnpj}-${organization}`;
  if (startDate) url = `${url}&date=ge${startDate}`;
  if (endDate) url = `${url}&date=le${endDate}`;
  if (sort) url = `${url}&_sort=${sort}`;
  if (status) url = `${url}&status=${status.join(",")}`;
  if (scheduleStatus) url = `${url}&slot.schedule.active=${scheduleStatus}`;

  const subjectId = await useAuth.getSubjectId();
  const organizationId = await useAuth.getOrganizationId();

  const headers = {
    Accept: "application/fhir+json",
    Authorization: await useAuth.getUserToken(),
    "Content-Type": "application/fhir+json",
    ...(subjectId && { "subject-id": subjectId }),
    ...(organizationId && { "organization-id": organizationId }),
    ...(purposeofuse && { purposeofuse: purposeofuse }),
  };

  const response = await useAxios
    .get(url, { headers, timeout: 20000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.Success) {
        throw new Error(`Get Appointment: ${response.status}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error("GetAppointment ", error);
      // toast.error(`GetAppointment Error: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
