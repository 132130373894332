import { useState } from "react";
import { toast } from "react-toastify";
import { useForm, useWatch } from "react-hook-form";
import { DialogContent, DialogTitle, Stack } from "@mui/material";

import { CommonDialog } from "../../../../components/common/CommonDialog";
import { FormButtonGroup } from "../../../../components/form";
import { useConsentContext } from "../../../../contexts/ConsentContext";
import { NewConsentTabs } from "./NewConsentTabs";
import { GetPatient } from "../../../../services/fhir";
import { HTTPResponseStatus } from "../../../../utils/enum";
import { useOrganizationContext } from "../../../../contexts/OrganizationContext";
import { isMobile } from "react-device-detect";

interface IProps {
  defaultTab: any;
  noIdentifier?: any;
}

export function NewConsentDialog({ defaultTab, noIdentifier }: IProps) {
  const {
    newConsentDialog,
    setNewConsentDialog,
    setConfirmConsentDialog,
    resetStates,
    setSubjectName,
    setSubjectId,
    setModalCreatePerson,
    setConsentType,
    setVigency,
  } = useConsentContext();

  const { organizationList } = useOrganizationContext();

  const defaultValues = { identifier: "", organization: "", vigency: 30 };

  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = methods;

  let cpfValue = useWatch({ name: "identifier", control });
  let selectedOrganization = useWatch({ name: "organization", control });

  const [loading, setLoading] = useState<boolean>(false);

  const handleClose = () => {
    setSelectedTab("1");
    reset(defaultValues);
    setNewConsentDialog(false);
    resetStates();
  };

  const [selectedTab, setSelectedTab] = useState(`${defaultTab}`);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    reset(defaultValues);
    setSelectedTab(newValue);
  };

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      if (selectedTab === "1") {
        const response = await GetPatient({
          identifier: data.identifier.replace(/[.-]/g, ""),
        });
        
        if (response?.status !== HTTPResponseStatus.Success)
          throw new Error("Patient");
        else setSubjectName(response?.data?.name?.[0]?.text);
        setSubjectId(data?.identifier?.replace(/[.-]/g, ""));
        setConsentType("cpf");
      } else {
        const findOrganization = organizationList?.find(
          (el: any) => el?.resource?.id === data?.organization
        );
        if (!findOrganization) throw new Error("Organization");
        setSubjectName(findOrganization?.resource?.alias?.[0] || "");
        setSubjectId(findOrganization?.resource?.id || "");
        setConsentType("cnpj");
      }
      setVigency(data?.vigency);
      setNewConsentDialog(false);
      setConfirmConsentDialog(true);
    } catch (err) {
      setNewConsentDialog(false);
      setModalCreatePerson(true);
      console.log("err", err);
      toast.warning(
        "Usuário não encontrado, verifique os dados e tente novamente."
      );
    } finally {
      reset(defaultValues);
      setSelectedTab("1");
      setLoading(false);
      return;
    }
  };

  return (
    <CommonDialog
      handleClose={handleClose}
      isOpen={newConsentDialog}
      mobileView={isMobile}
    >
      <DialogTitle textAlign="center">Novo consentimento</DialogTitle>
      <DialogContent>
        <NewConsentTabs
          control={control}
          selectedTab={selectedTab}
          handleChangeTab={handleChangeTab}
          noIdentifier={noIdentifier}
        />

        <Stack width="100%" direction="row" justifyContent="space-between">
          <FormButtonGroup
            onGoBackButtonClick={handleClose}
            onNextButtonClick={handleSubmit(onSubmit)}
            disabled={
              selectedTab === "1"
                ? !cpfValue || !isValid
                : !selectedOrganization
            }
            goBackButtonText="Cancelar"
            nextButtonText="Prosseguir"
            loading={loading}
          />
        </Stack>
      </DialogContent>
    </CommonDialog>
  );
}
