import { useEffect, useState } from "react";
import { GetConsents } from "../../../../services/consent";
import { useSessionContext } from "../../../../contexts/SessionContext";
import { useConsentContext } from "../../../../contexts/ConsentContext";
import { usePrivacyContext } from "../../../../contexts/PrivacyContext";
import { useFormatter } from "../../../../utils/useFormatter";
import { useOrganizationContext } from "../../../../contexts/OrganizationContext";
import { useDatetime } from "../../../../utils/useDatetime";
import dayjs from "dayjs";
import { BundlePatientSummary } from "../../../../services/fhir";
import { useDict } from "../../../../utils/useDict";
import { GenericTable } from "../../../../components/GenericTable/GenericTable";
import { ConsentActions } from "../ConsentActions";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { Delete, Warning } from "@mui/icons-material";
import { RevokeConsentDialog } from "../RevokeConsentDialog";
import { switchCase } from "../../../../utils/helper";

export default function RevokeConsents() {
  const { user } = useSessionContext();

  const {
    setRevokeDialog,
    setSubjectName,
    setSubjectId,
    setRenewDialog,
    setUUID,
    setNewConsentDialog,
    resetStates,
    newConsentDialog,
    confirmConsentDialog,
    revokeDialog,
    renewDialog,
    setConsentType,
  } = useConsentContext();

  const { formatNameDisplay } = useFormatter;

  const { isSharing } = usePrivacyContext();
  const { organizationList } = useOrganizationContext();

  const { formatToDDMMYYYY, formatToHHmm } = useDatetime;
  const [searchField, setSearchField] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [granted, setGranted] = useState<any>([]);

  const [refetch, setRefetch] = useState<boolean>(false);

  function formatConsent(identifier: any, name: any, el: any) {
    const isValid =
      dayjs(el?.vigencia?.split("T")?.[0]).isAfter(dayjs()) || !el?.vigencia;

    return {
      identifier: identifier || "-",
      name: formatNameDisplay(name || identifier) || "-",
      subject: el?.sujeito?.id,
      date: dayjs(el?.hora).isValid() ? formatToDDMMYYYY(dayjs(el.hora)) : "-",
      hour: dayjs(el?.hora).isValid() ? formatToHHmm(dayjs(el.hora)) : "-",
      fullDate: el.hora,
      vigency: dayjs(el?.vigencia).isValid()
        ? formatToDDMMYYYY(dayjs(el.vigencia))
        : null,
      isValid: isValid,
      motivo: el?.contexto?.motivoAcesso,
      status: isValid ? "valid" : "expired",
      binaryUrl: el?.binaryUrl,
    };
  }
  useEffect(() => {
    resetStates();
    async function fetchData() {
      setLoading(true);

      try {
        const grantedResponse = await GetConsents({
          identifier: user.username,
          type: "granted",
        });

        const activeConsents = grantedResponse?.data?.filter(
          (el: any) => el?.acao === "Autorizar" && el?.status === "valid" && el
        );

        const auxIndividual =
          activeConsents
            ?.filter(
              (el: any) =>
                !el.contexto.instituicao &&
                el?.sujeito?.id !== user.username &&
                el?.sujeito?.nome
            )
            ?.map((el: any) =>
              formatConsent(el?.sujeito?.id, el?.sujeito?.nome, el)
            ) || [];

        setGranted([auxIndividual]);
      } catch (err) {
        console.log("Fetch consents", err);
      } finally {
        setLoading(false);
      }
    }

    if (isSharing) fetchData();
  }, [isSharing, user.username, refetch]);

  const columns = [
    {
      column: "CPF",
      rowName: "identifier",
    },
    { column: "Nome", rowName: "name" },

    { column: "Data", rowName: "date" },
    { column: "Hora", rowName: "hour" },
    { column: "Validade", rowName: "vigency" },
  ];
  const data = granted[0]?.sort((a: any, b: any) => {
    const dateA = dayjs(a?.fullDate);
    const dateB = dayjs(b?.fullDate);
    return dateB.diff(dateA);
  });
  return (
    <>
      <Typography
        variant="body1"
        textAlign={"left"}
        fontWeight={50}
        sx={{ fontSize: 16, marginBottom: 0 }}
      >
        <strong> {data?.length || "Nenhum"} </strong>
        {/* {switchCase(data?.length, {
          "data?.length < 1":
            "consentimentos concedidos aos seus dados de saúde, você pode revogar se desejar!",
          1: "consentimento concedido aos seus dados de saúde, você pode revogar se desejar!",
          default: "consentimento concedido aos seus dados de saúde!",
        })} */}
        {data?.length > 1
          ? "consentimentos concedidos aos seus dados de saúde, você pode revogar se desejar!"
          : data?.length === 1
          ? "consentimento concedido aos seus dados de saúde, você pode revogar se desejar!"
          : " consentimento concedido aos seus dados de saúde! "}
      </Typography>
      {/* <Stack direction="column" gap={2} width={"100%"}>
        {data
          ?.filter((consent: any) => consent?.status === "valid" && consent)
          ?.slice(0, 5)
          ?.map((el: any) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                padding: 2,
                border: "1px solid ",
                borderColor: "neutral300.main",
                borderRadius: 2,
                backgroundColor: "primary100.main",
                boxShadow: " 0px 1px 16px 1px rgba(0, 0, 0, 0.1)",
                cursor: "pointer",
              }}
              width={"100%"}
            >
              <div>
                <span style={{ fontWeight: 500 }}>Nome</span>
                <Typography>{el?.name}</Typography>
              </div>
              <div>
                <span style={{ fontWeight: 500 }}>Validade</span>
                <Typography variant="body2" textAlign={"center"}>
                  {el?.vigency || "-"}
                </Typography>
              </div>
              <div>
                <span style={{ fontWeight: 500 }}>Motivo</span>
                <Typography>{el?.motivo || "-"}</Typography>
              </div>
              <Tooltip title={data.isValid ? "Revogar" : "Renovar"}>
                <IconButton
                  onClick={() => {
                    setSubjectId(el?.identifier);
                    setSubjectName(el?.name);
                    setUUID(el?.subject);
                    if ((el.isValid && el.vigency) || !el.vigency)
                      setRevokeDialog(true);
                    else setRenewDialog(true);
                  }}
                >
                  {data.vigency ? (
                    data.isValid ? (
                      <Delete sx={{ color: "error600.main" }} />
                    ) : (
                      <Warning sx={{ color: "warning500.main" }} />
                    )
                  ) : (
                    <Delete sx={{ color: "error600.main" }} />
                  )}
                </IconButton>
              </Tooltip>
            </Stack>
          ))}
      </Stack> */}
      <Stack>
        {data?.length ? (
          <GenericTable
            tableColumns={columns}
            tableRows={data?.filter(
              (consent: any) => consent?.status === "valid" && consent
            )}
            actions={(rowData: any) => (
              <ConsentActions row={rowData} type={"granted"} />
            )}
          />
        ) : (
          <></>
        )}
      </Stack>

      <RevokeConsentDialog setRefetch={setRefetch} />
    </>
  );
}
