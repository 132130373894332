import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";

import { PrimaryButton } from "../common";
import { useSessionContext } from "../../contexts/SessionContext";
import { useViewContext } from "../../contexts/ViewContext";
import { useOrganizationContext } from "../../contexts/OrganizationContext";

export default function ViewDialog() {
  const { setAccess, roles, inviteDialog, terminateAccount } =
    useSessionContext();
  const { organizationList } = useOrganizationContext();

  const { viewDialogIsOpen, setViewDialogIsOpen } = useViewContext();

  function handleClose(event: any, reason: any) {
    if (reason && reason === "backdropClick") return;
    setViewDialogIsOpen(false);
  }

  function getRoles(data: any) {
    return [
      ...(data?.isManager ? ["manager"] : []),
      ...(data?.isPractitioner ? ["practitioner"] : []),
      ...(data?.isOwner ? ["owner"] : []),
      ...(data?.isInvited ? ["invited"] : []),
    ];
  }

  function handleProfessionalButton(organizationId: any, data: any) {
    localStorage.removeItem("warningsClosedOnce");
    setAccess({
      type: "professional",
      organizationId: organizationId,
      roles: getRoles(data),
    });
    localStorage.setItem("organizationId", organizationId);
    setViewDialogIsOpen(false);
  }

  async function handlePatientButton() {
    localStorage.removeItem("warningsClosedOnce");
    setAccess({ type: "patient" });
    setViewDialogIsOpen(false);
  }

  return (
    <Dialog
      onClose={handleClose}
      open={viewDialogIsOpen && !inviteDialog}
      maxWidth="sm"
      PaperProps={{
        sx: { borderRadius: "16px", paddingY: 4 },
      }}
    >
      <DialogTitle textAlign="center" mt={2}>
        Qual clínica deseja consultar?
      </DialogTitle>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          width="600px"
        >
          {roles
            ?.filter((el: any) => !el?.isInvited)
            ?.map((item) => {
              const auxOrganization = organizationList?.find(
                (e) =>
                  e?.resource?.id ===
                  item?.organization?.reference?.split("/")[1]
              );

              return (
                <Grid
                  item
                  xs={5}
                  key={`view-role-${
                    item.organization?.reference?.split("-")?.[1]
                  }`}
                >
                  <PrimaryButton
                    height="68px"
                    width="100%"
                    onClick={() =>
                      handleProfessionalButton(
                        item.organization?.reference?.split("-")?.[1],
                        item
                      )
                    }
                  >
                    {auxOrganization?.resource?.alias?.[0] !== "null"
                      ? auxOrganization?.resource?.alias?.[0]
                      : auxOrganization?.resource?.name ||
                        item?.organization?.display ||
                        "-"}
                  </PrimaryButton>
                </Grid>
              );
            })}
        </Grid>
      </DialogActions>

      <Typography
        variant="body1"
        textAlign="center"
        alignItems="baseline"
        mt={2}
        component="div"
      >
        Abrir
        <Button
          sx={{
            textTransform: "none",
            display: "inline",
            color: "primary",
          }}
          onClick={() => handlePatientButton()}
        >
          Visão do Paciente
        </Button>
      </Typography>
    </Dialog>
  );
}
