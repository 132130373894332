import { House, Share, Assignment, Campaign } from "@mui/icons-material";
import { Badge, Box, IconButton, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useWarningCentralContext } from "../../../../contexts/WarningCentralContext";

const IconButtonCustom = styled(IconButton)(({ theme }) => ({
  color: theme.palette.neutral0.main,
}));

export function MobileBarNavigation() {
  const navigate = useNavigate();
  const { handleOpen, warnings } = useWarningCentralContext();
  return (
    <Box
      width="100%"
      height="6vh"
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      sx={{ backgroundColor: "primary700.main" }}
      position="fixed"
      bottom={0}
      zIndex={1}
    >
      <IconButtonCustom onClick={() => navigate("/home")}>
        <House />
      </IconButtonCustom>
      <IconButtonCustom onClick={() => navigate("/myrecords")}>
        <Assignment />
      </IconButtonCustom>

      <IconButtonCustom onClick={() => navigate("/consent")}>
        <Share />
      </IconButtonCustom>
      <IconButtonCustom onClick={handleOpen}>
        <Badge badgeContent={warnings?.length} color="error">
          <Campaign sx={{ color: "white", fontSize: "28px" }} />
        </Badge>
      </IconButtonCustom>
    </Box>
  );
}
