import { Pages } from "@mui/icons-material";
import { IFeatures } from "../../utils/interfaces";

import { currentFeatures } from "../../configs/Settings";
import TermMain from "./TermMain";

export function useTerm() {
  const configs: IFeatures = {
    initial: false,
    active: currentFeatures.home,
    path: "/terms",
    text: "Termos de Uso",
    icon: Pages,
    sideMenu: false,
    sideMenuIndex: 0,

    component: () => <TermMain />,
  };

  return configs;
}
