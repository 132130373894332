import { ArrowForward } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { isValidElement } from "react";

interface IEditableCardRow {
  title: string;
  titleContext: any;
  onClick(): any;
  type?: "edit" | "add" | "details";
  auxText?: string;
  auxIcon?: any;
  colorButton?: string;
}

function typeCard(
  type: "edit" | "add" | "details" | undefined,
  auxText: string | undefined
) {
  if (auxText) {
    return auxText;
  }
  if (type === "add") {
    return "Adicionar";
  } else if (type === "details") {
    return "Detalhes";
  } else {
    return "Editar";
  }
}

export function EditableCardRow({
  title,
  titleContext,
  onClick,
  type,
  colorButton,
  auxText,
  auxIcon,
}: IEditableCardRow) {
  return (
    <Button
      sx={{
        textTransform: "none",
        paddingY: 3,
        paddingLeft: 5,
        paddingRight: 3,
      }}
      onClick={onClick}
    >
      <Stack direction="row" width="100%" alignItems="start">
        <Typography
          variant="body1"
          color="neutral700.main"
          width="20%"
          textAlign="left"
          sx={{ wordBreak: "break-word" }}
        >
          {title}:
        </Typography>
        <Typography
          variant="body1"
          color="neutral1000.main"
          width="60%"
          textAlign="left"
          fontWeight={500}
          pl={1}
          sx={{ wordBreak: "break-word" }}
        >
          {isValidElement(titleContext) ? titleContext : titleContext}
        </Typography>
        <Typography
          width="20%"
          variant="body1"
          color={colorButton && colorButton}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            gap: "8px",
          }}
        >
          {typeCard(type, auxText)}
          {auxIcon ? auxIcon : <ArrowForward fontSize="small" />}
        </Typography>
      </Stack>
    </Button>
  );
}
