import { Stack } from "@mui/material";
import { PrimaryButton, SecondaryButton } from "../../../components/common";
import { useContractContext } from "../../../contexts/ContractContext";

export function SubmitActions({ goBack, goForward, loading }: any) {
  const { currentContract } = useContractContext();
  return (
    <Stack
      width="100%"
      direction={!goBack ? "row-reverse" : "row"}
      justifyContent="space-between"
    >
      {goBack && (
        <SecondaryButton width="180px" height="50px" onClick={goBack}>
          Voltar
        </SecondaryButton>
      )}

      <PrimaryButton
        width="180px"
        height="50px"
        loading={loading}
        onClick={goForward}
        disabled={!currentContract}
      >
        Salvar
      </PrimaryButton>
    </Stack>
  );
}
