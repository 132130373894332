import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { GetBinary } from "../../../services/fhir/patientsummary/Binary/GetBinary";
import { CircularLoading } from "../../../components/common";
import { isMobile } from "react-device-detect";
import { Close, Info, Share } from "@mui/icons-material";
import { ScreenMobile } from "../../../components/common/ScreenMobile";
import { NewConsentDialog } from "../../consents/components/NewConsentDialog";
import { ConfirmConsentDialog } from "../../consents/components/ConfirmConsentDialog";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useConsentContext } from "../../../contexts/ConsentContext";
import { usePrivacyContext } from "../../../contexts/PrivacyContext";
import { FormButtonGroup } from "../../../components/form";
import { GetDocumentReferenceById } from "../../../services/fhir/documentreference/GetDocumentReferenceById";
import { PatchEHRPoster } from "../../../services/fhir/patientsummary/PatchEHRPoster";
import { toast } from "react-toastify";
import { CreatePersonDialog } from "../../consents/components/CreatePersonDialog";

interface IProps {
  handleClose: any;
  isOpen: any;
  data: any;
  title?: string;
  loadedBase64?: any;
  setLoaded?: any;
  setRefetchSummary?: any;
}

export function IframeDialog({
  handleClose,
  isOpen,
  data,
  title,
  loadedBase64,
  setLoaded,
  setRefetchSummary,
}: IProps) {
  const { access, user } = useSessionContext();
  const {
    setNewConsentDialog,
    newConsentDialog,
    confirmConsentDialog,
    modalCreatePerson,
    setModalCreatePerson,
  } = useConsentContext();
  const { isSharing } = usePrivacyContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [base64, setBase64] = useState<any>();
  const [refetch, setRefetch] = useState<boolean>(false);

  const [documentReference, setDocumentReference] = useState<any>();

  const base64ToBlob = (base64: any, contentType = "") => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const verifyDocSize = (base64String: any, limit: number) => {
    const sizeInBytes = (base64String.length * 3) / 4;

    const limitInBytes = limit * 1024 * 1024;

    return sizeInBytes > limitInBytes;
  };

  async function downloadBase64File(base64: any) {
    try {
      const blob = base64ToBlob(base64, "application/pdf");
      const fileUrl = URL.createObjectURL(blob);
      const auxFile = {
        fileUrl,
        fileName: "Solicitacoes_de_exames.pdf",
      };

      window.open(auxFile.fileUrl, "_blank");
      // setDataRow(e.row);
      // setOpenInformation(true);
    } catch (err) {
      console.log("err", err);
    }
  }

  async function removeResult() {
    await PatchEHRPoster({
      resourceType: "DocumentReference",
      resourceId: documentReference?.id || "",
      operation: "replace",
      path: "/status",
      value: "entered-in-error",
    });
    toast.success("Resultado removido com sucesso!");
    setRefetchSummary((prev: boolean) => !prev);
    handleClose();
  }

  useEffect(() => {
    async function getDocumentPdf() {
      const documentReference = await GetDocumentReferenceById({
        patient: user?.username,
        docId: data?.split("/")?.[1],
        isLogged: true,

        subjectId: user?.username,
      });
      setDocumentReference(documentReference?.entry?.[0]?.resource);

      const response = await GetBinary({ urlBinary: data });
      setBase64(response);
      if (setLoaded) {
        setLoaded(response);
      }
    }
    if (!loadedBase64) {
      getDocumentPdf();
    }
    setLoading(false);
  }, [data]);

  const handleDialogClose = (event: any, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose(event, reason);
    }
  };

  return isMobile ? (
    <>
      <ScreenMobile open={isOpen} onClose={handleClose} title="Documento">
        {(base64?.data && !loading) || loadedBase64?.data ? (
          <>
            {verifyDocSize(base64?.data || loadedBase64?.data, 1) ? (
              <Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Info sx={{ color: "#0000a7" }} />
                  <Typography textAlign="justify">
                    O arquivo selecionado excede o tamanho limite de
                    pre-visualização.
                  </Typography>
                  <Typography
                    onClick={() => {
                      downloadBase64File(base64?.data || loadedBase64?.data);
                    }}
                    variant={"body1"}
                    color="primary"
                    sx={{
                      "&:hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                      },
                    }}
                  >
                    Clique aqui para acessar
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              <iframe
                title="PDF Viewer"
                src={`data:${
                  base64?.contentType || loadedBase64?.contentType
                };base64,${base64?.data || loadedBase64?.data}`}
                width="100%"
                height={window.innerHeight - 200}
              />
            )}
          </>
        ) : (
          <CircularLoading />
        )}
      </ScreenMobile>
    </>
  ) : (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: 2,
          maxWidth: "850px",
          height: "850px",
        },
      }}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      onClose={handleDialogClose}
      open={isOpen}
    >
      {access?.type === "patient" && isSharing ? (
        <Stack direction="row" alignSelf="center">
          <IconButton
            onClick={() => setNewConsentDialog && setNewConsentDialog(true)}
            sx={{ position: "absolute", right: 48, top: 5 }}
          >
            <Share fontSize="medium" />
          </IconButton>
          <IconButton
            onClick={() => handleClose(null, "iconButtonClick")}
            sx={{ position: "absolute", right: 5, top: 5 }}
          >
            <Close />
          </IconButton>
        </Stack>
      ) : (
        <IconButton
          onClick={() => handleClose(null, "iconButtonClick")}
          sx={{ position: "absolute", right: 5, top: 5 }}
        >
          <Close />
        </IconButton>
      )}
      <DialogTitle textAlign="center">Documento</DialogTitle>

      {(base64?.data && !loading) || loadedBase64?.data ? (
        <>
          {verifyDocSize(base64?.data || loadedBase64?.data, 1) ? (
            <Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Info sx={{ color: "#0000a7" }} />
                <Typography textAlign="justify">
                  O arquivo selecionado excede o tamanho limite de
                  pre-visualização!
                </Typography>
                <Typography
                  onClick={() => {
                    downloadBase64File(base64?.data || loadedBase64?.data);
                  }}
                  variant={"body1"}
                  color="primary"
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                      cursor: "pointer",
                    },
                  }}
                >
                  Visualizar
                </Typography>
              </Stack>
            </Stack>
          ) : (
            <iframe
              title="PDF Viewer"
              src={`data:${
                base64?.contentType || loadedBase64?.contentType
              };base64,${base64?.data || loadedBase64?.data}`}
              width="100%"
              height={window.innerHeight - 200}
            />
          )}
        </>
      ) : (
        <CircularLoading />
      )}

      <DialogActions>
        {access?.type === "patient" &&
          documentReference?.category?.[0]?.coding?.[0]?.code ===
            "resultado" && (
            <FormButtonGroup
              onNextButtonClick={() => handleClose()}
              onGoBackButtonClick={async () => await removeResult()}
              nextButtonText="Fechar"
              goBackButtonText="Excluir arquivo"
            />
          )}
      </DialogActions>

      {newConsentDialog && (
        <NewConsentDialog defaultTab={"1"} noIdentifier={true} />
      )}
      {confirmConsentDialog && <ConfirmConsentDialog setRefetch={setRefetch} />}
      {modalCreatePerson && (
        <CreatePersonDialog
          isOpen={modalCreatePerson}
          handleClose={() => setModalCreatePerson(false)}
        />
      )}
    </Dialog>
  );
}
