import { Box, Chip, Pagination, Stack, Typography } from "@mui/material";
import { useFhirData } from "../../../utils/useFhirData";
import { useSessionContext } from "../../../contexts/SessionContext";
import { useState } from "react";
import { usePractitionerRoleContext } from "../../../contexts/PractitionerRoleContext";
import { InfoMessage } from "./BackupNotice";
import { useDict } from "../../../utils/useDict";

function maskCNPJ(cnpj: string): string {
  return cnpj
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1.$2")
    .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    .replace(/\.(\d{3})(\d)/, ".$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2");
}

export function PractitionerCancel({ isFiscalReponsive }: any) {
  const { rolesOrganization, practitionerRole } = usePractitionerRoleContext();

  function getTrueRoles(obj: Record<string, unknown>): string[] {
    return Object.entries(obj)
      .filter(([_, value]) => typeof value === "boolean" && value === true)
      .map(([key]) => key);
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(1);

  const filterOrganizations = rolesOrganization
    ?.map((e: any) => e?.resource)
    ?.filter((org: any) => {
      const verifyRoles = practitionerRole?.find((role: any) => {
        return (
          role?.resource?.organization?.reference?.includes(org?.id) &&
          (role?.resource?.active === undefined ||
            role?.resource?.active !== false)
        );
      });

      return verifyRoles;
    });

  const handleChangePage = (event: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  return (
    <Stack width="100%" spacing={2}>
      <Stack>
        <Typography variant="subtitle2" textAlign={"center"}>
          Identificamos que você faz parte das seguintes organizações:
        </Typography>
      </Stack>

      <Stack spacing={1} width={"100%"}>
        {filterOrganizations
          ?.slice((currentPage - 1) * itemsPerPage, currentPage)
          ?.map((el: any) => {
            const auxActivity = practitionerRole
              ?.find((e: any) =>
                e?.resource?.organization?.reference.includes(el?.id)
              )
              ?.resource?.code?.map(
                (role: any) => useDict.roles[role?.coding?.[0]?.system]
              );

            const brandLogoOrganization =
              useFhirData?.brandLogoOrganization(el?.extension) ?? "";

            return (
              <Stack
                direction={"row"}
                key={`view-role-${el?.reference?.split("-")?.[1]}`}
                sx={{
                  border: "1px solid",
                  borderColor: "neutral400.main",
                  borderRadius: "8px",
                  padding: "16px",
                }}
                spacing={4}
                width={"100%"}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "68px",
                    height: "68px",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    alt={el.alias?.[0]}
                    src={brandLogoOrganization}
                    style={{ objectFit: "contain" }}
                    width={"100%"}
                  />
                </Box>
                <Stack flex={1} spacing={1}>
                  <Stack direction="row" spacing={4} alignItems="center">
                    <Typography variant="subtitle2">
                      {el.alias?.[0] !== "null"
                        ? el.alias?.[0]
                        : el.name || el?.display || "-"}
                    </Typography>
                    <Typography variant="body2" fontStyle={"italic"}>
                      ({maskCNPJ(el?.identifier?.[0]?.value || "")})
                    </Typography>
                  </Stack>

                  <Typography variant="body1" fontWeight={400}>
                    {` ${el.contact?.[0]?.address?.state}-${
                      el.contact?.[0]?.address?.city
                    }, ${el.contact?.[0]?.address?.line?.join(", ")}   ` || "-"}
                  </Typography>
                  <Stack direction="row" alignItems={"center"} spacing={1}>
                    <Typography variant="body2" fontWeight={500}>
                      Atividades: {auxActivity?.join(", ")}
                    </Typography>
                    {/* {trueRoles.map((role) => (
                  <Chip
                    key={role}
                    variant="outlined"
                    size="small"
                    label={role}
                  />
                ))} */}
                  </Stack>
                </Stack>
                {/* <Stack direction="column" justifyContent={"center"}>
              <Tooltip title={"Cancelar assinatura"}>
                <IconButton
                  onClick={async () => {
                    await handleCancelPlan();
                  }}
                >
                  <Close sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </Stack> */}
              </Stack>
            );
          })}
        <Pagination
          count={Math.ceil(
            (filterOrganizations?.length || itemsPerPage) / itemsPerPage
          )}
          page={currentPage}
          onChange={handleChangePage}
          sx={{ mt: 2, display: "flex", justifyContent: "center" }}
          color="primary"
        />
      </Stack>

      <Stack width="100%" spacing={2}>
        <InfoMessage
          message={
            !isFiscalReponsive
              ? "Suas agendas serão apagadas juntamente com todas as consultas marcadas."
              : "Suas agendas serão apagadas."
          }
        />

        {!isFiscalReponsive && (
          <>
            <InfoMessage
              message={
                "Os seus dados pessoais serão organizados e disponibilizados para download após a confirmação. Esse processo pode levar algum tempo, dependendo do volume de informações associadas à sua conta."
              }
            />
            <InfoMessage
              message={"Você perderá o acesso a plataforma Fluxmed."}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
}
