import { ArrowBack, Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import TermOfOwner from "./components/TermOfOwner";
import { FormButtonGroup } from "../../components/form";
import { useNavigate } from "react-router-dom";
import TermOfPatient from "./components/TermOfPatient";
import { useForm } from "react-hook-form";
import { useSessionContext } from "../../contexts/SessionContext";
import { PutPerson } from "../../services/fhir/person/PutPerson";
import { useAuthContext } from "../../contexts/AuthContext";
import TermText from "./components/TermText";
import { isMobile } from "react-device-detect";

export function TermOfUseDialog({
  isOpen,
  setIsOpen,
  update,
}: {
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  update?: boolean;
}) {
  const navigate = useNavigate();
  const {
    user,
    roles,
    access,
    setIsOpenTermDialog,
    userNavigable,
    setTerminateAccount,
  } = useSessionContext();

  const verifyRoles = roles?.find((e: any) => e?.isOwner);
  const steps = ["Paciente", "Clínica"];
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const { logoutUser } = useAuthContext();

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      termOfPatient: false,
      termOfOwner: false,
    },
  });

  const TermOfPatientChecked = watch("termOfPatient");
  const TermOfOwnerChecked = watch("termOfOwner");

  const handleStepChange = (event: React.SyntheticEvent, newIndex: number) => {
    setActiveStep(newIndex);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    if (!update) setIsOpenTermDialog?.(false);
    else window.location.reload();
  };

  const onSubmit = async (data: any) => {
    data.identifier = user?.username || "";
    data.name = user?.name || "";
    data.birthDate = user?.birthDate || "";
    data.active = true;

    await PutPerson(data);
    handleClose();
    // navigate("/home", { replace: true });
  };

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: "10px",
            padding: 2,
            paddingX: { xs: 1, sm: 4, md: 8 },
            maxWidth: "1200px",
          },
        }}
        maxWidth="sm"
        fullScreen={isMobile}
        onClose={handleClose}
        open={isOpen}
      >
        {update ? (
          <>
            <IconButton
              onClick={() => handleBack()}
              sx={{
                position: "absolute",
                left: { xs: 0, lg: 12 },
                top: { xs: 2, lg: 12 },
              }}
              size="large"
              disabled={activeStep === 0}
              title="Voltar"
            >
              <ArrowBack />
            </IconButton>
          </>
        ) : (
          <IconButton
            onClick={() => handleClose()}
            sx={{ position: "absolute", right: 5, top: 5 }}
            size="large"
            title="Fechar"
          >
            <Close />
          </IconButton>
        )}

        <DialogTitle textAlign="center">
          {update
            ? "Atualização dos Termos de uso e Política de Privacidade"
            : "Termos de uso e Política de Privacidade"}
        </DialogTitle>

        {verifyRoles && update && (
          <Stepper
            activeStep={activeStep}
            style={{ padding: 8, paddingBottom: 16 }}
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        )}
        <DialogContent w-full>
          {!update && access?.type === "professional" && verifyRoles && (
            <Stack>
              <Tabs
                centered
                value={activeStep}
                onChange={handleStepChange}
                sx={{ minHeight: "auto" }}
              >
                <Tab label="Paciente" sx={{ minWidth: 100 }} />
                <Tab label="Clínica" sx={{ minWidth: 100 }} />
              </Tabs>
            </Stack>
          )}

          {!verifyRoles && (
            <>
              <TermText termOf="patient" />
            </>
          )}
          {verifyRoles && (
            <>
              {activeStep === 0 && (
                <>
                  <TermText termOf="patient" />
                </>
              )}
              {activeStep === 1 && (
                <>
                  <TermText termOf="owner" />
                </>
              )}
            </>
          )}
        </DialogContent>

        {update && (
          <DialogActions>
            {!verifyRoles ? (
              <Stack direction={"column"} width={"100%"}>
                <Divider sx={{ marginY: 2 }} />
                <TermOfPatient
                  control={control}
                  isAssigned={!TermOfPatientChecked}
                />
                <FormButtonGroup
                  onNextButtonClick={handleSubmit(onSubmit)}
                  onGoBackButtonClick={() => {
                    userNavigable && setTerminateAccount(true);
                    setIsOpenTermDialog(true);
                    navigate("/home");
                  }}
                  nextButtonText={"Confirmar"}
                  goBackButtonText={"Recusar"}
                  disabled={!TermOfPatientChecked}
                  disabledBackButton={TermOfPatientChecked}
                />
              </Stack>
            ) : (
              <>
                {activeStep === 0 && (
                  <Stack direction={"column"} width={"100%"}>
                    <Divider sx={{ marginY: 2 }} />
                    <TermOfPatient
                      control={control}
                      isAssigned={!TermOfPatientChecked}
                    />
                    <FormButtonGroup
                      onNextButtonClick={handleNext}
                      onGoBackButtonClick={async () => {
                        setTerminateAccount(false);
                        setIsOpenTermDialog(true);
                        setActiveStep(0);
                        navigate("/home");
                      }}
                      nextButtonText={"Próximo"}
                      goBackButtonText={"Recusar"}
                      disabled={!TermOfPatientChecked}
                      disabledBackButton={TermOfPatientChecked}
                    />
                  </Stack>
                )}
                {activeStep === 1 && (
                  <Stack direction={"column"} width={"100%"}>
                    <Divider sx={{ marginY: 2 }} />
                    <TermOfOwner
                      control={control}
                      isAssigned={!TermOfOwnerChecked}
                    />
                    <FormButtonGroup
                      onNextButtonClick={handleSubmit(onSubmit)}
                      onGoBackButtonClick={async () => {
                        setTerminateAccount(false);
                        setIsOpenTermDialog(true);
                        setActiveStep(0);
                        navigate("/home");
                      }}
                      nextButtonText={"Confirmar"}
                      goBackButtonText={"Recusar"}
                      disabled={!TermOfOwnerChecked}
                      disabledBackButton={TermOfOwnerChecked}
                    />
                  </Stack>
                )}
              </>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
}
