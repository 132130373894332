import { Clear } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";

export const PreviewLogo = ({ preview, clearFile, fileInfo }: any) => {
  return (
    <>
      <Box
        sx={{
          border: "1px solid",
          borderColor: "neutral200.main",
          borderRadius: "8px",
          padding: "16px",
          height: "144px",
          width: "144px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={preview}
          alt="Preview"
          style={{
            objectFit: "contain",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </Box>
      {fileInfo && (
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          mt={-1}
        >
          <Typography variant="body2" color="success.main">
            {fileInfo}
          </Typography>
          <Tooltip title="Remover template">
            <IconButton onClick={clearFile} size="small">
              <Clear fontSize="small" color="error" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </>
  );
};
