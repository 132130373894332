import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { NoDataWarning } from "../../../../../../../components/common";
import { useEffect, useState } from "react";
import { useSessionContext } from "../../../../../../../contexts/SessionContext";
import { useFormatter } from "../../../../../../../utils/useFormatter";
import { useLocationContext } from "../../../../../../../contexts/LocationContext";
import { useDatetime } from "../../../../../../../utils/useDatetime";
import { useManagerContext } from "../../../../../../../contexts/ManagerContext";
import { useFhirData } from "../../../../../../../utils/useFhirData";
import dayjs from "dayjs";
import { GetSlot } from "../../../../../../../services/fhir";
import {
  AccessTime,
  Add,
  Close,
  MedicalServices,
  Person,
  PersonAdd,
} from "@mui/icons-material";
import { useContractContext } from "../../../../../../../contexts/ContractContext";

const statusTranslate: { [key: string]: string } = {
  free: "Disponível",
  "busy-unavailable": "Indisponível",
};

export function SlotsViewer({
  setAvailableDialogOpen,
  setCreateAppointmentDialogOpen,
}: any) {
  const { currentContract } = useContractContext();
  const { access } = useSessionContext();
  const { displaySchedules, selectedDate, setSelectedRow } =
    useManagerContext();

  const { location } = useLocationContext();
  const [slots, setSlots] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const schedulesIds = displaySchedules
    ?.map((e: any) => e.resource?.id)
    ?.join(",");

  const { formatDateToISO } = useDatetime;
  const { formatDateTimeToHour } = useFormatter;

  const [page, setPage] = useState(1);
  // Número de itens por página
  const itemsPerPage = 6;

  // Função para mudar de página
  const handleChangePage = (event: any, value: any) => {
    setPage(value);
  };

  // Cálculo dos itens que serão exibidos com base na página
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = slots?.entry
    ?.filter((slot: any) => dayjs(slot.resource?.end).isAfter(dayjs()))
    ?.slice(startIndex, endIndex);

  const [refetch, setRefetch] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const startDate = formatDateToISO(selectedDate);

        const endDate = formatDateToISO(selectedDate);

        if (schedulesIds) {
          const slotResponse = await GetSlot({
            schedule: schedulesIds,
            status: ["free"],
            sort: "start",
            startDate,
            endDate,
          });
          setSlots(slotResponse);
        } else {
          setSlots(undefined);
        }
      } catch (err) {
        console.log("err");
      } finally {
        setLoading(false);
      }
    }
    if (access?.organizationId) fetchData();
  }, [
    access?.organizationId,
    selectedDate,
    formatDateToISO,
    displaySchedules,
    schedulesIds,
    refetch,
  ]);

  return (
    <Box mt={3}>
      <Grid container spacing={2}>
        {paginatedData?.map((e: any, index: number) => {
          const auxSchedule = displaySchedules?.find((el: any) =>
            e?.resource?.schedule?.reference?.includes(el?.resource?.id)
          );

          const scheduleActors = auxSchedule?.resource?.actor;
          const schedulePractitioner = useFhirData.findActor(
            auxSchedule?.resource?.actor,
            "Practitioner"
          );

          const scheduleLocation = useFhirData.findActor(
            auxSchedule?.resource?.actor,
            "Location"
          );
          const auxVirtualService = useFhirData.virtualService(
            location?.find((loc: any) =>
              loc?.resource?.id.includes(
                scheduleLocation?.reference?.split("/")?.[1]
              )
            )?.resource
          );

          const auxLocation = location?.find((loc: any) =>
            loc?.resource?.id.includes(
              scheduleLocation?.reference?.split("/")?.[1]
            )
          )?.resource;

          const address = auxLocation?.address?.line?.join(", ");

          const healthcare = useFhirData.findActor(
            auxSchedule?.resource?.actor,
            "HealthcareService"
          );
          const date = dayjs(e?.resource?.start)?.format("DD/MM/YYYY") || "";
          const hour = e?.resource?.start
            ? formatDateTimeToHour(e?.resource.start)
            : "";

          const available =
            e?.resource?.status && statusTranslate[e?.resource?.status];
          const auxUpdateSlot = e?.resource;
          const slotTime = {
            start: e?.resource?.start,
            end: e?.resource?.end,
          };

          return (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  border: "1px solid #e0e0e0", // Borda leve e definida
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Box shadow suave
                  borderRadius: "8px", // Bordas levemente arredondadas
                  padding: 0.5, // Espaçamento interno

                  backgroundColor: "#fff", // Fundo branco
                }}
              >
                <CardContent>
                  <Stack spacing={1}>
                    <Stack
                      mb={2}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <AccessTime />
                        <Typography fontSize={20} variant="body1">
                          {hour}
                        </Typography>
                      </Stack>

                      <Typography fontSize={20} variant="body1">
                        {date}
                      </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Person sx={{ color: "primary.main", mr: 1 }} />

                      <Typography fontSize={20} variant="body1">
                        Dr.{" "}
                        {schedulePractitioner?.display
                          ?.trim()
                          ?.split(" ")
                          ?.slice(0, 2)
                          ?.join(" ")}
                      </Typography>
                    </Stack>

                    <Stack direction="row" alignItems="center" spacing={1}>
                      <MedicalServices sx={{ color: "primary.main", mr: 1 }} />

                      <Typography fontSize={20} variant="body1">
                        {healthcare?.display}
                      </Typography>
                    </Stack>

                    <Divider sx={{ my: 2 }}></Divider>

                    {!auxVirtualService ? (
                      <Typography variant="body1" color="text.secondary">
                        {address}
                      </Typography>
                    ) : (
                      <Typography variant="body1" color="text.secondary">
                        Atendimento remoto
                      </Typography>
                    )}
                  </Stack>
                </CardContent>

                <CardActions
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Button
                    startIcon={
                      auxUpdateSlot.status === "free" ? <Close /> : <Add />
                    }
                    color={
                      auxUpdateSlot.status === "free" ? "error" : "primary"
                    }
                    disabled={!currentContract}
                    onClick={() => {
                      setSelectedRow({
                        date,
                        hour,
                        available,
                        auxUpdateSlot,
                        scheduleActors,
                        schedulePractitioner,
                        slotTime,
                        setTabRefetch: setRefetch,
                      });
                      setAvailableDialogOpen(true);
                    }}
                  >
                    {auxUpdateSlot.status === "free"
                      ? "Remover horário"
                      : "Adicionar disponibilidade"}
                  </Button>

                  <Button
                    startIcon={<PersonAdd />}
                    disabled={!currentContract}
                    onClick={() => {
                      setSelectedRow({
                        date,
                        hour,
                        available,
                        auxUpdateSlot,
                        scheduleActors,
                        schedulePractitioner,
                        slotTime,
                        setTabRefetch: setRefetch,
                      });
                      setCreateAppointmentDialogOpen(true);
                    }}
                  >
                    Preencher horário
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        }) || <NoDataWarning message="Nenhum horário foi encontrado" />}
      </Grid>

      {paginatedData?.length && (
        <Pagination
          count={Math.ceil(
            slots?.entry?.filter((slot: any) =>
              dayjs(slot.resource?.end).isAfter(dayjs())
            )?.length / itemsPerPage
          )} // Número total de páginas
          page={page} // Página atual
          onChange={handleChangePage} // Função de mudança de página
          sx={{ display: "flex", justifyContent: "center", marginTop: "16px" }}
        />
      )}
    </Box>
  );
}
