import { Stack, Typography } from "@mui/material";

export default function ViewVersion({
  isOpen,
  inline,
}: {
  isOpen: boolean;
  inline?: boolean;
}) {
  const version = process.env.REACT_APP_VERSION_TEXT;
  const app = process.env.REACT_APP_PROJ;
  return (
    <Stack
      width="100%"
      maxWidth="180px"
      minWidth="fit-content"
      alignItems="center"
    >
      {inline ? (
        <Typography fontSize={14} color="neutral600.main">
          {app} {version}
        </Typography>
      ) : (
        <>
          <Typography fontSize={14} color="neutral600.main">
            {isOpen && `${app}-`}
            {version?.split("-")[1]}
          </Typography>
          <Typography fontSize={14} color="neutral600.main">
            {version?.split("-")[0]}
          </Typography>
        </>
      )}
    </Stack>
  );
}
