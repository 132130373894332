import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ArrowDownward, Close } from "@mui/icons-material";

import { LocationInfo } from "./LocationInfo";
import { PractitionersOptions } from "./PractitionersOptions";
import { DisponibilityTab } from "./DisponibilityTab";

import { GetSlot } from "../../../services/fhir";
import { DataSkeleton } from "../../../components/common";
import { DisponibilityCardMobile } from "./DisponibilityCardMobile";
import { isMobile } from "react-device-detect";
import { SchedulingScreenMobile } from "../../schedulingscreenmobile/SchedulingScreenMobile";
import { useOrganizationContext } from "../../../contexts/OrganizationContext";
import { useFhirData } from "../../../utils/useFhirData";

interface IProps {
  location: fhir5.Location;
  schedules: any;
  startDate: string;
}

export function DisponibilityCard({ location, startDate, schedules }: IProps) {
  const isExtraLargeScreen = useMediaQuery("(min-width:1199px)");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPractitioner, setSelectedPractitioner] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState<fhir5.BundleEntry<fhir5.Slot>[]>();
  const [slotType, setSlotType] = useState();
  const [slotsLoading, setSlotsLoading] = useState(false);

  const [refetchSlots, setRefetchSlots] = useState<boolean>(false);
  const [openSchedulingScreen, setOpenSchedulingScreen] =
    useState<boolean>(false);

  const actors = schedules?.map((schedule: any) => {
    const practitioner = useFhirData.findActor(
      schedule?.resource?.actor,
      "Practitioner"
    );
    const healthcare = useFhirData.findActor(
      schedule?.resource?.actor,
      "Healthcare"
    );
    return { ...practitioner, healthcare };
  });

  const { organizationList } = useOrganizationContext();
  const findOrganization = organizationList?.find(
    (org) =>
      org?.resource?.id ===
      location?.managingOrganization?.reference?.split("/")?.[1]
  );

  useEffect(() => {
    const schedule = schedules
      ?.filter((e: any) => {
        const findPractitioner = useFhirData?.findActor(
          e.resource?.actor,
          "Practitioner"
        );
        const findHealthCare = useFhirData?.findActor(
          e.resource?.actor,
          "Healthcare"
        )?.reference;
        return (
          actors[selectedPractitioner]?.reference ===
            findPractitioner?.reference &&
          actors[selectedPractitioner]?.healthcare?.reference === findHealthCare
        );
      })
      ?.map((el: any) => el?.resource?.id)
      ?.join(",");

    async function asyncCall() {
      setSlotsLoading(true);
      await GetSlot({
        schedule,
        startDate,
        status: ["free"],
        scheduleActive: true,
      }).then((response) => {
        const auxSchedule = schedules?.find((schedule: any) =>
          response?.entry?.[0]?.resource?.schedule?.reference?.includes(
            schedule?.resource?.id
          )
        );

        setSlotType(
          useFhirData.findActor(auxSchedule?.resource.actor, "Healthcare")
        );

        setSlots(response.entry);
        setSlotsLoading(false);
      });
    }
    if (schedule) {
      asyncCall();
    } else {
      setSlots([]);
    }
  }, [startDate, schedules, selectedPractitioner]);

  return (
    <>
      {isMobile ? (
        <DisponibilityCardMobile
          location={location}
          onClick={() => setOpenSchedulingScreen(true)}
        />
      ) : (
        <Card
          sx={{
            width: "100%",
            height: "fit-content",
            marginTop: 3,
            border: "1px solid",
            borderColor: "primary700.main",
            position: "relative",
          }}
        >
          {open && (
            <IconButton
              sx={{
                width: "fit-content",
                position: "absolute",
                top: "1px",
                right: "1px",
              }}
              onClick={() => setOpen(false)}
            >
              <Close />
            </IconButton>
          )}

          <CardContent>
            <Grid container height="fit-content" justifyContent="center" mt={1}>
              <Grid
                item
                xs={12}
                lg={5.9}
                sx={{
                  height: "100%",
                }}
              >
                <Stack>
                  <Stack sx={{ flexDirection: "row", justifyContent: "left" }}>
                    <LocationInfo
                      location={location}
                      organization={findOrganization}
                    />
                  </Stack>
                  {open ? (
                    <PractitionersOptions
                      slotType={slotType}
                      organization={findOrganization}
                      practitioners={actors}
                      selectedPractitioner={selectedPractitioner}
                      setSelectedPractitioner={setSelectedPractitioner}
                    />
                  ) : (
                    <></>
                  )}
                </Stack>
              </Grid>

              <Grid item xs={12} lg={0.2}>
                <Divider
                  orientation={isExtraLargeScreen ? "vertical" : "horizontal"}
                  sx={{
                    marginTop: `${!isExtraLargeScreen && "8px"}`,
                    marginLeft: `${isExtraLargeScreen && "8px"}`,
                    backgroundColor: "primary700.main",
                    height: `${isExtraLargeScreen ? "100%" : "2px"}`,
                    width: `${isExtraLargeScreen ? "2px" : "100%"}`,
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                lg={5.9}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",

                  width: "100%",
                  alignContent: "center",
                }}
              >
                {!open ? (
                  <Button
                    sx={{
                      width: "100%",
                      textTransform: "none",
                      "&:hover": {
                        background: "none",
                      },
                      height: "100%",
                      minHeight: "120px",
                    }}
                    onClick={() => setOpen(true)}
                  >
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        gap: "8px",
                        color: "primary700.main",
                      }}
                    >
                      Checar disponibilidade
                      <ArrowDownward fontSize="small" />
                    </Typography>
                  </Button>
                ) : !loading ? (
                  <DisponibilityTab
                    slots={slots}
                    loading={slotsLoading}
                    location={location}
                    setRefetchSlots={setRefetchSlots}
                  />
                ) : (
                  <DataSkeleton />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      <SchedulingScreenMobile
        open={openSchedulingScreen}
        onClose={() => setOpenSchedulingScreen(false)}
        location={location}
        practitioners={actors}
        slots={slots}
        loading={loading}
        setRefetchSlots={setRefetchSlots}
        selectedPractitioner={selectedPractitioner}
        setSelectedPractitioner={setSelectedPractitioner}
      />
    </>
  );
}
