import { toast } from "react-toastify";
import { endpoints } from "../../../configs/Settings";
import { HTTPResponseStatus } from "../../../utils/enum";
import { useAuth } from "../../../utils/useAuth";
import { useAxios } from "../../../utils/useAxios";

export async function DeleteUser(id: string) {
  const url = `${endpoints.Scim2}/Users/${id}`;

  const bearerToken = await useAuth.getUserToken();

  const headers = {
    "Content-Type": "application/json",
    Accept: "*/*",
    Authorization: bearerToken,
  };

  const response = await useAxios
    .delete(url, { headers, timeout: 8000 })
    .then((response) => {
      if (response.status !== HTTPResponseStatus.NoContent) {
        throw new Error(`DeleteUser error: ${response.status}`);
      }

      return response.data;
    })
    .catch((error) => {
      console.error("DeleteUser ", error);
      // toast.error(`DeleteUser Erro: ${error?.response?.status}`);
      throw new Error();
    });
  if (response) return response;
}
