import { useState } from "react";
import { IconButton, TableCell, Tooltip } from "@mui/material";
import { Add, Delete, PersonAdd } from "@mui/icons-material";

import { AvailableSlotDialog } from "./AvailableSlotDialog";
import { CreateAppointmentDialog } from "./creatappointmentdialog";
import { useContractContext } from "../../../contexts/ContractContext";

export function AvailableSlotsActions({ row }: any) {
  const { currentContract } = useContractContext();
  const [availableDialogOpen, setAvailableDialogOpen] =
    useState<boolean>(false);
  const [createAppointmentDialogOpen, setCreateAppointmentDialogOpen] =
    useState<boolean>(false);

  return (
    <>
      {availableDialogOpen && (
        <AvailableSlotDialog
          setModalOpen={setAvailableDialogOpen}
          open={availableDialogOpen}
          row={row}
        />
      )}
      {createAppointmentDialogOpen && (
        <CreateAppointmentDialog
          isOpen={createAppointmentDialogOpen}
          setModalOpen={setCreateAppointmentDialogOpen}
          row={row}
          cpfT={""}
        />
      )}

      <TableCell align="center">
        <Tooltip
          title={
            row.auxUpdateSlot.status === "free"
              ? "Remover disponibilidade"
              : "Adicionar disponibilidade"
          }
        >
          <IconButton
            sx={{
              color:
                row?.auxUpdateSlot.status === "free"
                  ? "error600.main"
                  : "neutral700.main",
              "&:hover": {
                background:
                  row?.auxUpdateSlot.status === "free"
                    ? "error700.main"
                    : "neutral700.main",
              },
            }}
            onClick={() => {
              setAvailableDialogOpen(true);
            }}
            disabled={!currentContract}
          >
            {row?.auxUpdateSlot.status === "free" ? <Delete /> : <Add />}
          </IconButton>
        </Tooltip>

        <Tooltip title="Preencher vaga">
          <IconButton
            onClick={() => setCreateAppointmentDialogOpen(true)}
            disabled={!currentContract}
          >
            <PersonAdd />
          </IconButton>
        </Tooltip>
      </TableCell>
    </>
  );
}
