import { Description } from "@mui/icons-material";
import { IconButton, TableCell, Tooltip } from "@mui/material";

import { isAndroid, isMobile, isMobileSafari } from "react-device-detect";
import { useState } from "react";
import { GetBinary } from "../../services/fhir/patientsummary/Binary/GetBinary";
import { IframeDialog } from "../ehrrecords/components/IframeDialog";

export function AccessHistoryActions({ row }: any) {
  const [binaryUrl, setBinaryUrl] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setBinaryUrl("");
    setOpen(false);
  };

  const base64ToBlob = (base64: any, contentType = "") => {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  async function downloadBase64File() {
    try {
      const response = await GetBinary({
        urlBinary: row?.binaryUrl,
      });
      const blob = base64ToBlob(response?.data, response?.contentType);
      const fileUrl = URL.createObjectURL(blob);
      const auxFile = {
        fileUrl,
        fileName: "Documento.pdf",
      };

      if (isMobileSafari && !isAndroid) {
        if (auxFile) {
          const link = document.createElement("a"); // Cria um elemento <a>
          link.href = auxFile?.fileUrl; // Define o URL do Blob
          link.download = auxFile?.fileName; // Define o nome do arquivo
          // document.body.appendChild(link); // Adiciona o link ao documento
          link.click(); // Simula o clique no link
          // document.body.removeChild(link); // Remove o link do documento
          // URL.revokeObjectURL(auxFile?.fileUrl); // Libera a memória do URL Blob
        } else {
          alert(
            "O arquivo ainda está sendo preparado, por favor tente novamente."
          );
        }
      } else {
        window.open(auxFile.fileUrl, "_blank");
        // setDataRow(e.row);
        // setOpenInformation(true);
        handleClose();
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  return (
    <>
      <TableCell align="center">
        {row?.binaryUrl && (
          <Tooltip title="Visualizar documento">
            <IconButton
              onClick={
                isMobile
                  ? () => downloadBase64File()
                  : () => {
                      setBinaryUrl(row?.binaryUrl);
                      handleOpen();
                    }
              }
            >
              <Description />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>

      {open && (
        <IframeDialog
          handleClose={handleClose}
          isOpen={open}
          data={binaryUrl}
        />
      )}
    </>
  );
}
