import { Warning } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";

export const ReferenceDetails = ({ errorFile }: any) => {
  console.log("errorFile", errorFile);
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        spacing={2}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid",
            borderColor: "neutral200.main",
            backgroundColor: "neutral300.main",
            borderRadius: "4px",
            padding: "4px",
            width: "144px",
            height: "144px",
          }}
        >
          <Typography
            sx={{
              position: "absolute",
              top: "-1px",
              left: "50%",
              transform: "translateX(-50%)",
              fontSize: "12px",
              color: "neutral500.main",
            }}
          >
            144px
          </Typography>

          <Typography
            sx={{
              position: "absolute",
              right: "-8px",
              top: "50%",
              transform: "translateY(-50%) rotate(90deg)",
              fontSize: "12px",
              color: "neutral500.main",
            }}
          >
            144px
          </Typography>
        </Box>
        <Stack direction={"column"} spacing={4}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Warning sx={{ color: "warning500.main", fontSize: "20px" }} />
            <Typography
              variant="body2"
              fontWeight={500}
              color="neutral700.main"
              textAlign="left"
            >
              Recomendações
            </Typography>
          </Stack>
          <Stack direction="column" spacing={1} alignItems="flex-start">
            <Typography
              variant="body2"
              fontWeight={500}
              color="neutral700.main"
            >
              Formato:
            </Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              color="neutral700.main"
            >
              JPEG ou PNG
            </Typography>
          </Stack>
          {errorFile && (
            <Typography
              variant="body1"
              fontWeight={400}
              color="warning700.main"
            >
              Por favor, envie apenas arquivos PNG ou JPEG!
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
};
