import { Warning } from "@mui/icons-material";
import { Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";
import { FormButtonGroup } from "../../../../components/form";
interface IProps {
  isOpen: boolean;
  handleClose: any;
  onClick: Function;
}
export function InactivateAccount({ isOpen, handleClose, onClick }: IProps) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: 2,
          paddingX: { xs: 1, sm: 4, md: 8 },
          maxWidth: "1200px",
        },
      }}
      maxWidth="sm"
      fullScreen={isMobile}
      onClose={handleClose}
      open={isOpen}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        mb={2}
        spacing={1}
      >
        <Warning fontSize="medium" sx={{ color: "warning500.main" }} />
        <DialogTitle align="center">
          Deseja confirmar o cancelamento da sua conta?
        </DialogTitle>
      </Stack>
      <FormButtonGroup
        onGoBackButtonClick={handleClose}
        onNextButtonClick={onClick}
        goBackButtonText="Cancelar"
        nextButtonText="Confirmar"
      />
    </Dialog>
  );
}
