import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CommonDialog } from "../../../common/CommonDialog";
import { useWarningCentralContext } from "../../../../contexts/WarningCentralContext";
import { NoDataWarning } from "../../../common";

export function WarningCentralDialog({ isOpen, handleClose }: any) {
  const { warnings } = useWarningCentralContext();

  return (
    <CommonDialog isOpen={isOpen} handleClose={handleClose}>
      <Stack direction="row" justifyContent="center">
        <DialogTitle>Central de avisos</DialogTitle>
      </Stack>

      <DialogContent>
        {warnings?.length ? (
          <Stack spacing={1}>
            {warnings?.map((e: any) => (
              <Card
                sx={{ border: "1px solid #ccc", cursor: "pointer" }}
                onClick={e?.onClick}
              >
                <CardHeader
                  sx={{ pb: 0 }}
                  avatar={e?.displayIcon}
                  title={
                    <Typography variant="body1" fontSize={22} fontWeight={500}>
                      {e?.text}
                    </Typography>
                  }
                  action={
                    <IconButton aria-label="action" onClick={e?.onClick}>
                      {e?.actionIcon}
                    </IconButton>
                  }
                />

                <CardContent sx={{ mt: 0, pt: 1 }}>
                  <Typography sx={{ mb: 1 }} variant="body1">
                    {e?.description}
                  </Typography>
                  <Chip
                    label={
                      e?.type === "settings" ? "Ação necessária" : "Atualização"
                    }
                    color={
                      e?.type === "settings"
                        ? "error"
                        : e?.type === "feature"
                        ? "primary"
                        : "warning"
                    }
                  />
                </CardContent>
              </Card>
            ))}
          </Stack>
        ) : (
          <NoDataWarning message="Nenhuma notificação do sistema ativa no momento!" />
        )}
      </DialogContent>
    </CommonDialog>
  );
}
